import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, Skeleton } from '@mui/material';
import moment from 'moment';
import ReactGA from 'react-ga4';
import styles from '../dashboard.module.scss';
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector';
import { REQUEST_SURFACEWEB, SurfaceWeb } from '../../../core/constants';
import { env } from '../../../environement/env';
import { fetchImageApi } from '../../../core/api/brandIntelApi';
import EmptyStateSurfaceWeb from '../common/EmptyStateSurfaceWeb';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';

function SurfaceWebSection() {
    const dispatch = useDispatch();
    const { isLoading, surfaceWeb } = useSelector(brandIntelSelector);
    const { notifs, notifsUser } = useSelector(notificationSelector)
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        document.title = "Surface Web";
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    

    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    // Filter the data based on the search term and source
    const filteredData: any[] =  []

    // Sort the filtered data based on publishedAt
    const sortedData = filteredData.sort((a: SurfaceWeb, b: SurfaceWeb) => {
        const dateA = new Date(a.post_date).getTime();
        const dateB = new Date(b.post_date).getTime();
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    function extractIdFromUrl(url: string): string | null {
        const regex = /\/read\/([a-zA-Z0-9_-]+)\?/;
        const match = url.match(regex);
        return match ? match[1] : null;
      }

      const renderImage = async (imageUrl: string) => {
        const blob = await fetchImageApi(imageUrl);
            if (blob) {
                const objectURL = URL.createObjectURL(blob);
                return objectURL
            }
      }


      const surfaceWebNotif = notifs.filter(item => item.isSurfaceWeb === true && notifsUser.some((not: any) => not.notifId === item.id && not.read === false))
      const sortedResult = surfaceWeb?.sort((a, b) => {
          const aInDmnNotif = surfaceWebNotif.some((notif) => notif?.data?.id === a.id);
              const bInDmnNotif = surfaceWebNotif.some((notif) => notif?.data?.id === b.id);
      
              // Sort elements in vulNotif to the top
              if (aInDmnNotif && !bInDmnNotif) return -1;
              if (!aInDmnNotif && bInDmnNotif) return 1;
          return 1
      })
    return (
        <div style={{ marginTop: 20, width:'100%' }}>
            {
                !isLoading ?
           <>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                <div className={styles.searchAndIcon}>
                    <input 
                        className={styles.search} 
                        name='search' 
                        placeholder='Search by source' 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                    />
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x' />
                </div>
                <div style={{ display: 'flex', alignItems: "center", gap: 25, cursor: 'pointer' }} onClick={toggleSortOrder}>
                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                        <span>{sortOrder === 'desc' ? 'Latest First' : 'Oldest First'} </span>
                        <FontAwesomeIcon icon={sortOrder === 'desc' ? faChevronDown : faChevronUp} size="1x" height={12} width={11} />
                    </p>
                </div>
            </div>
            <div className={styles.surfaceWebRow}>

                {sortedResult && sortedResult?.length > 0 ? (
                    sortedResult.map((item: SurfaceWeb, index: number) => (
                        <div key={index} className={styles.surfaceWebCol} onClick={() => openlinkDetail(item?.url)}>
                            <div style={{ display: "flex", gap: 24 , justifyContent:'space-between' }}>
                                <p style={{ color: "white", fontSize: 14, width:'320px' }}>{item?.post_body?.substring(0, 65)}...</p>
                               {item.post_image ?  <img src={item.post_image} className={styles.surfaceWebImg} />
                               : <div style={{height:"100px"}}/> 
                                
                               }
                                
                            </div>
                            <div style={{height:13, display: "flex", justifyContent: "space-between", alignItems: "center", marginTop:24}}>
                                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                {
                                        surfaceWebNotif.some((respItem: any) => {
                                            
                                            return respItem?.data?.id === item.id;
                                        }) ? (
                                            <div className={styles.redPoint} />
                                        ) : (
                                         null // Nothing is displayed if the condition is not met
                                        )
                                    }
                                    <p style={{ color: "#afafaf", fontSize: 12 }}>{item?.profile_name}</p>
                                </div>
                                <p style={{ color: "#afafaf", fontSize: 12 }}>{moment(item?.post_date).fromNow()}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.loaderVul}>
                    <EmptyStateSurfaceWeb/>
                    </div>
                )}
            </div>
            </>: <div className={styles.loaderVul} style={{width:'100%'}}>
        <CircularProgress />
    </div>
             }
        </div>
    );
}

export default SurfaceWebSection;
