import { call, put } from "redux-saga/effects";
import { isFirstTimeLoginAction, LoginSuccessAction, resetAuthStateAction, resetPasswordSuccess, sendOtpAction, sendOtpRequestAction, setNotificationAction, updatePasswordRecoverSuccess, verifyOtpAction } from "../actions/authActions";
import { loginAPI, logoutAPI, resetPasswordAPI, sendOtpAPI, setNewPasswordAPI, updatePasswordRecoverAccountAPI, verifyOtpAPI } from "../api/authApi";
import { AuthAction, AuthState, LOGIN_FAILED, LoginFirstTimeRes, LoginRes, MessageResp, NotifMessages, Otp, OtpRequest, OtpResult, OtpSent, OtpSuccess, RecoverAccountResultRequest, TypeRecoverAccount, ValidationError, firstTimeNewPassword } from "../constants";
import { cp } from "fs";

type TypeLoginRes = LoginRes  | ValidationError;

function isLoginFirstTimeRes(response: any): response is LoginFirstTimeRes {
    return response && typeof response.message === 'string';
  }
  
  // Type guard for ValidationError
function isValidationError(response: any): response is ValidationError {
    return response && response.errorCode && response.errorCode !== 200;
  }

export function* authSaga(action: AuthAction) {
    try {
        const { email, password } = action.payload as AuthState;
        const response: LoginRes = yield call(loginAPI, { email, password} );

       
       if(isValidationError(response)) {
        yield put({
            type: LOGIN_FAILED
        })
            yield put(setNotificationAction({
                error: response.error,
                success: false,
                errorCode:response.errorCode
            }))
        }else {
            yield put(setNotificationAction({
                error: 'Password Updated Successfuly',
                success: false,
                errorCode:200
            }))
            window.localStorage.setItem("email", email)
            window.localStorage.setItem('hmac', response.hmacValue)
            window.localStorage.setItem("fullName", response.lastName)
            window.localStorage.setItem('name', response.firstName)
            window.localStorage.setItem('avatar', response.profilePicture)
            window.localStorage.setItem('userid', response.id?.toString() || "")

            yield put(LoginSuccessAction(response))
            
        }

        yield put(setNotificationAction({
            error: '',
            success: false,
            errorCode:null
        }))

    } catch (error) {
        throw error;
    }
}

export function* setFirstTimeNewPasswordSaga(action: AuthAction) {
    try {
      const { password, email, jwt } = action.payload as firstTimeNewPassword;
      const response: LoginFirstTimeRes = yield call(setNewPasswordAPI, { email, password, jwt });
  
      if (isValidationError(response)) {
        yield put(setNotificationAction({
          error: response.error,
          success: false,
          errorCode: response.errorCode
        }));
      } else {
        yield put(isFirstTimeLoginAction({ isFirstTime: false }));
        yield put(sendOtpRequestAction({ email }));
        const responseLogin: LoginRes = yield call(loginAPI, { email, password });
        yield put(LoginSuccessAction(responseLogin));
        yield put(setNotificationAction({
          success: true,
          error: '',
          errorCode: 200
        }));
      }
    } catch (error) {
      yield put(setNotificationAction({
        error: 'An error occurred',
        success: false,
        errorCode: 500
      }));
    }
  }

export function* otpSendSaga(action: AuthAction) {
    try {
        const { email } = action.payload as AuthState;
        console.log('email: '+email)
        const response: LoginFirstTimeRes = yield call(sendOtpAPI, email);
        if(response.message === MessageResp.OPT_SENT_WITH_SUCCESS) 
            yield put(sendOtpAction({
                message: response.message,
                otpIsSent: true
            }))
    } catch (error) {
        throw error;
    }
}

export function* otpVerifySaga(action: AuthAction) {
    try {
        const { email, otp } = action.payload as Otp;
        const response: OtpSuccess = yield call(verifyOtpAPI, { email, otp })
        console.log(response)
        if(response.message)
            yield put(verifyOtpAction({
                otpIsValid: true
            }))
        else
            yield put(verifyOtpAction({
                otpIsValid: false
            }))
    } catch (error) {
        throw error;
    }
}

export function* resetPasswordSaga(action: AuthAction) {
    try {
        const { email } = action.payload as OtpRequest;
        const response: LoginFirstTimeRes = yield call(resetPasswordAPI, email);
        if(response.message === MessageResp.PASSWOED_RESET_EMAIL_SENT) {
            yield put(resetPasswordSuccess({
                notification: 'Password Reset Email Sent',
                resetMailIsSent: true
            }))
        } 
    } catch (error) {
        throw error;
    }
}

export function* updatePasswordRecoverSaga(action: AuthAction) {
    try {
        const data: RecoverAccountResultRequest = action.payload as RecoverAccountResultRequest
        const response: LoginRes = yield call(updatePasswordRecoverAccountAPI, data)
        
        if(isValidationError(response)) {
           
                yield put(setNotificationAction({
                    error: response.error,
                    success: false,
                    errorCode:response.errorCode
                }))
            } else {
                window.localStorage.setItem("email", response.email)
        window.localStorage.setItem('hmac', response.hmacValue)
        window.localStorage.setItem("fullName", response.lastName)
        window.localStorage.setItem('name', response.firstName)
        window.localStorage.setItem('userid', response.id?.toString() || "")
        window.localStorage.setItem('jwt', response?.jwt as any)
       window.localStorage.setItem("type", response?.typeEntreprise as any)
       
         yield put(updatePasswordRecoverSuccess({
                notification: 'The Password Updated Successfully',
                passwordIsUpdated: true
            }))    
            }
        
    } catch (error) {
        
    }
}

export function* logoutSaga() {
    try {
        yield call(logoutAPI);
    } catch (error) {
        throw error;
    }
}


