import React, { useEffect } from 'react';
import { menuItem, menuItems } from '../constants';
import styles from '../dashboard.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from "../../Account/common/defendisLogoWhite.svg";
import { useSelector, useDispatch } from 'react-redux';
import { statsSelector } from '../../../core/selectors/statsSelector'
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';
import { getInfosEntrepriseRequest } from '../../../core/actions/entrepriseActions';
import EmptyEntrepriseLogo from '../../../icons/Empty Users/emptyEntreprise.jpg';
import { Avatar } from '@mui/material';

function Menu() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { entreprise } = useSelector(entrepriseSelector);
    const { homeStats } = useSelector(statsSelector)
    const currentPath = location.pathname;

    // Uncomment and use to fetch entreprise info on mount
    // useEffect(() => {
    //     dispatch(getInfosEntrepriseRequest());
    // }, [dispatch]);

    // Improved function to check if "Data Leaks" is enabled
    const isDataLeaksEnabled = () => {
        return (
            entreprise?.subscriptionData?.credentials === true ||
            entreprise?.subscriptionData?.documents === true ||
            entreprise?.subscriptionData?.databases === true
        );
    };

    const totalVulnerabilities = Number(homeStats?.vulnerabilitites?.length) - 1


    const menuSubscriptions = menuItems.filter((item) => {
        if (item.title === 'Data Leaks') {
            // Include "Data Leaks" only if enabled
            return isDataLeaksEnabled();
        } else if (item.title === 'Vulnerabilities') {
            // Include "Vulnerabilities" only if enabled
            return entreprise?.subscriptionData?.vulnerabilities === true && totalVulnerabilities > 0;
        } else if (item.title === "Ransomware") {
            return entreprise?.subscriptionData?.ransomware === true;
        } else if (item.title === "Brand Monitoring") {
            return entreprise?.subscriptionData?.brand === true

        } else {
            // Include all other items
            return true;
        }
    });

    return (
        <div className={styles.menu}>
            <div className={styles.mainLogo}>
                <NavLink to="/" style={{ textDecoration: 'none' }}>
                    <img src={logo} className={styles.logo} height={16} alt="Main Logo" />
                </NavLink>
            </div>
            <div className={styles.menuMain}>
                <div className={styles.menuList}>
                    {menuSubscriptions.map((item: menuItem) => {
                        return (
                            <NavLink
                                to={item.path}
                                style={{ textDecoration: 'none', color: 'white' }}
                                className={currentPath === item.path ? styles.activeMenuItem : styles.menuItem}
                                key={item.id}
                            >
                                <>{item.icon}</>
                                <p style={{ fontSize: 14 }}>{item.title}</p>
                            </NavLink>
                        );
                    })}
                </div>
                <div className={styles.menuProfile} style={{ width: 200 }}>
                    <Avatar src={entreprise?.avatarUrl ? entreprise.avatarUrl : EmptyEntrepriseLogo} variant='rounded' style={{ height: 40, width: 40 }} />
                    {/* <img 
                        src={entreprise?.avatarUrl ? entreprise.avatarUrl : EmptyEntrepriseLogo} 
                        className={styles.profileLogo} 
                    /> */}
                    <div style={{ display: 'grid', overflow: 'hidden' }}>
                        <span className={styles.profileName} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{entreprise?.entrepriseName}</span>
                        <span className={styles.profilePlan} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{entreprise?.subscriptionName}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
