import styles from '../views/Dashboard/dashboard.module.scss'
import Icon from '../icons/States/Error.svg'
import Logo from '../icons/Defendis_Logo_White.svg'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from '../views/Dashboard/IntercomSetup'
import { useLocation } from 'react-router-dom'

const ErrorPage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);
  const errorMessage = queryParams.get('errorMessage') || location.state?.errorMessage || "Details not available at the moment.";
  const navigate = useNavigate()
  const previous = () => navigate(-1)
 

  const emailStorageVa = window.localStorage.getItem('email')
  const hmacValue = window.localStorage.getItem('hmac')
  const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
  const profilePicture = window.localStorage.getItem("avatar")

  useIntercom('isows87y', {
    app_id: 'isows87y',
    api_base: 'https://api-iam.intercom.io',
    email: emailStorageVa as string,
    user_hash: hmacValue as string,
    name: fullNamed,
    avatar: profilePicture,
  })
  
  
  return (
    <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
        <img src={Logo} alt="Defendis Logo" className={styles.logo} onClick={previous} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '95vh' }}>
        <div style={{backgroundColor: '#1A1A1A', borderRadius: '16px', padding: '40px 32px', textAlign: 'center', color: 'white', maxWidth: '400px',}}>
          <img src={Icon} alt="Error Icon" onClick={previous} height={124} width={124} /> 
          <div style={{ display: 'grid', gap: 0, paddingTop: 16}}>
            <span style={{fontSize: 20, fontWeight: 600, lineHeight: 1.5, paddingBottom: 8}}>There Was a Problem</span>
            <span style={{lineHeight: 1.3}}>Please refresh the page and try again. If the problem persists, contact us for help.</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 16}}>
              <Button onClick={previous} className={styles.btnLogin} style={{ fontSize: 14, fontWeight: '500', width: 120 }}>Refresh</Button>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center'}}>
                <span className={styles.FooterText}>Error message:</span>
                <span className={styles.FooterText}>{errorMessage}</span>
            </div>
  </div>
  )
}

  export default ErrorPage