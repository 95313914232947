import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../../dashboard.module.scss'
import { CreditCardsThreatIntel } from '../../../../core/constants'
export default function CreditCardDetailDetails() {
  const [creditCard, setcreditCard] = useState<any>()
    useEffect(() => {
      const data = window.localStorage.getItem("item")
      if(data) {
        setcreditCard(JSON.parse(data))
      }
    }, [])
    return (
        <table className={styles.table}style={{marginTop:25}}>
    <tbody>
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px', padding: '13px 16px'}} className={styles.tableCell}> 
          BIN/IIN
          </td>
              <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
              {creditCard?.cardnumber.substring(0,6)}
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Card Brand
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.brand || "-"}
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Card Type
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.type || "-"}
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Card Level
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.level || "-"}
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Currency
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.currency || "-"}
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Reference
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.cardid}
            </td>
        </tr>
        
      </tbody>
  </table>
    )
}
