import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'
import { VulnerabilityRef } from '../../../core/constants'
export default function Reference() {
    const { vulnerabilies, vulDetail } = useSelector(vulnerabilitiesSelector)
    const references = vulnerabilies?.references?.filter(item => item?.cve_id === vulDetail?.cve_id)
    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
                <tbody>
                    {
                        references?.map((item) => 
                            <tr className={styles.tableActivityRowRansomWatchList} style={{justifyContent: 'space-between'}}>
                                <td style={{width: '100%', padding: '16px', color: "#e9bd08", textDecorationLine: 'underline', cursor:'pointer'}} className={styles.tableCell} onClick={()=>openlinkDetail(item?.url)} >
                                {item?.url}
                                </td>
                                <td style={{padding: '0px 16px'}} className={styles.tableCell}>
                                    <div style={{display: 'flex', alignItems: "center", gap: 5, justifyContent: 'flex-end'}}>
                                        <span style={{whiteSpace: 'nowrap', backgroundColor: '#2C2C2C', padding: "6px 12px", borderRadius: "32px", color: 'white'}}>{item?.tags ? item?.tags : "No Tags"}</span>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
