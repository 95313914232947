const dateFormats = [
    'DD/MM/YYYY h:mm:ss A',
    'M/D/YYYY h:mm:ss A',
    'DD.MM.YYYY HH:mm:ss',
    'DD.MM.YYYY H:mm:ss',      // Allows single-digit hours
    'M/D/YYYY HH:mm:ss',
    'D/M/YYYY HH:mm:ss',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'MM.DD.YYYY',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
    'DD.MM.YYYY',
    'YYYYMMDD',
    'DD MMM YY HH:mm',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DD HH:mm:ss.SSS', // Corrected format for milliseconds
    'YYYY-MM-DD HH:mm:ss',     // General timestamp without AM/PM
    'MM.DD.YYYY HH:mm:ss',
    'MM/DD/YYYY HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss A',   // For AM/PM
    'MM/DD/YYYY HH:mm:ss A',   // For AM/PM with US format
    'DD/MM/YYYY HH:mm:ss A',   // For AM/PM with international format
    'DD.MM.YYYY H:mm:ss.SSSSSS', // Microseconds
    'YYYY/M/D H:mm:ss',        // Allows single-digit month and day
    'DD Mon YY HH:mm UTC',
    'DD-Mon-YY H:mm:ss A',     // Allows single-digit hours with AM/PM
    'D.MM.YYYY HH:mm:ss',      // European date with single-digit day
    'DD.MM.YY H:mm',           // European date with single-digit hour
    'DD Mon YY HH:mm',
    'DD.MM.YYYY HH:mm:ss',     // Standard European timestamp
    'D/M/YYYY H:m:s',          // Single-digit hours, minutes, seconds
    'Day Mon D HH:mm:ss YYYY', // Day of the week with full timestamp
    'M/D/YYYY H:mm:ss A',      // US format single-digit hours with AM/PM
    'DD-Mon-YY HH:mm:ssQ',     // Optional trailing character 'Q'
    'MM.DD.YYYY HH:mm:ss',     // US format with full timestamp
    'D/MM/YYYY H:m:s',          // Single-digit day with full timestamp
    'ddd MMM D HH:mm:ss YYYY',   // With single space handling
    'ddd MMM  D HH:mm:ss YYYY' 
];

export default dateFormats;
