import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import styles from "./auth.module.scss";
import BackBtn from './components/BackBtn';
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendOtpRequestAction,
  verifyOtpRequestAction,
} from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../core/constants';
import ErrorNotification from '../Account/common/ErrorNotification';
import ReactGA from 'react-ga4';
import { useIntercom } from '../Dashboard/IntercomSetup';
import Logo from "../../icons/Defendis_Logo_White.svg"
import logoSignSVG from '../../icons/Login-Illustration.svg'

function OtpVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);
  const [OpenNotification, setOpenNotification] = useState(false)

  
  const emailStorageVa = window.localStorage.getItem('email')
  const hmacValue = window.localStorage.getItem('hmac')
  const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
  const profilePicture = window.localStorage.getItem("avatar")

  useIntercom('isows87y', {
    app_id: 'isows87y',
    api_base: 'https://api-iam.intercom.io',
    email: emailStorageVa as string,
    user_hash: hmacValue as string,
    name: fullNamed,
    avatar: profilePicture,
  })
  


  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  // useEffect(() => {
  //   // Conditionally dispatch sendOtpRequestAction based on specific auth state conditions
  //   if (auth.otpIsValid) {
  //     if(auth.role !== Roles.boAdmin) {
  //       navigate("/dashboard");
  //     }
  //     else {
  //       navigate('/admin')
  //     }
  //   }
  // }, [auth.otpIsSent, auth.otpIsValid, auth.email, dispatch, navigate]);

  useEffect(() => {
    document.title = "Authentification Verification"
    ReactGA.send({ hitType: "Authentification Verification", page: window.location.pathname });

    if (auth.otpIsValid) {
      if (auth.role !== Roles.boAdmin) {
        navigate("/");
      }
      else {
        navigate('/admin')
      }
    }
  }, [auth])

  const previous = () => {
    navigate(-1)
  }

  const login = () => {
    dispatch(verifyOtpRequestAction({
      email: auth.email,
      otp: otp,
    }));

  };



  const anonymizeEmail = (email: string) => {
    // Split the email into the local part and the domain
    const [localPart,] = email.split('@');
    // Check if the local part is long enough to anonymize
    if (localPart.length > 2) {
      // Keep the first and last character, replace the rest with asterisks
      const anonymizedPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
      return anonymizedPart + '@defendis.ai';
    } else {
      // If the local part is too short, just return it as is with the new domain
      return localPart + '@defendis.ai';
    }
  }

  return (
    <div>
      <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
          <img src={Logo} className={styles.logo} alt="Defendis Logo" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh' }}>
          <div className={styles.login} style={{ width: 'auto' }}>
            <div style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
              <form onSubmit={e => e.preventDefault()}>
                <div className={styles.loginInputs}>
                  <div style={{ display: 'grid' }}>
                    <span className={styles.titleLogin}>Verification</span>
                    <span className={styles.lightText} style={{ paddingTop: 0, paddingBottom: 32 }}>We sent a verification code to your email address, it will expire in 10 minutes.</span>
                  </div>
                  <div style={{ marginBottom: -10 }}>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputStyle={styles.otpInputs}
                      renderInput={(props) => <input {...props} style={{ fontSize: 20, fontWeight: 400, width: 16 }} />}
                    />
                  </div>
                  <div style={{ display: 'grid', width: '100%', gap: 0 }}>
                    <Button
                      onClick={login}
                      type="submit"
                      style={{ marginTop: 48, fontSize: 14, fontWeight: 500 }}
                    >
                      Verify code
                    </Button>
                    <Button
                      onClick={previous}
                      type="submit"
                      style={{ marginTop: 12, backgroundColor: '#1F1F1F', color: 'white', fontSize: 14, fontWeight: 500, width: '100%' }}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div style={{ paddingTop: '2rem' }}>
              <img src={logoSignSVG} alt="Illustration" />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
          <span className={styles.loginFooterText}>© 2024 Defendis Technology Inc.</span>
          <a href="https://defendis.ai/terms" className={styles.loginFooterText} target="_blank">
            <span>Terms</span>
          </a>
          <a href="https://defendis.ai/privacy" className={styles.loginFooterText} target="_blank">
            <span>Privacy Policy</span>
          </a>
          <a href="https://help.defendis.ai" className={styles.loginFooterText} target="_blank">
            <span>Support</span>
          </a>
        </div>
        {
          auth.otpIsValid && <ErrorNotification
            severity='success'
            open={OpenNotification}
            message={auth.notification}
            handleClose={handleCloseNotification}
          />
        }
      </div>

    </div>






  );
}

export default OtpVerification;
