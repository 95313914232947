import { call, put } from "redux-saga/effects";
import { BranIntelAction, Dark, Domaine, FAILED_DARK, FAILED_DOMAINE, FAILED_SOCIALMEDIA, Places, SocialMedia, SocialMediaPost, SurfaceWeb } from "../constants";
import { getDarkApi, getDomainsApi, getPlacesApi, getSocialMediaApi, getSurfaceWebApi } from "../api/brandIntelApi";
import { setDarkAction, setDomainAction, setPlacesAction, setSocialMediaAction, setSurfaceWebAction } from "../actions/brandIntelActions";
import { AxiosError } from "axios";

export function* getPlacesSaga(actions: BranIntelAction) {
    try {
        const response: Places[] = yield call(getPlacesApi)
        yield put(setPlacesAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getSurfaceWebSaga(actions: BranIntelAction) {
    try {
        const response: SurfaceWeb[] = yield call(getSurfaceWebApi, actions.payload as string)
        yield put(setSurfaceWebAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getSocialMediaSaga(actions: BranIntelAction) {
    try {
        const response: SocialMediaPost[] = yield call(getSocialMediaApi, actions.payload as string)
        yield put(setSocialMediaAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getDarkSaga(actions: BranIntelAction) {
    try {
        const response: Dark[] = yield call(getDarkApi, actions.payload as string)
        yield put(setDarkAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getDomaineSaga(actions: BranIntelAction) {
    try {
        const response: Domaine[] = yield call(getDomainsApi, actions.payload as string)
        yield put(setDomainAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

