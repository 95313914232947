import axios from "axios";
import { ApprouveUserReq, Entreprise, EntrepriseSupaBase, ExpiredToken, LoginFirstTimeRes, LoginRes, UserEntrepriseReq, UsersEntreprise } from "../constants";
import { env } from "../../environement/env";

axios.defaults.withCredentials = true

export const getEntrepriseInfoAPI = async () => {
    try {
        const url = `${env.apiUrl}/entreprise/`;
        const { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Entreprise | ExpiredToken;
    } catch (error) {
        throw error;
    }
}

export const getAllEntreprisesAPI  = async () => {
    try {
        const url = `${env.apiUrl}/entreprise/getAllCompanies`;
        const { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as EntrepriseSupaBase[];
    } catch (error) {
        throw error;
    }
}

export const selectCompanyAPI  = async (userId: string, entrepriseId: number) => {
    try {
        const url = `${env.apiUrl}/entreprise/selectCompany`;
        const { data } = await axios.post(url,
            {
                userId,
                entrepriseId
            },
             {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateInfosEntrepriseAPI = async (dataEntreprise: Entreprise) => {
    try {
        const url = `${env.apiUrl}/entreprise/update`;
        const { data } = await axios.post(url, dataEntreprise, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as LoginFirstTimeRes
    } catch (error) {
        throw error;
    }
}

export const approuveUserAPI = async (dataSend: ApprouveUserReq) => {
    try {
        const url = `${env.apiUrl}/user/approuveUser`;
        const { data } = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as LoginFirstTimeRes | ExpiredToken
    } catch (error) {
        throw error;
    }
}

export const addUserAPI = async (dataUser: UserEntrepriseReq) => {
    try {
        
        const url = `${env.apiUrl}/user/saveUserInvite`;
        const { data } = await axios.post(url, 
            dataUser, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        window.localStorage.setItem('jwt', data?.jwt)
       window.localStorage.setItem("type", data?.typeEntreprise)
        return data as LoginRes | LoginFirstTimeRes
    } catch (error) {
        throw error;
    }
}

export const getUsersEntrepriseAPI = async () => {
    try {
        const url = `${env.apiUrl}/entreprise/getUsers`;
        const { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as UsersEntreprise[] | ExpiredToken
    } catch (error) {
        throw error;
    }
}

export const deleteUserApi = async (id: number) => {
    try {
        const url = `${env.apiUrl}/user/delete`
        await axios.post(url, {
            userId: id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
    } catch (error) {
        throw error;
    }
}

export const deleteUsersAPI = async (userIds: number[]) => {
    try {
        const url = `${env.apiUrl}/user/deleteUsers`
        await axios.post(url, {
            users: userIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
    } catch (error) {
        throw error;
    }
}


export async function uploadEntreprisePictureProfileAPI(dataSend: FormData) {
    try {
        const { data } =await axios.post(`${env.apiUrl}/entreprise/uploadProfilePicture`,dataSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as string
    } catch (error) {
        console.error(error)
    }
}

export const removeEntreprisePictureAPI = async () => {
    try {
         const {data} =await axios.post(`${env.apiUrl}/entreprise/removeEntrepriseUser`, {},{
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
         }
        )
        return data
    } catch (error) {
        throw error
    }
}

