import React, {FormEvent, useState, useEffect} from 'react'
import Header from '../Dashboard/common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import AvatarUpload from './components/AvatarUpload'
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import { getUserInfoAction, getUserInfoRequest, updateUserInfoRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { UserInfo } from '../../core/constants'
import phonecodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import { useNavigate } from 'react-router-dom'
import { authSelector } from '../../core/reducers/authReducer'
import ReactGA from 'react-ga4';
import { checkUsernameApi } from '../../core/api/userApi'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
    },
    validEmail: {
    },
    disabledInput:{
        color: "#333333"
    }
  }),
);

const PersonnalAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { auth } = useSelector(authSelector)
    const { userInfo, message } = useSelector(userSelector)
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [userNameError, setuserNameError] = useState<boolean | undefined>(false)
    const [emailSecondary, setEmailSecondary] = useState<string>('');
    const [errorSecondary, setErrorSecondary] = useState<boolean>(false);
    const [formState, setformState] = useState<UserInfo>(userInfo);
    const [openNotification, setopenNotification] = useState(false)
    const [openNotfUser, setopenNotfUser] = useState(false)
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean>(false)
    
    useEffect(() => {
        document.title = "Profile Settings"
        ReactGA.send({ hitType: "Profile Settings", page: window.location.pathname });

        const isUpdated = Object.keys(userInfo)
        .filter(key => key !== 'profilePicture') // Exclude 'profilePicture' from the comparison
        .some(key => userInfo[key] !== formState[key]);
        
        setDataIsUpdated(isUpdated);
            
      }, [formState, userInfo])
    const validateEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);
        setError(!validateEmail(value));
    };

    const handleChangeEmailSecondary = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmailSecondary(value);
        setErrorSecondary(!validateEmail(value));
    };


    // const handleChange = (event: SelectChangeEvent) => {
    //     setRecent(event.target.value as string);
    //   };

    const handleChange = async (event: typesHandleChange) => {
        const { name, value } = event.target;
        if(name === 'secondaryEmail') {
            setErrorSecondary(!validateEmail(value));
        }
        if(name === 'email') {
            setError(!validateEmail(value));
        }
        
        setformState((prevState: any) => ({
        ...prevState,
        [name]: value,
        }));
    }

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(updateUserInfoRequest({
            userReq: formState
        }))
        setTimeout(() => {
            dispatch(getUserInfoRequest())
            setopenNotification(true)
        }, 2000);
       
    }

    useEffect(() => {
      setformState(userInfo);
    }, [userInfo])
    
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }

    const handleCloseNotificationUsername = () => {
        setopenNotfUser(false)
    }


    
   
    
    
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderPersonalInfo title='Profile' dataIsUpdated={dataIsUpdated} handleSubmit={handleSubmit} setformState={setformState as any}/>
                <div  className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Profile Picture</p>
                    {/*<p className={styles.avatarPersonalAccountCard_text}>Your profile photo appears when you log in to Defendis and is visible to others in your organisation. You can update your profile photo at any time and it will automatically update any where it appears.</p>*/}
                    <AvatarUpload/>
                </div>
                <div  style={{marginTop: 24}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Personal Information</p>
                    {/* <p className={styles.avatarPersonalAccountCard_text}>
                        This the personal information you use to access and manage your account. You can also add a mobile
                        phone number and secondary email for account security, recovery, and notifications.
                    </p> */}
                    <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" fullWidth>
                    <div style={{display: 'grid', gap: 16, marginBottom: 12}}>
                        <div className={styles.personalInfosInputs}>
                            <TextField InputProps={{sx: { fontSize: 14 }}}
                                value={formState.firstName}
                                name='firstName'
                                id="standard-basic"
                                label="First Name" 
                                variant="standard" 
                                onChange={handleChange}
                                />
                            <TextField InputProps={{sx: { fontSize: 14 }}}
                                value={formState.lastName}
                                onChange={handleChange}
                                name='lastName'
                                id="standard-basic"
                                label="Last Name"
                                variant="standard"
                                />
                        </div>
                        <div className={styles.personalInfosInputs}>
                            <div className={styles.primaryEmailPersonalInfo}>
                                <TextField InputProps={{sx: { fontSize: 14 }}}
                                    onChange={handleChange}
                                    value={formState.email}
                                    name='email'
                                    id="standard-basic"
                                    label="Work Email"
                                    variant="standard"
                                    className={formState.email != "" ? error ? classes.unvalidEmail: classes.validEmail : undefined}
                                    />
                                {formState.email!=="" && (
                                    <FontAwesomeIcon style={{marginTop: 36,marginLeft:-20}} icon={error ? faCircleXmark : faCircleCheck} color={error ? "#E11900" : "#20B444"}/>
                                )}
                            </div>
                            <div className={styles.primaryEmailPersonalInfo}>
                                <TextField InputProps={{sx: { fontSize: 14 }}}
                                    onChange={handleChange}value={formState.username}
                                    name='username'
                                    id="standard-basic"
                                    label="Username"
                                    variant="standard"
                                    />
                                {//@ts-ignore
                                    !formState.isUnique && (
                                    <FontAwesomeIcon style={{marginTop: 36,marginLeft:-20}} icon={faCircleXmark} color={"#E11900"}/>
                                )}
                            </div>
                        </div>
                        <div className={styles.personalInfosInputs} style={{width:'47%'}}>
                            <Select IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7} style={{ marginLeft: "-20px", marginRight: "-10px" }} />} style={{ backgroundColor:"transparent", marginLeft: -12, marginBottom: -14, marginRight: -6}}
                                name='phonecode'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formState.phonecode}
                                onChange={handleChange}
                                className={styles.selectPhoneNumberPersonalInfo}
                                renderValue={(value) => `${value}`}

                                MenuProps={{
                                    PaperProps: {
                                    sx: {
                                        backgroundColor: '#1f1f1f',
                                        borderRadius: '8px',
                                        padding: '0px',
                                        boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                        maxHeight: '360px',
                                        overflowY: 'auto',
                                    },
                                    },
                                    MenuListProps: {
                                    dense: false,
                                    disablePadding: true,
                                    },
                                    anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                    },
                                    transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                    },
                        }}
                            >
                                {
                                    // @ts-ignore
                                    phonecodes.sort((a, b)=>a?.name-b?.name).map((code) => <MenuItem value={code.dial_code} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 24px 8px 16px'}}>{code.name} ({code.dial_code})</MenuItem>)
                                }
                            </Select>
                            <TextField InputProps={{sx: { fontSize: 14 }}}
                                onChange={handleChange} value={formState.phone} name='phone' id="standard-basic" className={styles.phoneNumberTextField} variant="standard" />
                        </div>
                    </div>
                    </FormControl>
                    </form>
                </div>
                {/* <div style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Preferences</p>
                    <div className={styles.preferenceAccountInfo}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recent}
                        onChange={handleChange}
                        className={styles.selectPreferences}
                    >
                        <MenuItem value={'10'}>{"English (United kingdom)"}</MenuItem>
                        <MenuItem value={'20'}>{"Frensh (France)"}</MenuItem>
                    </Select>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recent}
                        onChange={handleChange}
                        className={styles.selectPreferences}
                        
                    >

                        <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                        <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
                    </div>      
                </div> */}
            </div>
            <ErrorNotification
                severity={message ==="User updated successfuly" ? 'success': 'error'}
                open={openNotification}
                message={message}
                handleClose={handleCloseNotification}
            />
           
        </div>
  )
}

export default PersonnalAccount