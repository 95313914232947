import { faCalendar, faChevronDown, faChevronUp, faDownload, faEye, faSearch, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import { CredentialsLeak, REQUEST_CREADENTIALS_LEAK } from '../../../core/constants'
import { setCredentialLeakDetail } from '../../../core/actions/threatIntelActions'
import moment from 'moment'
import ReactGA from 'react-ga4';
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Alert, Snackbar } from '@mui/material'
// import Pagination from '../common/Pagination'
import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import Loader from "../../../icons/Loader.svg"
import EmptyState from '../common/EmptyState'
import { getCredentialLeakExcelApi, PageSort } from '../../../core/api/threatIntelApi'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'
import { exportToExcel } from '../components/ExportToExcel'
import dateFormats from '../dateFormats'

type SortOrder = 'asc' | 'desc';


export const renderColors = (riskScore: number) => {
  if (riskScore === 0.0)
    return {
      textColor: "#757575",
      backgroundColor: "#e6e6e6",
      borderColor: "#757575"
    }
  else if (riskScore >= 0.1 && riskScore <= 3.9)
    return {
      textColor: "#20b444",
      backgroundColor: "#ecf9ef",
      borderColor: "#20b444"
    }
  else if (riskScore >= 4.0 && riskScore <= 6.9)
    return {
      textColor: "#A08100",
      backgroundColor: "#FFEB96",
      borderColor: "#A08100"
    }
  else if (riskScore >= 7.0 && riskScore <= 8.9)
    return {
      textColor: "#894C02",
      backgroundColor: "#FFD8A7",
      borderColor: "#894C02"
    }
  else if (riskScore >= 9.0 && riskScore <= 10.0)
    return {
      textColor: "#e11900",
      backgroundColor: "#FBD2C4",
      borderColor: "#e11900"
    }
  else
    return {
      textColor: "#757575",
      backgroundColor: "#e6e6e6",
      borderColor: "#757575"
    }
}

export default function CredentialsLeaks() {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [recent, setRecent] = useState('all')
  const [severity, setSeverity] = useState("critical")
  const [sortBy, setSortBy] = useState<string>('log_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const { databaseLeak, credentialsLeak, totalRecordsCredentials, isLoadingCredentials } = useSelector(threatIntelSelector)
  const { notifs, notifsUser } = useSelector(notificationSelector)
  const [visiblePasswords, setVisiblePasswords] = useState<any>({});
  const [page, setPage] = useState(1)
  const [search, setsearch] = useState("")
  const [isDonwloaded, setisDonwloaded] = useState(false)

  useEffect(() => {

    document.title = "Credential Leaks"
    ReactGA.send({ hitType: "Credential Leaks", page: window.location.pathname });
    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
    };
    if (!credentialsLeak) {
      dispatch({
        type: REQUEST_CREADENTIALS_LEAK,
        payload: dataCred
      })
    }
  }, [])

  const setCredentialDetail = (item: any) => {
    const colors = renderColors(Math.round(Number(item?.total_risk_score)));

    const itemForDisplay = {
      ...item,
      textColor: colors.textColor,
      borderColor: colors.borderColor,
      bgColor: colors.backgroundColor
    };
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
    navigate("/threatIntel/credentialLeaksDetail")
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      sortBy: search
    }
    setPage(value)
    dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
  };


  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleSearch = (
  ) => {
    if (search !== "") {
      const dataCred: PageSort = {
        page: page,
        sortBy: search
      }
      dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
    } else {
      const dataCred: PageSort = {
        page: page,
        sortBy: null
      }
      dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
    }
  };

  const creditsPasswordsNotif = notifs?.filter(item => item.isCredentials === true && notifsUser?.some((not: any) => not.notifId === item.id && not.read === false))


  const sortedResult = credentialsLeak?.data?.sort((a, b) => {
    const aInPasswordNotif = creditsPasswordsNotif.some((notif) => notif?.data?.passwordid === a.passwordid);
    const bInPasswordNotif = creditsPasswordsNotif.some((notif) => notif?.data?.passwordid === b.passwordid);

    // Sort elements in vulNotif to the top
    if (aInPasswordNotif && !bInPasswordNotif) return -1;
    if (!aInPasswordNotif && bInPasswordNotif) return 1;
    return 1
  })

  const finalSort = sortedResult?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'username') {
      comparison = a.username.localeCompare(b.username)
    } else if (sortBy === 'url') {
      comparison = a.url.localeCompare(b.url)
    } else if (sortBy === 'total_risk_score') {
      comparison = Number(a?.total_risk_score).toFixed(1).localeCompare(Number(b?.total_risk_score).toFixed(1))
    } else if (sortBy === 'log_date') {
      comparison = a.user.log_date.localeCompare(b.user.log_date)
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })




  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = async () => {
    const result: any[] = await getCredentialLeakExcelApi()
    const dataXlsx = result?.map((item: any) => ({
      'UserName': item?.username,
      'Password': item?.password,
      'Url': item?.url,
      'Risk Score': item?.total_risk_score,
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Credentials_Leaks-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };



  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e) => setsearch(e.target.value)} />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch} />
        </div>
        {/* <div style={{display: 'flex', alignItems: "center", gap: 10}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={severity}
                onChange={handleChangeSeverity}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'critical'}>Critical</MenuItem>
                        <MenuItem value={'high'}>High</MenuItem>
                        <MenuItem value={'medium'}>Medium</MenuItem>
                        <MenuItem value={'low'}>Low</MenuItem>
            </Select>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                onChange={handleChangeSortBY}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'severity'}>Severity</MenuItem>
                        <MenuItem value={'date'}>Date</MenuItem>
                        <MenuItem value={'id'}>ID</MenuItem>
            </Select>
        </div> */}
        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 14 }} />
        </div>


      </div>
      {
        !isLoadingCredentials ?
          sortedResult && sortedResult.length !== 0 ?
            <div style={{ width: "100%" }}>
              <table className={styles.table}>
                <tr className={styles.tableHead} >
                  <th></th>
                  <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                    <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                      <span style={{ marginRight: '4px' }}>Username</span>
                      <FontAwesomeIcon
                        icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                        size="1x"
                        height={12}
                        width={11}
                      />
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8 }}>
                    <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                      <span style={{ marginRight: '4px' }}>Password</span>
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                    <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                      <span style={{ marginRight: '4px' }}>Source</span>
                      <FontAwesomeIcon
                        icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                        size="1x"
                        height={12}
                        width={11}
                      />
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8 }} onClick={() => handleSort('total_risk_score')}>
                    <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                      <span style={{ marginRight: '4px' }}>Risk Score</span>
                      <FontAwesomeIcon
                        icon={sortBy === 'total_risk_score' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                        size="1x"
                        height={12}
                        width={11}
                      />
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8 }} onClick={() => handleSort('log_date')}>
                    <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                      <span style={{ marginRight: '4px' }}>Leaked on</span>
                      <FontAwesomeIcon
                        icon={sortBy === 'log_date' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                        size="1x"
                        height={12}
                        width={11}
                      />
                    </div>
                  </th>
                </tr>
                {
                  finalSort?.map(item =>
                    <tr className={styles.tableActivityRowDomainBrand} onClick={() => setCredentialDetail(item)}>
                      <td className={styles.tableCell} style={{ width: 6 }}>
                        {
                          creditsPasswordsNotif.some((respItem: any) => {

                            return respItem?.data?.passwordid === item.passwordid;
                          }) ? (
                            <div className={styles.redPoint} />
                          ) : (
                            null // Nothing is displayed if the condition is not met
                          )
                        }
                      </td>
                      <td className={styles.tableCell}>
                      {(item?.username ? item?.username : "-").toLowerCase()}
                      </td>
                      <td className={styles.tableCell} style={{ paddingLeft: 8, width: 100 }}>
                        {item.password ? `${item.password.slice(0, 3)}${item.password.slice(3).replace(/./g, '*')}` : "-"}
                      </td>
                      <td className={styles.tableCell} style={{ paddingLeft: 8 }}>
                        {item?.url?.includes('android') ? item?.url?.split('@')[1] : item?.url?.substring(0, 43)}
                      </td>
                      <td className={styles.tableCell} style={{ padding: "13px 0px", width: 150 }}>
                        <span className={styles.scoreSeverity} style={{
                          width: '22px',
                          color: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).textColor,
                          border: `1px solid ${renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).borderColor}`,
                          backgroundColor: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).backgroundColor
                        }}>{
                            Number(item?.total_risk_score).toFixed(1)
                          }</span>
                      </td>
                      <td className={styles.tableCell} style={{ paddingLeft: 8, width: 150 }}>
                        <div style={{}}>
                          {
                            item?.user ? (
                              <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey" />
                                <span style={{ fontSize: 14 }}> 
                                  {item.user.log_date && moment(item.user.log_date, dateFormats, true).isValid()
                                    ? moment(item.user.log_date, dateFormats, true).format('MMM DD, YYYY')
                                    : item.user.created_at && moment(item.user.created_at).format('MMM DD, YYYY')
                                  }
                                </span>
                              </div>
                            ) : "-"
                          }
                        </div>
                      </td>

                    </tr>
                  )
                }
              </table>
              <Pagination
                showFirstButton
                showLastButton
                shape="rounded"
                page={Number(credentialsLeak?.pagination?.currentPage)}
                count={credentialsLeak?.pagination?.totalPages}
                onChange={handleChangePage}
                sx={{
                  '& .MuiPagination-ul': {
                    justifyContent: "center",
                    display: 'flex'
                  },
                  '& .Mui-selected': {
                    color: '#ffffff', // Text color for active page
                    backgroundColor: '#1f1f1f', // Background color for active page
                    opacity: 0.3
                  },
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff', // Text color for inactive pages
                  },
                }}
              />
            </div>
            :
            <div className={styles.loaderVul}>
              <EmptyState />
            </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }

      <Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>

    </div>
  )
}


{/* <Pagination 
        
          onChange={handleChangeNextPage}
          page={localStorage.getItem('currentPage')?parseInt(JSON.parse(localStorage.getItem('currentPage')||"1")):1}
          count={credentialsLeak?.pagination?.totalRecords  }
           shape="rounded"
           sx={{
            "& .MuiPaginationItem-root": {
              color: '#333333',  // Sets color for all numbers
          },
          "& .Mui-selected": {
              backgroundColor: '#1f1f1f',  // Sets background for the current page
              color: 'white',  // Optional: Change text color for the selected item
          },
          "& .MuiPaginationItem-root:hover": {
              backgroundColor: theme.palette.action.hover,  // Optional: Change on hover
          }
           }}
           /> */}