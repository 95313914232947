import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './dashboard.module.scss'
import CredentialsLeaks from './ThreatIntelSections/CredentialsLeaks'
import InsideThreats from './ThreatIntelSections/InsideThreats'
import Overview from './ThreatIntelSections/Overview'
import PaymentCardLeaks from './ThreatIntelSections/PaymentCardLeaks'
import DocumentLeaks from './ThreatIntelSections/Components/credentialSections/DocumentLeaks'
import DatabaseLeaks from './ThreatIntelSections/Components/credentialSections/DatabaseLeaks'
import { setNamePageLocalStorage } from '../../core/actions/stepsNavPageActions'
import { useSelector } from 'react-redux'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'

const nameSteps = [
    "overview",
    "credentialLeaks",
    "insiderThreats",
    "paymentCardLeaks",
    "documentsLeak",
    "databaseLeaks"
]

function ThreatIntelView() {
    const navigate = useNavigate()
    const [recent, setRecent] = useState('all')
    const storedStepName = localStorage.getItem('step') || 'credentialLeaks';
    const [activeCompo, setactiveCompo] = useState(storedStepName)
    const { entreprise } = useSelector(entrepriseSelector)
    // const state: any = useSelector((state) => state)
    // const entreprise = state.entreprise
    const handleChange = (event: SelectChangeEvent) => {
        setRecent(event.target.value as string);
    }

    const renderSections = () => {
        switch (activeCompo) {
            case 'credentialLeaks':
                return <CredentialsLeaks />
            case 'paymentCardLeaks':
                return <PaymentCardLeaks />
            case 'insiderThreats':
                return <InsideThreats/>
            case 'documentsLeak':
                return <DocumentLeaks />
            // case 'databaseLeaks':
            //     return <DatabaseLeaks/>
            default:
                return;
        }
    }
    const handleChangeStep = (newStepName: string) => {
        setNamePageLocalStorage(newStepName)
        setactiveCompo(newStepName);
    };

    useEffect(() => {
        if (nameSteps.includes(storedStepName))
            setactiveCompo(storedStepName);
        else
            setactiveCompo("credentialLeaks")
    }, [storedStepName]);
    return (
        <div className={styles.main}>
            <Header />
            <Menu />
            <div className={styles.content}>
                <div className={styles.menuContent}>
                    <div className={styles.firstSectionMenu}>
                        {/* <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>handleChangeStep('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</p> */}
                        {
                            entreprise?.subscriptionData?.credentials && <p style={{ cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500 }} onClick={() => handleChangeStep('credentialLeaks')} className={activeCompo === 'credentialLeaks' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Credential Leaks</p>
                        }
                        {
                            entreprise?.subscriptionData?.paymentCards && <p style={{ cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500 }} onClick={() => handleChangeStep('paymentCardLeaks')} className={activeCompo === 'paymentCardLeaks' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Payment Card Leaks</p>
                        }
                        {
                            entreprise?.subscriptionData?.documents && <p style={{ cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500 }} onClick={() => handleChangeStep('documentsLeak')} className={activeCompo === 'documentsLeak' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Document Leaks</p>
                        }
                        {/*
                            <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>handleChangeStep('insiderThreats')} className={activeCompo === 'insiderThreats' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Compromised Victims</p>

                            entreprise?.subscriptionData?.databases  && <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>handleChangeStep('databaseLeaks')} className={activeCompo === 'databaseLeaks' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Database Leaks</p> */}
                    </div>
                    {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: 500}}
            >
                <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select> */}
                </div>
                <hr className={styles.divider} />
                {
                    renderSections()
                }
            </div>
        </div>
    )
}

export default ThreatIntelView
