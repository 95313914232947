import { Theme } from '@emotion/react';
import { Button, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@mui/styles';
import styles from "./auth.module.scss"
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../core/reducers/authReducer';
import { setNewPasswordRequestAction, updatePasswordRecoverRequest } from '../../core/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorNotification from '../Account/common/ErrorNotification';
import { useIntercom } from '../Dashboard/IntercomSetup';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidPassword: {
      borderBottom: '1px solid red !important',
    },
    validPassword: {
      borderBottom: '1px solid green !important',
    }
  }),
);



function NewPassword() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const classes = useStyles();

  const [password, setPassword] = useState<string>('')
  const [rePassword, setRePassword] = useState<string>('')
  const [error, setError] = useState<boolean>(false);
  const [OpenNotification, setOpenNotification] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorRetrieveToken, setErrorRetrieveToken] = useState<string | null>(null);

  const emailStorageVa = window.localStorage.getItem('email')
  const hmacValue = window.localStorage.getItem('hmac')
  const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
  const profilePicture = window.localStorage.getItem("avatar")

  useIntercom('isows87y', {
    app_id: 'isows87y',
    api_base: 'https://api-iam.intercom.io',
    email: emailStorageVa as string,
    user_hash: hmacValue as string,
    name: fullNamed,
    avatar: profilePicture,
  })
  

  const useQuery = () => {
    return new URLSearchParams(useLocation().hash.substring(1)); // Access hash part of the URL
  };
  const query = useQuery();
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPassword(value);
    setError(value !== rePassword);
  };
  const handleChangeRePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setRePassword(value);
    setError(password !== value);
  };

  const submit = () => {
    if (!error && accessToken) {
      dispatch(updatePasswordRecoverRequest({
        jwt: accessToken,
        password
      }))
    }
  }

  useEffect(() => {
    document.title = 'Login - New Password'
    const token = query.get('access_token');
    // console.log("token: " + token)
    const errorMessage = query.get('error');
    const errorCode = query.get('error_code');
    const errorDescription = query.get('error_description');

    if (errorMessage) {
      // Handle error if it's present in the URL
      setErrorRetrieveToken(`Error ${errorCode}: ${decodeURIComponent(errorDescription || 'Unknown error')}`);
    } else if (token) {
      // If access_token is present, set it to the state
      setAccessToken(token);
    } else {
      // If no token or error found, set a default error message
      setErrorRetrieveToken('No access token found or the link might have expired.');
    }
    if (auth.passwordIsUpdated) {
      setOpenNotification(true)
      setTimeout(() => {
        navigate('/')
      }, 1500);
    }
  }, [auth, query])

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  return (
    <div className={styles.main}>
      <div className={styles.img}>
        <img src={"/Content.png"} height="99.3%" width="100%" />
        <img src="/defendisLogo.png" className={styles.logo} />
      </div>
      <div className={styles.login}>
        <h2 className={styles.lightText}>New Password</h2>
        <p style={{ fontSize: 12 }} className={styles.lightText}>The longer and more random your password, the better.</p>
        <div className={styles.primaryEmailPersonalInfo}>
          <TextField onChange={handleChangePassword} className={password !== "" && rePassword !== "" ? error ? classes.unvalidPassword : classes.validPassword : undefined} id="standard-basic" label="Password" variant="standard" />
          {password !== "" && rePassword !== "" && (
            <FontAwesomeIcon style={{ marginTop: 36, marginLeft: -20 }} icon={error ? faCircleXmark : faCircleCheck} color={error ? "#E11900" : "#20B444"} />
          )}
        </div>
        <div className={styles.primaryEmailPersonalInfo}>
          <TextField onChange={handleChangeRePassword} className={password !== "" && rePassword !== "" ? error ? classes.unvalidPassword : classes.validPassword : undefined} id="standard-basic" label="Re-type Password" variant="standard" />
          {password !== "" && rePassword !== "" && (
            <FontAwesomeIcon style={{ marginTop: 36, marginLeft: -20 }} icon={error ? faCircleXmark : faCircleCheck} color={error ? "#E11900" : "#20B444"} />
          )}
        </div>
        <Button onClick={submit} className={styles.btnLogin}>Recover Account</Button>
        <div className={styles.demo}>
          <p style={{ fontSize: 12, textAlign: "center" }} className={styles.lightText}>Need help? <span style={{ textAlign: "center", fontWeight: "600", textDecoration: "underline" }}>Connect with support</span></p>
        </div>
      </div>
      {
        auth.passwordIsUpdated && <ErrorNotification
          severity={`${errorRetrieveToken?.includes("403") ? "error" : "success"}`}
          open={OpenNotification}
          message={errorRetrieveToken?.includes("403") ? errorRetrieveToken : auth.notification}
          handleClose={handleCloseNotification}
        />
      }
    </div>
  )
}

export default NewPassword
