import React, { useState } from 'react'
import styles from "../dashboard.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronDown, faListDots } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material'
import DnsRecords from './DnsRecords'
import WhoisRecords from './WhoisRecords'
import Comments from './Comments'
import DomainTakeDownRequest from './DomainTakeDownRequest'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { SET_STEP_PAGE } from '../../../core/constants'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import moment from 'moment'

function DomainDetail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { domaineDetail } = useSelector(brandIntelSelector)
    const [activeCompo, setactiveCompo] = useState('whois')
    const renderSection = () => {
        switch (activeCompo) {
            case 'dns':
                return <DnsRecords />
            case 'whois':
                return <WhoisRecords />
            default:
                return;
        }
    }
    // console.log(domaineDetail)

  return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>{
                            dispatch({
                                type: SET_STEP_PAGE,
                                payload: "domains"
                            })
                            navigate("/brandIntel")
                        }} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Go Back / {domaineDetail?.id}</span>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop:4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                    <span>{domaineDetail?.domain}</span>
                        <span className={styles.scoreSeverity}>{domaineDetail?.score}</span>
                    </div>  
                </div>
                {/* <div style={{display: "flex", alignItems: "center", gap: 10,marginTop:-30}}>
                    <Button style={{ width:"auto", height: '40px', gap: 10, fontSize:14, fontWeight:'500', backgroundColor: "#00b0ff", display: 'flex', alignItems: "center"}}>
                        Export <FontAwesomeIcon icon={faChevronDown} height={19} width={20} />
                    </Button>
                    <Button onClick={()=>navigate("/brandIntel/requestTakeDown")} style={{ height: '40px',border:'1px solid #242424', color:"white", gap: 10, fontSize:14, fontWeight:'500', backgroundColor: "#1a1a1a", display: 'flex', alignItems: "center", width:"auto"}}>
                    Request Takedown
                    </Button>
                        </div> */}
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24}}>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Detected on</span>
                    <span style={{color: "white"}}>{moment(domaineDetail?.detection_date)?.format("MMMM DD, YYYY")}</span>
                    </div>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                   
                    </div>
                </div>

                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px' }} className={styles.firstSectionMenu}>
                    <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('whois')} className={activeCompo === 'whois' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Whois Record</p>
                    <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('dns')} className={activeCompo === 'dns' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>DNS Records</p>
                </div>

                {renderSection()}

    </div>
    </div>
  )
}

export default DomainDetail