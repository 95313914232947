import React from 'react'
import styles from '../../../dashboard.module.scss'
export default function MalwarePath({user}:any) {
    return (
        <table className={styles.table}style={{marginTop:8}}>
        <tbody>
           {
            user?.malware_path ?
            <tr  className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '805px', padding: '15px 16px', color: '#ffffff'}} className={styles.tableCell}>
                {user?.malware_path}
              </td>
              <td style={{display:"flex", justifyContent:"end", padding: '15px 16px', color: '#afafaf'}} className={styles.tableCell}>
                {user?.hwid}
              </td>
            </tr>
          : <tr  className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '805px', padding: '15px 16px'}} className={styles.tableCell}>
                {user?.hwid}
              </td>
            </tr>
          }
          </tbody>
      </table>
    )
}
