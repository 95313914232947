import { useEffect } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import Content from './Content'
import styles from "../Account/account.module.scss"
import { useDispatch } from 'react-redux'

import { useSelector } from 'react-redux'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4';
import { checkIfTokenExpiredApi } from '../../core/api/authApi'

function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { auth } = useSelector(authSelector)

    const getAndVerifyToken = async () => {
        const token = window.localStorage.getItem('jwt');
        if (!token) {
            navigate('/login')
        } else {
            const response = await checkIfTokenExpiredApi(token)
            console.log(response)
            if (!response.authentificated)
                navigate("/login")

        }
    };

    useEffect(() => {
        document.title = "Home"
        ReactGA.send({ hitType: "Home", page: window.location.pathname });




    }, [])

    return (
        <div className={styles.main}>
            <Menu />
            <Header />
            <Content />
        </div>
    )
}

export default Home
