import { faCalendar, faChevronDown, faChevronUp, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import moment from 'moment'
import { REQUEST_VICTIMS } from '../../../core/constants'
import ReactGA from 'react-ga4';
import UnassignedLogo from '../../../icons/Unassigned.svg'
import { exportToExcel } from '../components/ExportToExcel'
import { Alert, Avatar, CircularProgress, Pagination, Snackbar } from '@mui/material'
import Loader from "../../../icons/Loader.svg"
import { CircleFlag } from 'react-circle-flags'
import EmptyState from '../common/EmptyState'
import { SET_VIC_DETAIL, SortVul } from '../../../core/constants';
import EmptyStateVictims from '../common/EmptyStateVictims'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { useJune } from '../../../core/useJune';
import { PageSort } from '../../../core/api/threatIntelApi';

type SortOrder = 'asc' | 'desc'

function VictimsSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { victims, isLoadingVictims } = useSelector(ransomeSelector)
  const [search, setsearch] = useState('')
  const [isDonwloaded, setisDonwloaded] = useState(false)
  const [sortBy, setSortBy] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const analytics = useJune('06Aa9ruF6ggwXtYX');
  const [page, setPage] = useState(1)
  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      search: search,
      sortBy:null
    }
    setPage(value)
    dispatch({
      type: REQUEST_VICTIMS,
      payload: dataCred
    })
  };
  useEffect(() => {

    document.title = "Ransomware Victims"
    ReactGA.send({ hitType: "Ransomware Victims", page: window.location.pathname });
    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
      search:""
    };
    if (!victims) {
      dispatch({
        type: REQUEST_VICTIMS,
        payload: dataCred
      })
    }
  }, [])

  const dataXlsx = victims?.data?.map(item => ({
    'country': item?.country,
    'Victims': item?.post_title,
    'Groups': item?.group_name,
    'Discovery date': moment(item?.discovered).format('MMMM DD, YYYY'),
    'Attack date': moment(item?.published).format('MMMM DD, YYYY')
  }))

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = () => {
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Ransomware_victims-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };

  const trackExport = useCallback(() => {
    if (analytics) analytics.track('Victims Export');
  }, [analytics]);


  const handleClick = () => {
    trackExport();
    handleExportList();
  };

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }

  const results = victims?.data
  

  const sortedVictims = results?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'country') {
      comparison = a.country.localeCompare(b.country)
    } else if (sortBy === 'post_title') {
      comparison = a.post_title.localeCompare(b.post_title)
    } else if (sortBy === 'discovered') {
      comparison = a.discovered.localeCompare(b.discovered)
    } else if (sortBy === 'group_name') {
      comparison = a.group_name.localeCompare(b.group_name)
    } else if (sortBy === 'published') {
      comparison = a.published.localeCompare(b.published)
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })


  const handleSearch = () => {
    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
      search:search
    };
      dispatch({
        type: REQUEST_VICTIMS,
        payload: dataCred
      })
  }


  return (
    <div className={styles.awaitingApproval} style={{ marginTop: 18 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div className={styles.searchAndIcon}>
          <input onChange={(e) => setsearch(e.target.value)} className={styles.search} name='search' placeholder='Search' />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'onClick={handleSearch} />
        </div>
        {/*<div className={styles.removeSelected} onClick={handleClick}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 12}} />
                            </div> */}
      </div>
      {
        !isLoadingVictims ?
          sortedVictims && sortedVictims?.length !== 0 ?
            <><table className={styles.table} >
              <thead>
                <tr className={styles.tableHead}>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('country')}>
                    Country {" "}
                    <FontAwesomeIcon
                      icon={sortBy === 'country' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('post_title')}>
                    Victims {" "}
                    <FontAwesomeIcon
                      icon={sortBy === 'post_title' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('group_name')}>
                    Groups {" "}
                    <FontAwesomeIcon
                      icon={sortBy === 'group_name' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('discovered')}>
                    Published on {" "}
                    <FontAwesomeIcon
                      icon={sortBy === 'discovered' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </th>
                  <th style={{ paddingLeft: 8, display: "flex", justifyContent: "start", paddingRight: 13, cursor: 'pointer' }} onClick={() => handleSort('published')}>
                    <p style={{ display: "flex", alignItems: 'center', gap: 5, width: 150 }}>
                      <span>Attacked on</span>
                      <FontAwesomeIcon
                        icon={sortBy === 'published' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                        size="1x"
                        height={12}
                        width={11}
                      />
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedVictims?.map(item =>
                  <tr style={{ cursor: 'pointer' }} className={styles.tableActivityRowWatchListAssets} onClick={() => {
                    dispatch({ type: SET_VIC_DETAIL, payload: item });
                    navigate("/ransomwareIntel/VictimsDetail");
                  }}>
                    <td style={{ width: '90px', padding: '12px 16px' }} className={styles.tableCell}>
                      {
                        item?.country ?
                          <CircleFlag countryCode={item?.country?.toLowerCase()} height="28" />
                          :
                          <Avatar src={UnassignedLogo}
                            sx={{
                              width: 28,
                              height: 28,
                              borderRadius: '50%',
                              overflow: 'hidden',
                              img: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '50%',
                              },
                            }}
                          />
                      }


                    </td>
                    <td className={styles.tableCell}>
                      {item?.post_title}
                    </td>
                    <td style={{ width: '200px' }} className={styles.tableCell}>
                      {item?.group_name}
                    </td>
                    <td style={{ width: '180px' }} className={styles.tableCell} >
                      <div style={{ display: 'flex', alignItems: "center", gap: 5, justifyContent: "start" }}>
                        <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                        {" " + moment(item?.discovered).format('MMM DD, YYYY')}
                      </div>
                    </td>
                    <td style={{ width: '150px', paddingLeft: 8 }} className={styles.tableCell} >
                      <div>
                        <div style={{ display: 'flex', alignItems: "center", gap: 5, justifyContent: "start" }}>
                          <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                          {" " + moment(item?.published).format('MMM DD, YYYY')}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>

              <Pagination
                showFirstButton
                showLastButton
                shape="rounded"
                page={Number(victims?.pagination?.currentPage)}
                count={victims?.pagination?.totalPages}
                onChange={handleChangePage}
                sx={{
                  '& .MuiPagination-ul': {
                    justifyContent: "center",
                    display: 'flex'
                  },
                  '& .Mui-selected': {
                    color: '#ffffff', // Text color for active page
                    backgroundColor: '#1f1f1f', // Background color for active page
                    opacity: 0.3
                  },
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff', // Text color for inactive pages
                  },
                }}
              /></> :
            <div className={styles.loaderVul}>
              <EmptyStateVictims />
            </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }
      <Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default VictimsSection
