import { faCalendar, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styles from '../../dashboard.module.scss';
import { useSelector } from 'react-redux';
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector';
import { Avatar, CircularProgress } from '@mui/material';
import moment from 'moment';
import { CircleFlag } from 'react-circle-flags';
import UnassignedLogo from '../../../../icons/Unassigned.svg';
import { SET_VIC_DETAIL } from '../../../../core/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type SortOrder = 'asc' | 'desc';

function Victims() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { countryDetail } = useSelector(ransomeSelector);
  const { victims, isLoadingVictims } = useSelector(ransomeSelector);
  const [search, setsearch] = useState('');
  const [sortBy, setSortBy] = useState<string>('discovered');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

const results = victims?.data?.filter( values => 
      search !== '' ? 
      (values.post_title.includes(search) || values.post_title.includes(search))
      : values
     )



    const sortedVictims = countryDetail?.victims?.sort((a, b) => {
      let comparison = 0
      if (sortBy === 'country') {
        comparison = a.country.localeCompare(b.country)
      } else if (sortBy === 'post_title') {
        comparison = a.post_title.localeCompare(b.post_title)
      } else if (sortBy === 'discovered') {
        comparison = a.discovered.localeCompare(b.discovered)
      } else if (sortBy === 'group_name') {
          comparison = a.group_name.localeCompare(b.group_name)
      } else if (sortBy === 'published') {
        comparison = a.published.localeCompare(b.published)
      }

      return sortOrder === 'asc' ? comparison : -comparison
    })

  // console.log(isLoadingVictims);

  return (
    <>
      {countryDetail && countryDetail?.victims?.length !== 0 ? (
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHead}>
              <th
                style={{ paddingLeft: 16, cursor: 'pointer' }}
                onClick={() => handleSort('post_title')}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <span>Victims</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'post_title' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    width={11}
                    height={12}
                  />
                </div>
              </th>
              <th
                style={{ paddingLeft: 8, cursor: 'pointer' }}
                onClick={() => handleSort('group_name')}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 180 }}>
                  <span>Groups</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'group_name' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    width={11}
                    height={12}
                  />
                </div>
              </th>
              <th
                style={{ paddingLeft: 8, cursor: 'pointer' }}
                onClick={() => handleSort('discovered')}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 180 }}>
                  <span>Discovery Date</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'discovered' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    width={11}
                    height={12}
                  />
                </div>
              </th>
              <th
                style={{ paddingLeft: 8, cursor: 'pointer' }}
                onClick={() => handleSort('published')}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 150 }}>
                  <span>Attack Date</span>
                  <FontAwesomeIcon
                    icon={sortBy === 'published' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                    width={11}
                    height={12}
                  />
                </div>
              </th>
            </tr>
          </thead>

          {sortedVictims?.map((item:any) => (
            <tbody key={item.id}>
              <tr className={styles.tableActivityRow } style={{cursor:"pointer"}} onClick={() => {
                            dispatch({ type: SET_VIC_DETAIL, payload: item });
                           navigate("/ransomwareIntel/VictimsDetail");
                        }}>
                <td style={{ padding: '16px' }} className={styles.tableCell}>
                  {item?.post_title}
                </td>
                <td style={{ width: '240px' }} className={styles.tableCell}>
                  {item?.group_name}
                </td>
                <td style={{ width: '180px' }} className={styles.tableCell}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'start' }}>
                    <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf" />
                    {' ' + moment(item?.discovered).format('MMM DD, YYYY')}
                  </div>
                </td>
                <td style={{ paddingLeft: 8, width: '150px' }} className={styles.tableCell}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'start' }}>
                    <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf" />
                    {' ' + moment(item?.published).format('MMM DD, YYYY')}
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <div className={styles.loaderVul}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default Victims;