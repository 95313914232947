import { faComment, faFolder, faHouse, faListCheck, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import iconMenu1 from "../../icons/Main/Menu-1.svg"
import iconMenu2 from "../../icons/Main/Menu-2.svg"
import iconMenu3 from "../../icons/Main/Menu-3.svg"
import iconMenu4 from "../../icons/Main/Menu-4.svg"
import iconMenu5 from "../../icons/Main/Menu-5.svg"
import iconMenu6 from "../../icons/Main/Menu-6.svg"
import iconMenu7 from "../../icons/Main/Menu-7.svg"
import iconMenu8 from "../../icons/Main/Menu-8.svg"

export interface menuItem {
    id: number;
    title: string;
    icon: any;
    path: string;
}


export const menuItems: menuItem[] = [
    {
        id: 1,
        title: "Home",
        icon: <img src={iconMenu1} width={18}/>,
        path: '/'
    },
    // {
    //     id: 2,
    //     title: "Threat Management",
    //     icon: <img src={iconMenu2} width={18}/>,
    //     path: '/threatManagement'
    // },
    {
        id: 3,
        title: "Data Leaks",
        icon: <img src={iconMenu3} width={18}/>,
        path: '/threatIntel'
    },
    // {
    //     id: 4,
    //     title: "Brand Monitoring",
    //     icon: <img src={iconMenu4} width={18}/>,
    //     path: '/brandIntel'
    // },
    {
        id: 5,
        title: "Vulnerabilities",
        icon: <img src={iconMenu5} width={18}/>,
        path: '/vulnerabilities'
    },
    {
        id: 6,
        title: "Ransomware",
        icon: <img src={iconMenu6} width={18}/>,
        path: '/ransomwareIntel'
    },
    // {
    //     id: 7,
    //     title: "Compilance",
    //     icon: <img src={iconMenu7} width={18}/>,
    //     path: '/compilance'
    // },
    // {
    //     id: 8,
    //     title: "Reports",
    //     icon: <img src={iconMenu8} width={18}/>,
    //     path: '/reports'
    // },
]