import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import Menu from '../common/Menu'
import Header from '../common/Header'
import { Button, colors } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import moment from 'moment';
import ImpactSection from '../VulSections/ImpactSection'
import AffectProduct from '../VulSections/AffectProduct'
import Reference from '../VulSections/Reference'
import Config from '../VulSections/Config'
import Weakness from '../VulSections/Weakness'
import ChangeHistory from '../VulSections/ChangeHistory'
import ReactGA from 'react-ga4';
import { getVulnerabilitieByCVEAPI } from '../../../core/api/vulnerabilityApi'
import { CVEItem } from '../../../core/constants'
import { useDispatch } from 'react-redux'
import { setVulnerabilitiesByCVESearchAction } from '../../../core/actions/vulnerabilitiesActions'
import { setVulStepPageAction } from '../../../core/actions/stepsNavPageActions'
import { CircleFlag } from 'react-circle-flags'


function VictimsDetail() {
    type RansomeWareState = ReturnType<typeof ransomeSelector>;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeCompo, setactiveCompo] = useState('information')
    const { victimDetail } = useSelector(ransomeSelector)
    const [data, setdata] = useState<CVEItem | null>()
    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = window.localStorage.getItem("fullName")
    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };


    useEffect(() => {
        document.title = "Victim Details"
        ReactGA.send({ hitType: "Victim Details", page: window.location.pathname });
    }, [])




    return (
        <div className={styles.main}>
            <Menu />
            <Header />

            <div className={styles.content}>


                <div className={styles.personalInfoHeader}>
                    <div onClick={() => {
                        setTimeout(() => {
                            setVulStepPageAction({ vulStepName: 'list' })
                        }, 100)
                        navigate("/ransomwareIntel")
                    }} style={{ cursor: "pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf' }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span>Go Back</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 16 }} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <CircleFlag countryCode={victimDetail?.country.toLowerCase() ?? 'default'} height="24" />
                        <span style={{ fontSize: 20, fontWeight: 600 }}>{victimDetail?.post_title}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24 }}>
                        <div style={{ display: "flex", alignItems: 'center', gap: 5 }}>
                            <span style={{ color: "#afafaf" }}>Published on</span>
                            <span style={{ color: "white" }}>{moment(victimDetail?.published).format("MMM DD, YYYY")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: 'center', gap: 5 }}>
                            <span style={{ color: "#afafaf" }}>By</span>
                            <span style={{ color: "white" }}>{victimDetail?.group_name ? victimDetail.group_name.charAt(0).toUpperCase() + victimDetail.group_name.slice(1) : ""}</span>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#141414", width: 'fit-content', marginTop: 32, justifyContent: "center", borderRadius: 6, padding: '4px 6px' }} className={styles.firstSectionMenu}>
                        <span style={{ cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500 }} className={activeCompo === 'information' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Insights</span>
                    </div>
                    {victimDetail &&
                        <table className={styles.table} style={{ marginTop: 25 }}>

                            <tbody>
                                <tr className={styles.tableActivityRowRansomWatchList}>
                                    <td style={{ padding: '16px', width: "150px" }} className={styles.tableCell}>
                                        Date Discovered
                                    </td>
                                    <td style={{ padding: '16px' }} className={styles.tableCell}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' /> <span>{moment(victimDetail?.discovered).format("MMMM D, YYYY")}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={styles.tableActivityRowRansomWatchList}>
                                    <td style={{ padding: '16px' }} className={styles.tableCell}>
                                        Original Post
                                    </td>
                                    <td onClick={() => { const postUrl = victimDetail?.post_url || ""; const validUrl = postUrl.startsWith("http") ? postUrl : postUrl.includes(".onion") ? `http://${postUrl}` : `https://${postUrl}`; openlinkDetail(validUrl) }} style={{ cursor: victimDetail?.post_url ? "pointer" : "default", padding: '16px', color: victimDetail?.post_url ? "#e9bd08" : "inherit", textDecorationLine: victimDetail?.post_url ? 'underline' : 'none' }} className={styles.tableCell} >
                                        {victimDetail?.post_url || "-"}
                                    </td>
                                </tr>
                                <tr className={styles.tableActivityRowRansomWatchList}>
                                    <td style={{ padding: '16px' }} className={styles.tableCell}>
                                        Victim Website
                                    </td>
                                    <td onClick={() => { const website = victimDetail?.website || ""; if (website) { const validUrl = website.startsWith("http") ? website : `https://${website}`; openlinkDetail(validUrl); }}} style={{ cursor: victimDetail?.website ? "pointer" : "default", padding: '16px', color: victimDetail?.website ? "#e9bd08" : "inherit", textDecorationLine: victimDetail?.website ? 'underline' : 'none' }} className={styles.tableCell} >
                                        {victimDetail?.website || "-"}
                                    </td>
                                </tr>

                                <tr style={{ verticalAlign: 'top' }} className={styles.tableActivityRowRansomWatchList}>
                                    <td style={{ padding: '16px' }} className={styles.tableCell}>
                                        Description
                                    </td>
                                    <td style={{ padding: '16px', lineHeight: 1.5 }} className={styles.tableCell}>
                                        {
                                            victimDetail?.description
                                        }
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    }
                </div>
            </div>
        </div>
    )
}

export default VictimsDetail