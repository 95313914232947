import { Reducer } from "react";
import { Countries, CountryDetail, FAILED_NEGOCIATIONS, SET_VIC_DETAIL, FAILED_RANSOME, FAILED_RANSOME_COUNTRIES, FAILED_RANSOME_COUNTRY_DETAIL, FAILED_RANSOME_OVERVIEW, FAILED_VICTIMS, GroupsRansome, MessageData, Negociations, RansomeOverview, RansomeWareAction, REQUEST_NEGOCIATIONS, REQUEST_RANSOME, REQUEST_RANSOME_COUNTRIES, REQUEST_RANSOME_COUNTRY_DETAIL, REQUEST_RANSOME_OVERVIEW, REQUEST_VICTIMS, SET_COUNTRY_CODE, SET_GROUP_DETAIL, SET_LAST_UPDATED_COUNTRY, SET_MESSAGES, SUCCESS_NEGOCIATIONS, SUCCESS_RANSOME, SUCCESS_RANSOME_COUNTRIES, SUCCESS_RANSOME_COUNTRY_DETAIL, SUCCESS_RANSOME_OVERVIEW, SUCCESS_VICTIMS, Victims, GroupsRansomeDetail, Victim } from "../constants";

export interface RansomeWareState {
    groups: GroupsRansome | null
    groupDetail: GroupsRansomeDetail | null
    isLoading: boolean
    victims: Victims | null
    negociations: Negociations[] | null
    chatMessages: Negociations | null
    overview: RansomeOverview | null;
    isLoadingOveriview: boolean;
    countryDetail: CountryDetail | null;
    isLoadingCountryDetail: boolean;
    code: string | null;
    last_publish_date: string | null;
    countries: Countries[] | null;
    isLoadingCountries: boolean;
    isLoadingVictims: boolean;
    isLoadingGroups: boolean;
    isLoadingNegociations: boolean;
    victimDetail: Victim | null;
}

const initialState: RansomeWareState = {
    groups: null,
    groupDetail: null,
    isLoading: false,
    victims: null,
    negociations: null,
    chatMessages: null,
    overview: null,
    countryDetail: null,
    isLoadingOveriview: false,
    isLoadingCountryDetail: false,
    code: null,
    last_publish_date: null,
    countries: null,
    isLoadingCountries: false,
    isLoadingVictims: false,
    isLoadingGroups: false,
    isLoadingNegociations: false,
    victimDetail: null

}

export const RansomeReducer: Reducer<RansomeWareState, RansomeWareAction> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case REQUEST_RANSOME:
            return {
                ...state,
                isLoadingGroups: true
            }
        case SUCCESS_RANSOME:
            return {
                ...state,
                isLoadingGroups: false,
                groups: actions.payload as GroupsRansome
            }
        case SET_GROUP_DETAIL:
            return {
                ... state,
                groupDetail: actions.payload as GroupsRansomeDetail
            }
        case REQUEST_VICTIMS:
            return {
                ...state,
                isLoadingVictims: true
            }
        case SUCCESS_VICTIMS:
                return {
                    ...state,
                    isLoadingVictims: false,
                    victims: actions.payload as Victims
                }
        case FAILED_VICTIMS:
                    return {
                        ...state,
                        isLoadingVictims: false,
                       
                    }
        case SET_MESSAGES:
            return {
                ...state,
                chatMessages: actions.payload as Negociations
            }
        case REQUEST_RANSOME_OVERVIEW: 
            return {
                ... state,
                isLoadingOveriview: true
            }
        case SUCCESS_RANSOME_OVERVIEW: 
            return {
                ...state,
                isLoadingOveriview: false,
                overview: actions.payload as RansomeOverview
            }
        case FAILED_RANSOME_OVERVIEW:
            return {
                ...state,
                isLoadingOveriview: false
            }
        case REQUEST_RANSOME_COUNTRY_DETAIL:
            return {
                ... state,
                isLoadingCountryDetail: true
            }
        case SUCCESS_RANSOME_COUNTRY_DETAIL:
            return {
                ... state,
                isLoadingCountryDetail: false,
                countryDetail: actions.payload as CountryDetail
            }
        case FAILED_RANSOME_COUNTRY_DETAIL:
            return {
                ... state,
                isLoadingCountryDetail: false
            }
        case SET_COUNTRY_CODE:
            return {
                ... state,
                code: actions.payload as string
            }
        case SET_LAST_UPDATED_COUNTRY:
            return {
                ...state,
                last_publish_date: actions.payload as string
            }
            case REQUEST_RANSOME_COUNTRIES:
                return {
                    ... state,
                    isLoadingCountries: true
                }
            case SUCCESS_RANSOME_COUNTRIES:
                return {
                    ... state,
                    isLoadingCountries: false,
                    countries: actions.payload as Countries[]
                }
            case FAILED_RANSOME_COUNTRIES:
                return {
                    ... state,
                    isLoadingCountries: false
                }
            case REQUEST_NEGOCIATIONS:
                return {
                    ... state,
                    isLoadingNegociations: true
                }
            case SUCCESS_NEGOCIATIONS:
                return {
                    ... state,
                    isLoadingNegociations: false,
                    negociations: actions.payload as Negociations[]
                }
            case FAILED_NEGOCIATIONS:
                return {
                    ... state,
                    isLoadingNegociations: false
                }
            case SET_VIC_DETAIL:
                return {
                    ... state,
                    victimDetail: actions.payload as Victim
                }
        default:
            return state;
    }
}