import React, { useState, useEffect } from 'react'
import { Button, TextField } from '@mui/material'
import styles from "./auth.module.scss"
import BackBtn from './components/BackBtn'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resePasswordRequest } from '../../core/actions/authActions'
import { useSelector } from 'react-redux'
import { authSelector } from '../../core/reducers/authReducer'
import ErrorNotification from '../Account/common/ErrorNotification'
import ReactGA from 'react-ga4';
import logoSignSVG from '../../icons/Login-Illustration.svg'
import { useIntercom } from '../Dashboard/IntercomSetup'
import Logo from '../../icons/Defendis_Logo_White.svg'

function ForgotPassword() {
    const navigate = useNavigate();
    const previous = () => {
        navigate(-1)
    }

    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
    const profilePicture = window.localStorage.getItem("avatar")
  
    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed,
      avatar: profilePicture,
    })
    

    const dispatch = useDispatch()
    const auth = useSelector(authSelector);
    const [email, setemail] = useState('');
    const [OpenNotification, setOpenNotification] = useState(false)
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        document.title = "Recover Password"
        if (auth.resetMailIsSent) {
            setOpenNotification(true)

        }
    }, [auth])


    const sendResetEmail = () => {
        if (email !== '') {
            dispatch(resePasswordRequest({ email }))
        }
    }

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    return (
        <div style={{ backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
                <img src={Logo} className={styles.logo} alt="Defendis Logo" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh' }}>
                <div className={styles.login} style={{ width: 'auto' }}>
                    <div style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className={styles.loginInputs}>
                                <span className={styles.titleLogin}>Recover password</span>
                                <TextField InputProps={{ sx: { fontSize: 14 } }}
                                    onChange={(e) => setemail(e.target.value)}
                                    id="email"
                                    label="Work Email"
                                    variant="standard"
                                    fullWidth
                                    margin="normal"
                                />
                                <div style={{ display: 'grid', width: '100%', gap: 0 }}>
                                    <Button
                                        onClick={sendResetEmail}
                                        type="submit"
                                        style={{ marginTop: 48, fontSize: 14, fontWeight: 500 }}
                                    >
                                        Recover Account
                                    </Button>
                                    <Button
                                        onClick={previous}
                                        type="submit"
                                        style={{ marginTop: 12, backgroundColor: '#1F1F1F', color: 'white', fontSize: 14, fontWeight: 500, width: '100%' }}
                                    >
                                        Go Back
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div style={{ paddingTop: '2rem' }}>
                        <img src={logoSignSVG} alt="Illustration" />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center' }}>
                <span className={styles.loginFooterText}>© 2024 Defendis Technology Inc.</span>
                <a href="https://defendis.ai/terms" className={styles.loginFooterText} target="_blank">
                    <span>Terms</span>
                </a>
                <a href="https://defendis.ai/privacy" className={styles.loginFooterText} target="_blank">
                    <span>Privacy Policy</span>
                </a>
                <a href="https://help.defendis.ai" className={styles.loginFooterText} target="_blank">
                    <span>Support</span>
                </a>
            </div>
            {
                auth.resetMailIsSent && <ErrorNotification
                    severity='success'
                    open={OpenNotification}
                    message={auth.notification}
                    handleClose={handleCloseNotification}
                />
            }
        </div>
    )
}

export default ForgotPassword
