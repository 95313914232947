import React, { useEffect, useState } from 'react'
import styles from './account.module.scss'
import { Switch } from '@mui/material'
import Header from '../Dashboard/common/Header'
import Menu from './common/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga4'


function Support() {
  
  const [emailStorage, setemailStorage] = useState<string | undefined>()
  const [hmacStorage, sethmacStorage] = useState<string | undefined>()
  const [fullName, setfullName] = useState<string | undefined>()
  const emailStorageVa = window.localStorage.getItem('email')
              const hmacValue = window.localStorage.getItem('hmac')
              const fullNameD = window.localStorage.getItem("fullName")

const openIntercom = () => {
  if (window.Intercom) {
    window.Intercom('show')
  } else {
    console.warn('Intercom is not loaded');
  }
};

  useEffect(() => {
    document.title = "Settings - Support"
    ReactGA.send({ hitType: "Settings - Support", page: window.location.pathname });

  }, [])

  const openlinkDetail = (link: string) => {
    window.open(link, '_blank');
};
  
  return (
    <div className={styles.main}>
        <Menu/>
        <Header/>
        <div className={styles.content}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>Get Support</p>
        </div>
        <div  className={styles.avatarPersonalAccountCard}>
        <p className={styles.avatarPersonalAccountCard_title}>Ressources</p>
                      <div onClick={()=>openlinkDetail(" https://status.defendis.ai")} className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p className={styles.avatarPersonalAccountCard_text}>Service Status</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>    
                      

                      <div onClick={openIntercom} className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p  className={styles.avatarPersonalAccountCard_text}>Talk to support</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>

                      <div onClick={()=>openlinkDetail("https://www.defendis.ai/changelog")} className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p  className={styles.avatarPersonalAccountCard_text}>Changelog</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>
                      
        </div>  
        <div  className={styles.avatarPersonalAccountCard}>
        <p className={styles.avatarPersonalAccountCard_title}>About</p>
                          
                      

                      <div onClick={()=>openlinkDetail(' https://help.defendis.ai/')} id="${styles.supportLinks}"className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p  className={styles.avatarPersonalAccountCard_text}>Help Center</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>

                      <div onClick={()=>openlinkDetail('https://Defendis.ai/terms')} id="${styles.supportLinks}"className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p  className={styles.avatarPersonalAccountCard_text}>Terms of Use</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>

                      <div onClick={()=>openlinkDetail('https://defendis.ai/privacy')}  id="${styles.supportLinks}"className={`${styles.personalInfosInputs} ${styles.supportLinks}`}>
                        <p  className={styles.avatarPersonalAccountCard_text}>Privacy Policy</p>
                        <FontAwesomeIcon  icon={faArrowUpRightFromSquare} color='#afafaf' cursor="pointer"/>
                      </div>
                      
                      
        </div> 
        
        </div>
    </div>
  )
}

export default Support