import axios from "axios";
import { CredentialsLeak, CreditCardsThreatIntel, DocumentsLeakIntel, InsideThreatsIntel } from "../constants";
import { env } from "../../environement/env";

export interface PageSort {
    page: number,
    sortBy: string | null,
    search?: string
}

export const getCredentialsLeakApi = async ({page, sortBy}:PageSort) => {
        const { data } = await axios.get(`${env.apiUrl}/threatIntel/credentialsLeak?page=${page}&sortBy=${sortBy}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        localStorage.setItem("currentPage", JSON.stringify(page))
        console.log(data)
        return data as CredentialsLeak;
   
}

export const getCredentialLeakExcelApi = async () => {
    const { data } = await axios.get(`${env.apiUrl}/threatIntel/credentialsLeak/credentialsExcel?sortBy=all`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    
    return data;

}

export const getCredentialsLeakUserProfileApi = async (userid: number) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/threatIntel/credentialsLeak/userProfile`,{ userid }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        console.log(data)
        return data;
    } catch (error) {
        
    }
}

export const getCreditsCardAi = async ({page, sortBy}:PageSort) => {
        const { data } = await axios.get(`${env.apiUrl}/threatIntel/creditsCard?page=${page}&sortBy=${sortBy}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as CreditCardsThreatIntel;
    
}

export const getCreditsCardExcelAi = async () => {
    const { data } = await axios.get(`${env.apiUrl}/threatIntel/creditsCard/creditsCardExcel`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    return data;

}

export async function getEmailApi(userid:number) {
    try {
        const { data } = await axios.post(`${env.apiUrl}/threatIntel/creditsCard/getEmail`,
        { userid },
        {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as string;
    } catch (error) {
        
    }
}

export const getInsideThreatsApi = async () => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/threatIntel/insideThreats`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as InsideThreatsIntel[];
    } catch (error) {
        
    }
}

export const getDocumentsLeakApi = async ({page, sortBy}:PageSort) => {
        const { data } = await axios.get(`${env.apiUrl}/threatIntel/documentsLeak?page=${page}&sortBy=${sortBy}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as DocumentsLeakIntel;
    
}

export const getDocumentsLeakExcelApi = async () => {
    const { data } = await axios.get(`${env.apiUrl}/threatIntel/documentsLeak/getDocumentsLeakExcel`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    return data;

}

export const getTotalRecordsCredentialsApi = async() => {
    const { data } = await axios.get(`${env.apiUrl}/threatIntel/credentialsLeak/totalCredentialsLeak`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })

    console.log(data)
    
    return data?.totalRecords;
}


export const getCountLeakedUsername = async (username: string) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/threatIntel/credentialsLeak/countLeaked`,
            {username}
            , {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data
    } catch (error) {
        let errorMessage = "An unknown error occurred.";
        window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
    }
}

export const getCountCardNumber = async(cardnumber: string) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/threatIntel/creditsCard/getCountCardNumber`, {
            cardnumber
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data?.count
    } catch (error) {
        let errorMessage = "An unknown error occurred.";
        window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
    }
}