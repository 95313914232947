import React, { useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { GET_WATCH_LIST, GET_WATCH_LIST_EXECUTIVE, WatchListExecutive } from '../../../core/constants'
import EmptyStateWatchList from '../../Dashboard/common/EmptyStateWatchList'
import ErrorNotification from '../common/ErrorNotification'
import { DeleteWatchListExecutiveApi } from '../../../core/api/watchListApi'
import { CircularProgress } from '@mui/material'
import { supabaseClient } from '../../../core/supabase'

interface props {
  open: boolean;
  setOpen: any;
}

function ExecutiveWatchList({ open, setOpen }: props) {
  const dispatch = useDispatch()
  const { watchListExecutive, isLoadingExecutive } = useSelector(watchListSelector)
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [notifMessage, setnotifMessage] = useState<string>("")
  const [openNotif, setOpenNotif] = useState(false)

  const handleCheckboxChange = (id: any) => {
    setSelectedAssets((prev: any) => {
      if (prev.includes(id)) {
        return prev.filter((item: any) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };


  const deleteExecutive = async () => {
    await supabaseClient.from("watchlistExecutive").delete().in('id', selectedAssets)
    // setnotifMessage(message)
    // setOpenNotif(true)
    setTimeout(() => {
      dispatch({ type: GET_WATCH_LIST_EXECUTIVE })
      dispatch({ type: GET_WATCH_LIST })
    }, 500);
  }
  useEffect(() => {
    document.title = "Executive Watch list"
    dispatch({ type: GET_WATCH_LIST_EXECUTIVE })
  }, [])



  return (
    <>
      {
        !isLoadingExecutive ?
          watchListExecutive && watchListExecutive?.length > 0 ?
            <div className={styles.awaitingApproval}>

              <div className={styles.searchAndRemove}>
                <div className={styles.searchAndIcon}>
                  <input className={styles.search} name='search' placeholder='Search' />
                  <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x' />
                </div>
                <div className={styles.removeSelected} onClick={deleteExecutive}>
                  <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                  <FontAwesomeIcon icon={faTrash} color='white' style={{ fontSize: 12 }} />
                </div>
              </div>
              <table className={styles.table} style={{ marginTop: 25 }}>
                <thead>
                  <tr className={styles.tableHead}>
                    <th style={{ paddingLeft: 8 }}> </th>
                    <th style={{ paddingLeft: 8 }}>Email Address</th>
                    <th style={{ paddingLeft: 8 }}>Full Name</th>
                    <th style={{ paddingLeft: 8 }}>Username</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    watchListExecutive?.map((data: WatchListExecutive) =>
                      <tr className={styles.tableActivityRowUsers}>
                        <td style={{ width: '32px', padding: '13px'}} className={styles.tableCell}>
                          <input
                            type="checkbox"
                            className={styles.customCheckbox}
                            value={data.id}
                            onChange={() => handleCheckboxChange(data.id)}
                            checked={selectedAssets.includes(data?.id)}
                          />
                        </td>
                        <td className={styles.tableCell}>{data.email}</td>
                        <td style={{ width: '220px' }} className={styles.tableCell}>{data.fullName}</td>
                        <td style={{ width: '150px' }} className={styles.tableCell}>{data.username}</td>
                      </tr>
                    )
                  }

                </tbody>
              </table>
              <ErrorNotification
                severity="success"
                open={openNotif}
                message={notifMessage}
                handleClose={() => setOpenNotif(false)}
              />
            </div>
            :
            <div className={styles.loaderVul}>
              <EmptyStateWatchList
                open={open}
                setOpen={setOpen}
                title='Executives Watch list is empty'
                addButtonText='Executive'
                text='Add an executive to start monitoring for cybersecurity risks'
              />
            </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }
    </>
  )
}

export default ExecutiveWatchList