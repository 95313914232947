import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDownload, faSearch, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import { useDispatch } from 'react-redux'
import { GroupsRansome, GroupsRansomeDetail, REQUEST_RANSOME, SET_GROUP_DETAIL } from '../../../core/constants'
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { CircularProgress, Alert, Snackbar, Pagination } from '@mui/material'
import moment from 'moment'
import EmptyStateGroups from '../common/EmptyStateGroups'
import { exportToExcel } from '../components/ExportToExcel'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'
import { PageSort } from '../../../core/api/threatIntelApi'
import { getGroupsExcelAPI } from '../../../core/api/ransomeApi'
import dateFormats from '../dateFormats'

type SortOrder = 'asc' | 'desc'
function GroupsSection() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { groups, isLoadingGroups } = useSelector(ransomeSelector)
  const [search, setsearch] = useState("")
  const [isDonwloaded, setisDonwloaded] = useState(false)
  const [sortBy, setSortBy] = useState<string>('lastscrape')
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc')
  const { notifs, notifsUser } = useSelector(notificationSelector)

  const [page, setPage] = useState(1)
  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      sortBy: sortOrder,
      search: search
    }
    setPage(value)
    dispatch({
      type: REQUEST_RANSOME,
      payload: dataCred 
    })
  };

  const resultsSearch =  groups?.data


  useEffect(() => {

    document.title = "Ransomware Groups"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    const dataCred: PageSort = {
      page: 1,
      search:'',
      sortBy: null,
    };
    
    
    if(!groups) {
      dispatch({
        type: REQUEST_RANSOME,
        payload: dataCred 
      })
    }
  }, [])

  const renderDate = (last_seen: string) => {
    try {
      const parsedDate = moment(last_seen, dateFormats, true); // 'true' for strict parsing
      if (parsedDate.isValid()) {
        return parsedDate.format("MMMM DD, YYYY");
      } else {
        return "Invalid date";
      }
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date";
    }
  };

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
      if(column.includes("lastscrape")) {
        const dataCred: PageSort = {
          page: page,
          search:search,
          sortBy: sortOrder === 'asc' ? 'desc' : 'asc',
        };
        dispatch({
          type: REQUEST_RANSOME,
          payload: dataCred 
        })
      }
    } else {
      setSortBy(column)
      setSortOrder('asc')
      if(column.includes("lastscrape")) {
        const dataCred: PageSort = {
          page: page,
          search:search,
          sortBy: "asc",
        };
        dispatch({
          type: REQUEST_RANSOME,
          payload: dataCred 
        })
      }
    }
  }
  const grpsNotif = notifs.filter(item => item.isDarkWeb === true && notifsUser.some((not: any) => not.notifId === item.id && not.read === false))

  const uniqueGroups = Array.from(
    new Map((resultsSearch ?? []).map(item => [item.name, item])).values()
  );


  const sortedGroups = uniqueGroups.sort((a, b) => {
    const aInGrpNotif = grpsNotif.some((notif) => notif?.data?.id === a.id);
    const bInGrpNotif = grpsNotif.some((notif) => notif?.data?.id === b.id);

    // Sort elements in vulNotif to the top
    if (aInGrpNotif && !bInGrpNotif) return -1;
    if (!aInGrpNotif && bInGrpNotif) return 1;

    let comparison = 0
    if (sortBy === 'name') {
      comparison = a.name.localeCompare(b.name)
    }  else if (sortBy === 'enabled') {
      comparison = (a.enabled === b.enabled) ? 0 : a.enabled ? 1 : -1;
    }


    return sortOrder === 'asc' ? comparison : -comparison
  })



  const setGroupDetail = (item: any) => {
    dispatch({
      type: SET_GROUP_DETAIL,
      payload: item
    });

    navigate("/ransomwareIntel/groupDetail")
  }
  

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = async () => {
    const resultGroupsExcel = await getGroupsExcelAPI()
    const dataXlsx = resultGroupsExcel?.map((item: GroupsRansomeDetail) => ({
      'Group': item?.name,
      'Link': encodeURI(String(item?.slug || 'Link Unavailable')),
      'Last seen': moment(item?.lastscrape).format("MMM DD, YYYY"),
      'Status': item?.enabled ? "Yes" : "No",
  
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Ransomware_groups-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };


  const handleSearch = () => {
    const dataCred: PageSort = {
      page: 1,
      search:search,
      sortBy: null,
    };
    
    
    
      dispatch({
        type: REQUEST_RANSOME,
        payload: dataCred 
      })
  }
  

  return (
    <div className={styles.awaitingApproval} style={{ marginTop: 18 }}>

      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }} >
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' placeholder='Search' onChange={(e) => setsearch(e.target.value)} />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x' onClick={handleSearch}/>
        </div>
        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 12 }} />
        </div>
      </div>
      {
        !isLoadingGroups ?

          sortedGroups && sortedGroups.length !== 0 ?
          <>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableHead}>
                  <th></th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('name')}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 180 }}>
                      <span>Groups</span>
                      <FontAwesomeIcon icon={sortBy === 'name' && sortOrder === 'asc' ? faChevronUp : faChevronDown} size="1x" height={12} width={11} />
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('enabled')}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 20 }}>
                      <span>Active</span>
                      <FontAwesomeIcon icon={sortBy === 'enabled' && sortOrder === 'asc' ? faChevronUp : faChevronDown} size="1x" height={12} width={11} />
                    </div>
                  </th>
                  <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('lastscrape')}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5, width: 180 }}>
                      <span>Latest Activity</span>
                      <FontAwesomeIcon icon={sortBy === 'lastscrape' && sortOrder === 'asc' ? faChevronUp : faChevronDown} size="1x" height={12} width={11} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  sortedGroups?.map(item =>
                    <tr className={styles.tableActivityRowUsers} style={{ cursor: "pointer" }} onClick={() => setGroupDetail(item)}>
                      <td className={styles.tableCell} style={{ width: 6 }}>
                        {
                          grpsNotif.some((respItem: any) => {

                            return respItem?.data?.id === item.id;
                          }) ? (
                            <div className={styles.redPoint} />
                          ) : (
                            null // Nothing is displayed if the condition is not met
                          )
                        }
                      </td>
                      <td style={{ padding: '16px' }} className={styles.tableCell}>
                        {item?.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : ''}
                      </td>
                      <td className={styles.tableCell} >
                        {
                          item?.enabled ?
                            <FontAwesomeIcon icon={faCircleCheck} width={23} height={24} color="#20B444" />
                            :
                            <div style={{ marginLeft: 5, height: 8, width: 8, borderRadius: "100%", border: "3px solid #383838", backgroundColor: "transparent" }} />
                        }
                      </td>
                      <td style={{ width: '150px' }} className={styles.tableCell} >
                        {item?.lastscrape ?
                          <div style={{ display: 'flex', alignItems: "center", gap: 5 }}>
                            <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                            <span>{moment(item.lastscrape).format("MMM DD, YYYY")}</span>
                          </div>
                          : <div>
                            <p>-</p>
                          </div>
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <Pagination
            showFirstButton
            showLastButton
            shape="rounded"
            page={Number(groups?.pagination?.currentPage)}
            count={groups?.pagination?.totalPages}
            onChange={handleChangePage}
            sx={{
              '& .MuiPagination-ul': {
                justifyContent: "center",
                display: 'flex'
              },
              '& .Mui-selected': {
                color: '#ffffff', // Text color for active page
                backgroundColor: '#1f1f1f', // Background color for active page
                opacity: 0.3
              },
              '& .MuiPaginationItem-root': {
                color: '#ffffff', // Text color for inactive pages
              },
            }}
          /></>
            :
            <div className={styles.loaderVul}>
              <EmptyStateGroups />
            </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }
      <Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default GroupsSection
