import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import Content from './Content'
import styles from "./dashboard.module.scss"
import MenueContent from './common/MenueContent'
import {  MenuItem, Select, SelectChangeEvent } from '@mui/material'
import CriticalRiskBar from './components/CriticalRiskBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ChartHome from './components/ChartHome'
import Card from './components/Card';
import OverviewComponent from './components/OverviewComponent'
import ListVulnarabilities from './components/ListVulnarabilities'
import { useDispatch } from 'react-redux'
import { GET_ALL_VULNERABILITIES, GET_ALL_VULNERABILITIES_REQUEST, REQUEST_VULNERABILITIES_OVERVIEW, VulnerabilityList } from '../../core/constants'
import { getAllVulnerabilitiesPerPageAction } from '../../core/actions/vulnerabilitiesActions'
import { useSelector } from 'react-redux'
import { stepsNavPageSelector } from '../../core/selectors/stepsNavPageSelector'
import { setNamePageLocalStorage, setVulStepPageAction } from '../../core/actions/stepsNavPageActions'
import { getAllVulnerabilitiesAPI } from '../../core/api/vulnerabilityApi'

const nameSteps = ['overview', 'list']

function VulnerabilitiesView() {
  const [recent, setRecent] = useState('all')
  
  const storedStepName = localStorage.getItem('step') || 'overview';
  const [vulStepName, setVulStepName] = useState(storedStepName);
    const dispatch = useDispatch()
  const handleChange = (event: SelectChangeEvent) => {
    dispatch({
      type: REQUEST_VULNERABILITIES_OVERVIEW,
      payload: event.target.value as string
    })
         setRecent(event.target.value as string);
      }

      const renderOverviewComponent = () => {
        return <OverviewComponent/>
      }

      const renderVulnerabilitiesComponent = () => {
        return <ListVulnarabilities/>
      }


const handleChangeStep = (newStepName: string) => {
  setNamePageLocalStorage(newStepName)
  setVulStepName(newStepName);
};

useEffect(() => {
  if(nameSteps.includes(storedStepName))
    setVulStepName(storedStepName);
  else
    setVulStepName("overview")
}, [storedStepName]);
      
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
            <div className={styles.menuContent}>
            <div className={styles.firstSectionMenu}>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('overview')} className={vulStepName === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</p>
                <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('list')}  className={vulStepName === 'list' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Vulnerabilities</p>
            </div>
            { /*
              <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleChange} value={recent} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />} style={{ backgroundColor:"transparent", fontSize: 14, fontWeight: 500}}
              MenuProps={{
              PaperProps: {
                  sx: {
                  backgroundColor: '#1f1f1f',
                  borderRadius: '8px',
                  padding: '0px',
                  boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                  maxHeight: '360px',
                  overflowY: 'auto',
                  },
              },
              MenuListProps: {
                  dense: false,
                  disablePadding: true,
              },
              anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
              },
              transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
              },
      }} >
                  <MenuItem value={'all'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Full Range</MenuItem>
                  <MenuItem value={'last7days'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Last 7 days</MenuItem>
                  <MenuItem value={'lastMonths'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Last month</MenuItem>
                  <MenuItem value={'last3Months'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Last 3 months</MenuItem>
          </Select> */
            }
        </div>
              <hr className={styles.divider}/>
              {
                vulStepName === "overview"
                 ?
                  renderOverviewComponent()
                :
                  renderVulnerabilitiesComponent()
              }
            </div>
        </div>
  )
}

export default VulnerabilitiesView