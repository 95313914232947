import { PageSort } from "./api/threatIntelApi";

export enum MessageResp {
    SHOULD_UPDTAE_PASSWORD = 'should_update_password',
    PASSWORD_UPDATED_SUCCESSFULLY = 'PASSWORD_UPDATED_SUCCESSFULLY',
    OPT_SENT_WITH_SUCCESS = 'otp_sent_with_success',
    PASSWOED_RESET_EMAIL_SENT = 'password_reset_email_sent',
    DATA_INFO_SECURITY_UPDAYED = 'data_info_security_updated',
    PASSWORD_ALREADY_USED = 'password_already_used',
    ENTREPRISE_DATA_UPDATED = 'entreprise_data_updated',
    INVITE_LINK = 'invite_link_send',
    USER_APPROUVED = 'user_approuved',
    WATCH_LIST_ASSEST_SAVE = 'watch_list_assest_saved',
    WATCH_LIST_BRAND_KEYWORD_SAVE = 'watch_list_brand_keyword_saved',
    WATCH_LIST_DOMAIN_SAVE = 'watch_list_domain_saved',
    WATCH_LIST_IP_SAVE = 'watch_list_ip_saved',
    WATCH_LIST_BIN_SAVE = 'watch_list_bin_saved',
    WATCH_LIST_EXECUTIVE_SAVE = 'watch_list_executive_saved',
    WATCH_LIST_RANSOME_SAVE = 'watch_list_ransome_saved'
}

export interface ValidationError {
    error: string;
    errorCode: any
}

export interface ErrorGlobal {
    global: string[];
}

export interface LoginReq {
    email: string;
    password: string;
}

export interface LoginRes {
    login: string;
    success: boolean;
    authentificated: boolean;
    firstName: string;
    lastName: string;
    approuved: boolean | number;
    loginEntreprise: number | null;
    hmacValue: string;
    profilePicture: string;
    dateToUpdateOtp: string | null;
    id: number | null;
    email: string;
    jwt: string | undefined
    typeEntreprise:string| undefined
}

export interface LoginFirstTimeRes {
    message: string;
}

export interface VerifyToken {
    authentificated: boolean;
}

export interface OtpSent{
    message: MessageResp;
    otpIsSent: boolean
}

export interface OtpSuccess{
    message: boolean;
}

export interface OtpRequest {
    email: string;
}

export interface ResetPasswordNotif {
    notification: string;
    resetMailIsSent: boolean;
}

export interface OtpResult {
    otpIsValid: boolean;
}

export interface isLoginFirstTime {
    isFirstTime: boolean;
}

export interface firstTimeNewPassword {
    email: string;
    password: string;
    jwt: string
}

export interface Otp {
    email: string;
    otp: string;
}

export enum Roles {
    public = "public",
    boAdmin = "boAdmin",
    boAnalyst = "Analyst",
    boResellerManager = "Reseller Manager",
    boResellerAnalyst = "Reseller Analyst",
    boClient = "Client",
    boAuditor = "Auditor",
    boAdminEntreprise = "Admin"
}

export interface RolesSupabase {
    id: bigint; // Primary key, not null
    created_at: string; // Timestamp with time zone, not null
    role: string | null; // Role name, nullable
    userSettings: boolean | null; // User settings permission, nullable
    companySettings: boolean | null; // Company settings permission, nullable
    assetSettings: boolean | null; // Asset settings permission, nullable
    addComment: boolean | null; // Permission to add comments, nullable
    removeComment: boolean | null; // Permission to remove comments, nullable
    updateComment: boolean | null; // Permission to update comments, nullable
    collaborate: boolean | null; // Collaborate permission, nullable
    updated_at: string | null; // Timestamp with time zone, nullable
  }
  


export interface AuthState {
    email: string;
    password: string;
    login: string;
    authenticated: boolean;
    success: boolean;
    isFirstTime: boolean;
    notification: string;
    otpIsSent: false;
    otpIsValid: boolean;
    role: Roles;
    resetMailIsSent: boolean;
    jwt: string;
    passwordIsUpdated: boolean;
    firstName: string;
    lastName: string;
    approuved: boolean | number;
    loginEntreprise: number | null;
    hmacValue: string;
    profilePicture: string;
    dateToUpdateOtp: string;
    error:string;
    errorCode:any | null;
    isLoadingLogin: false
}

export enum NotifMessages {
    INVALID_EMAIL_OR_PASSWORD = "Invalid Email or Password"
}

export interface NotifMessage {
    error: string;
    success: boolean;
    errorCode: any;
}

export interface RecoverAccountResultRequest {
    jwt: string;
    password: string;
}

export interface RecoverAccountResultSuccess {
    passwordIsUpdated: boolean;
    notification: string;
}

type TypeOfAuthAction = typeof LOGIN_REQUEST
    | typeof LOGIN_SUCCESS
    | typeof LOGIN_FAILED
    | typeof IS_FIRST_TIME_LOGIN
    | typeof SET_NOTIFICATION
    | typeof RESET_AUTH_STATE
    | typeof SET_PICTURE_PROFILE
    | typeof LOGOUT
    | typeof SET_JWT
    ;

type TypeOfSetNewPassword = 
    typeof SET_FIRST_TIME_NEW_PASSWORD_REQUEST 
    | typeof SET_FIRST_TIME_NEW_PASSWORD_SUCCESS
    | typeof RESET_PASSWORD_REQUEST
    | typeof RESET_PASSWORD_SUCCESS
    | typeof UPDATE_RECOVER_PASSWORD_REQUEST
    | typeof UPDATE_RECOVER_PASSWORD_SUCCESS
    | typeof RESET_NOTIFICATION
    ;

type TypeOfOtp = typeof OTP_REQUEST | typeof SEND_OTP | typeof VERIFY_OTP | typeof VERIFY_OTP_REQUEST;

type Actions = 
    TypeOfAuthAction
    | TypeOfSetNewPassword
    | TypeOfOtp;

type PayloadActions = 
    AuthState
    | firstTimeNewPassword
    | Otp
    | OtpResult
    | ResetPasswordNotif
    | RecoverAccountResultRequest
    | RecoverAccountResultSuccess
    | string
    ;

export type AuthAction = {
    type: Actions;
    payload: PayloadActions;
}

export interface ExpiredToken {
    status: number;
    message: string;
    name: string;
}

export type TypeRecoverAccount = 
    LoginFirstTimeRes
    | ValidationError
    | ExpiredToken


export interface UserInfo {
    [key: string]: any;
    'isUnique': boolean;
    'firstName': string;
    'lastName': string;
    'phone': string;
    'email': string;
    'phonecode': string;
    'username': string;
    'dateToUpdateOtp': string | null;
    'profilePicture': string;
    'loginEntreprise':number | null;
}

export interface User {
    userInfo: UserInfo
}

export interface UserReq {
    userReq: UserInfo
}

export interface Session {
    userId: number;
    id: number;
    browser: string;
    location: string;
    created_at: string;
    ipAddress: string;
}

export interface Security {
    security: SecurityPage
}

export interface EmailsInviteLink {
    emails: string[]
}

export interface CheckUserNameResp {
    isUnique: boolean
}

export interface SecurityPage {
    password: string;
    newPassword: string;
    email: string;
    sessions: Session[]
    }


export type userActions = 
    typeof GET_USER_INFO_REQUEST
    | typeof GET_USER_INFO_SUCCESS
    | typeof GET_USER_INFO_FAILURE
    | typeof UPDATE_USER_INFO_REQUEST
    | typeof UPDATE_SECURITY_PAGE
    | typeof GET_SECURITY_PAGE
    | typeof GET_ROLE_USER
    | typeof SET_ROLE_USER
    | typeof FAILED_ROLE_USER
    | typeof REQUEST_ROLES
    | typeof SUCCESS_ROLES
    | typeof FAILED_ROLES
    | typeof UPDATE_USER_INFO_SUCCESS
    | typeof UPDATE_LOGIN_ENTREPRISE

export interface UserAction {
    type: userActions,
    payload: User & UserReq & ExpiredToken & Security & EmailsInviteLink & RolesSupabase & RolesSupabase[] & string
}    

export interface NotificationMessage {
    notification: string;
}

export interface Entreprise {
    [key: string]: any;
    id: bigint | null;  // Assuming bigint is used for large integers
    created_at: string;  // ISO 8601 date string
    entrepriseName: string | null;
    ein: string | null;
    type: EntrepriseTypeSupaBase | null;  // Assuming 'public.entreprisetype' is an enum or similar type
    city: string | null;
    country: string | null;
    subscription: bigint | null;
    subStart: string | null;  // ISO 8601 date string
    subEnd: string | null;    // ISO 8601 date string
    extraUsers: number | null;
    extraIndicators: bigint | null;
    avatarUrl: string | null;
    active: boolean | null;
    updated_at: string | null;
    subscriptionName: string;
    subsciptionsOptions: SubscriptionData | null
}

interface SubscriptionData {
    id: number;
    created_at: string; // ISO 8601 date string
    subscription: string;
    credentials: boolean;
    paymentCards: boolean;
    insiderThreats: boolean;
    documents: boolean;
    databases: boolean;
    brand: boolean;
    vulnerabilities: boolean;
    ransomware: boolean;
    compliance: boolean;
    updated_at: string; // ISO 8601 date string
    subUsers: number;
    subIndicators: number;
    subTakedowns: number;
  }
  

export interface EntrepriseSupaBase {
    id: bigint;  // Assuming bigint is used for large integers
    created_at: string;  // ISO 8601 date string
    entrepriseName: string | null;
    ein: string | null;
    type: EntrepriseTypeSupaBase | null;  // Assuming 'public.entreprisetype' is an enum or similar type
    city: string | null;
    country: string | null;
    subscription: bigint | null;
    subStart: string | null;  // ISO 8601 date string
    subEnd: string | null;    // ISO 8601 date string
    extraUsers: number | null;
    extraIndicators: bigint | null;
    avatarUrl: string | null;
    active: boolean | null;
    updated_at: string | null;
}

export enum  EntrepriseTypeSupaBase {
    BANK = "Bank",
    STARTUP = "Startup",
    ENTREPRISE = "Entreprise",
    GOVERNMENT = "Government"
}

export interface UserEntrepriseReq {
    jwt:string
    firstname: string;
    lastname: string;
    username: string;
}


export interface UsersEntreprise {
    id: number;
    entrepriseId: number;
    permissions: Permissions[];
    firstname: string;
    lastname: string;
    email: string;
    approuved: boolean;
    profilePicture: string;
}

export interface Permissions {
    id ?:bigint;
    created_at?: string;
    users: string | null;
    entreprises: bigint | null;
    roles: bigint | null;
    updated_at?: string | null;
}

export interface ApprouveUserReq {
    userId: number
}

type TypeEntrepriseActions = 
    typeof GET_INFOS_ENTREPRISE
    | typeof SET_INFOS_ENTREPRISE
    | typeof UPDATE_INFOS_ENTREPRISE
    | typeof SET_USERS_ENTREPRISE
    | typeof SAVE_USER_ENTREPRISE
    | typeof SEND_INVITE_LINK
    | typeof SET_ENTREPRISE_PICTURE_PROFILE
    | typeof REQUEST_GET_ALL_ENTREPRISES
    | typeof SUCCESS_GET_ALL_ENTREPRISES
    | typeof FAILED_GET_ALL_ENTREPRISES


export interface EntrepriseActions {
    type: TypeEntrepriseActions,
    payload: Entreprise | UserEntrepriseReq | ApprouveUserReq | string | EntrepriseSupaBase| EntrepriseSupaBase[]
}

export interface AddUserInviteLinkReq {
    firstName: string;
    lastName: string;
    email: string;
}

export interface WatchList {
    assets: number;
    brandKeyword: number;
    domains: number;
    ips: number;
    bins: number;
    executives: number;
    ransomes: number;
}

export interface WatchListAssetsReq {
    vendor: string;
    product: string;
    version: string;
    assetsStartDate: string;
}

export interface WatchListAssets {
    id: number;
    vendor: string;
    product: string;
    version: string;
    assetsStartDate: string;
}

export enum Status {
    INREVIEW = 'In Review',
    REJECTED = 'Rejected',
    APPROUVED = 'Approved'
}

export interface WatchListBrandKeyWordReq {
    keyword: string;
    status: Status 
}

export interface WatchListBrandKeyWord {
    id: number
    keyword: string;
    status: Status 
}

export interface WatchListDomainReq {
    domain: string;
    status: Status 
}

export interface WatchListDomain {
    id: number
    domain: string;
    status: Status 
}

export interface WatchListIpReq {
    ip: string;
    status: Status 
}

export interface WatchListIp {
    id: number
    ip: string;
    status: Status 
}

export interface WatchListBinReq {
    bin: string;
    status: Status 
}

export interface WatchListBin {
    id: number
    bin: string;
    status: Status 
}

export interface WatchListExecutiveReq {
    fullName: string;
    email: string;
    executiveStatus: Status;
    username: string;
}

export interface WatchListExecutive {
    id: number
    fullName: string;
    email: string;
    executiveStatus: Status ;
    username:string;
}

export interface WatchListGroupReq {
    group: string;
    status: Status 
}

export interface WatchListGroup {
    id: number
    group: string;
}

export type TypesWatchListActions = 
    typeof GET_WATCH_LIST
    | typeof FAILED_WATCH_LIST
    | typeof GET_WATCH_LIST_ASSETS
    | typeof GET_WATCH_LIST_BIN
    | typeof SET_WATCH_LIST_BIN
    | typeof GET_WATCH_LIST_BRAND_KEYWORD
    | typeof GET_WATCH_LIST_DOMAIN
    | typeof GET_WATCH_LIST_EXECUTIVE
    | typeof GET_WATCH_LIST_IP
    | typeof GET_WATCH_LIST_RANSOME
    | typeof SET_WATCH_LIST
    | typeof SAVE_WATCH_LIST_ASSETS
    | typeof SET_WATCH_LIST_ASSETS
    | typeof GET_WATCH_LIST_ASSETS
    | typeof SAVE_WATCH_LIST_BRAND_KEYWORD
    | typeof SET_WATCH_LIST_BRAND_KEYWORD
    | typeof SAVE_WATCH_LIST_DOMAIN
    | typeof SET_WATCH_LIST_DOMAIN
    | typeof SAVE_WATCH_LIST_IP
    | typeof SET_WATCH_LIST_IP
    | typeof SAVE_WATCH_LIST_BIN
    | typeof SET_WATCH_LIST_BIN
    | typeof SAVE_WATCH_LIST_EXECUTIVE
    | typeof SET_WATCH_LIST_EXECUTIVE
    | typeof SAVE_WATCH_LIST_RANSOME
    | typeof SET_WATCH_LIST_RANSOME
    

export type PayloadsWatchList =
     WatchList 
    | WatchListAssetsReq
    | WatchListBrandKeyWordReq
    | WatchListDomainReq
    | WatchListIpReq
    | WatchListBinReq
    | WatchListExecutiveReq
    | WatchListGroupReq
    | WatchListAssets[]
    | WatchListBrandKeyWord[]
    | WatchListDomain[]
    | WatchListIp[]
    | WatchListBin[]
    | WatchListExecutive[]
    | WatchListGroup[]
    

export interface WatchListActions {
    type: TypesWatchListActions
    payload: PayloadsWatchList
}

export interface VulnerabilityRef {
    url: string;
    source: string;
    tags: string[];
}

export interface VulnerabilityConfigurationNodes {
    operator: string;
    negate: string;
    cpeMatch: {
        vulnerable: boolean;
        criteria: string;
        matchCriteriaId: string;
    }[]
}

export interface VulnerabilityWeaknesses {
    source: string;
    type: string;
    description: VulnerabilityDesc[]
}

export interface VulnerabilityCvssMetricV2 {
    source: string;
    type: string;
    cvssData: {
        version: string;
        vectorString: string;
        accessVector: string;
        accessComplexity: string;
        authentication: string;
        confidentialityImpact: string;
        integrityImpact: string;
        availabilityImpact: string;
        baseScore: number;
        userInteraction: string;
        attackVector: string;
        privilegesRequired: string;
        attackComplexity:string;
        scope: string;
        baseSeverity: string;
    };
    baseSeverity: string;
    exploitabilityScore: number;
    impactScore: number;
    acInsufInfo: boolean;
    obtainAllPrivilege: boolean;
    obtainUserPrivilege: boolean;
    obtainOtherPrivilege: boolean;
    userInteractionRequired: boolean;
}

export interface VulnerabilityDesc {
    lang: string;
    value: string;
}

export interface Vulnerability {
    exploitability_score: string;
    impact_score: string;
    cve_id: string;
    source: string;
    description: string;
    published_date: string;
    last_modified_date: string;
    link: string;
    version: string;
    vector_string: string;
    attack_vector: string;
    attack_complexity: string;
    privileges_required: string;
    user_interaction: string;
    scope: string;
    confidentiality_impact: string;
    integrity_impact: string;
    availability_impact: string;
    base_score: number;
    base_severity: string;
    problem_type_data: any;
    weakness_link: any;
    id: number;
    operator: any;
    vulnerable: any;
    cpe23_uri: any;
    version_start_including: any;
    version_end_excluding: any;
    cpe_vendor: string;
    cpe_product: string;
    cpe_product_version: string;
}
export interface VulnerabilityList {
    cves: Vulnerability[]
    references:References[]
    vulnTimeLines: VulTimeLines[]
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export interface SortVul{
    sortByDate?: string,
    sortBySeverity?: string,
    sortById?: string,
    search?:string,
    page?: number
}


export  interface References {
    id: string;
    url: string;
    name: string;
    refsource: string;
    tags: string;
    cve_id: string;
}

export interface VulTimeLines {
    published_date: string;
    vulnerabilities: string;
}

export interface CVEItem {
    cve: CVE;
    configurations: Configurations;
    impact: Impact;
    publishedDate: string;
    lastModifiedDate: string;
}


export interface CVE {
    data_type: string;
    data_format: string;
    data_version: string;
    CVE_data_meta: CVEMeta;
    problemtype: ProblemType;
    references: References;
    description: Description;
}

export interface CVEMeta {
    ID: string;
    ASSIGNER: string;
}

export interface ProblemType {
    problemtype_data: ProblemTypeData[];
}

export interface ProblemTypeData {
    description: DescriptionData[];
}

export interface References {
    reference_data: ReferenceData[];
}

export interface ReferenceData {
    url: string;
    name: string;
    refsource: string;
    tags: string[];
}

export interface Description {
    description_data: DescriptionData[];
}

export interface DescriptionData {
    lang: string;
    value: string;
}

export interface Configurations {
    CVE_data_version: string;
    nodes: Node[];
}

export interface Node {
    operator: string;
    children: Node[];
    cpe_match: CPENode[];
}

export interface CPENode {
    vulnerable: boolean;
    cpe23Uri: string;
    versionStartExcluding?: string;
    versionEndExcluding?: string;
    cpe_name: any[];  // Adjust the type based on actual use
}

export interface Impact {
    baseMetricV3: BaseMetricV3;
}

export interface BaseMetricV3 {
    cvssV3: CVSSV3;
    exploitabilityScore: number;
    impactScore: number;
}

export interface CVSSV3 {
    version: string;
    vectorString: string;
    attackVector: string;
    attackComplexity: string;
    privilegesRequired: string;
    userInteraction: string;
    scope: string;
    confidentialityImpact: string;
    integrityImpact: string;
    availabilityImpact: string;
    baseScore: number;
    baseSeverity: string;
}

export interface Page {
    resultsPerPage: number,
    startIndex: number,
    pubStartDate: string
    pubEndDate: string

}


export type PayloadVulnerabilities =
    VulnerabilityList
    & Vulnerability
    & SortVul
    & CVEItem
    & Page
    & string

export type VulnerabilitiesActions = 
    typeof GET_ALL_VULNERABILITIES
    | typeof SET_PAGE_TABLE
    | typeof SEARCH_QUERY_VUL
    | typeof SET_VUL_DETAIL
    | typeof SUCCESS_VULNERABILITIES
    | typeof FAILED_VULNERABILITIES
    | typeof REQUEST_VULNERABILITIES_OVERVIEW
    | typeof SUCCESS_VULNERABILITIES_OVERVIEW
    | typeof FAILED_VULNERABILITIES_OVERVIEW


export interface VulnerabilitiesAction {
    type: VulnerabilitiesActions
    payload: PayloadVulnerabilities
}

interface ReviewsDetail {
    name: string;
    text: string | null;
    textTranslated: string | null;
    publishAt: string;
    publishedAtDate: Date;
    likesCount: number;
    reviewId: string;
    reviewUrl: string;
    reviewerId: string;
    reviewerUrl: string;
    reviewerPhotoUrl: string;
    reviewerNumberOfReviews: number;
    isLocalGuide: boolean;
    stars: number;
    rating: string | null;
    responseFromOwnerDate: string | null;
    responseFromOwnerText: string | null;
    reviewImageUrls: string[];
    reviewContext: Record<string, any>;
    reviewDetailedRating: Record<string, any>;
}


export interface Places {
    name: string;
    type: string[];
    city: string;
    country: string;
    rating: number;
    reviews: number;
    street: string;
    postalCode: number | null;
    longitude: number;
    latitude: number;
    phone: string | null;
    accessibility: [
        {
            [key: string]: boolean
        }
    ],
    reviewsDetail: ReviewsDetail[] | null
}

export interface SurfaceWeb {
    id: number;
  url: string;
  type: string;
  profile_name: string;
  post_date: string; // Can also be `Date` if you plan to parse it into a date object
  post_body: string;
  likes: number | null;
  comments_count: number | null;
  userId: string;
  query_source: string;
  search_query: string;
  post_image: string;
}
interface TwitterReply {
    author_handle: string;
    author_name: string;
    likes: string;
    reply_count: string;
    reply_text: string;
    retweets: string;
}

export interface TwitterDetail {
    author_handle: string;
    author_name: string;
    image_url: string | null;
    likes: string;
    media_type: string | null;
    replies: TwitterReply[];
    retweets: string;
    timestamp: string;
    tweet: string;
    tweet_url: string;
    verified: string;
    views: string | null;
}

interface YoutubeComment {
    author: string;
    comment_text: string;
    like_count: string;
    comment_date: string;
}

export interface YoutubeDetail {
    channel_name: string;
    channel_url: string;
    comments: YoutubeComment[];
    comments_count: string;
    date: string;
    description: string;
    duration: string;
    like_count: number;
    subscriber_count: string;
    thumbnail_url: string;
    title: string;
    url: string;
    views: string;
}

export interface PlatformItem {
    num_comments: number;
    num_posts: number;
    num_queries: number;    
    results: SocialMediaDetail[]
}

export interface PlatformItemRedit {
    num_comments: number;
    num_posts: number;
    num_queries: number;    
    results: RedditDetail[]
}
export interface PlatformItemTwitter {
    num_comments: number;
    num_posts: number;
    num_queries: number;    
    results: TwitterDetail[]
}
export interface PlatformItemYoutube {
    num_comments: number;
    num_posts: number;
    num_queries: number;    
    results: YoutubeDetail[]
}
export interface SocialMedia {
    "youtube": PlatformItemYoutube;
    "instagram": PlatformItem;
    "reddit": PlatformItemRedit;
    "twitter": PlatformItemTwitter;
}

export interface SocialMediaDetail {
    inputUrl: string;
    id: string;
    type: string;
    shortCode: string;
    caption: string;
    hashtags: string[];
    mentions: string[];
    url: string;
    commentsCount: number;
    firstComment: string;
    latestComments: {
        id: string;
        text: string;
        ownerUsername: string;
        ownerProfilePicUrl: string;
        timestamp: string;
        likesCount: number;
    }[];
    dimensionsHeight: number;
    dimensionsWidth: number;
    displayUrl: string;
    images: string[]; // Assuming these are URLs to images
    videoUrl: string;
    alt: string | null;
    likesCount: number;
    videoViewCount: number;
    videoPlayCount: number;
    timestamp: string;
    childPosts: any[]; // Assuming these are child posts, can be of any type
    ownerFullName: string;
    ownerUsername: string;
    ownerId: string;
    productType: string;
    videoDuration: number;
    isSponsored: boolean;
}

interface RedditComment {
    author: string;
    comment_date: string;
    comment_text: string;
    num_replies: number;
    score: string;
}

export interface RedditDetail {
    author: string;
    body: string;
    comments: RedditComment[];
    comments_count: string;
    date: string;
    image_url: string;
    subreddit: string;
    title: string;
    upvotes_count: string;
    url: string;
    video_preview_url: string | null;
    video_thumbnail_url: string | null;
    yt_thumbnail_url: string | null;
    yt_video_url: string | null;
}

export type BrandIntelActions = typeof REQUEST_PLACES
    | typeof SUCCESS_PLACES
    | typeof FAILURE_PLACES
    | typeof SET_PLACE_DETAIL
    | typeof REQUEST_SURFACEWEB
    | typeof SUCCESS_SURFACEWEB
    | typeof REQUEST_SOCIALMEDIA
    | typeof SUCCESS_SOCIALMEDIA
    | typeof FAILED_SOCIALMEDIA
    | typeof SET_SOCIALMEDIA_DETAIL
    | typeof REQUEST_DARK
    | typeof SUCCESS_DARK
    | typeof FAILED_DARK
    | typeof SET_DARK
    | typeof REQUEST_DOMAINE
    | typeof SUCCESS_DOMAINE
    | typeof FAILED_DOMAINE
    | typeof SET_DOMAINE
    | typeof SET_STEP_PAGE
    | typeof SET_DNS
    | typeof FAILED_SURFACEWEB

export type TypesSocialMedia = SocialMediaDetail
    & YoutubeDetail
    & TwitterDetail
    & RedditDetail

export interface SocialMediaDetailPlatform {
    platform: string;
    resultSocialMedia: TypesSocialMedia
}
export interface SocialMediaPost {
    id: number;
    url: string;
    type: string;
    profile_name: string;
    post_date: string; // Use `Date` if you plan to parse it into a Date object
    post_body: string;
    likes: number | null;
    comments_count: number;
    userId: number | null;
    query_source: string | null;
    search_query: string | null;
    post_title: string | null;
}


export interface Dark {
    source: string;
    content: string
    detection_date: string;
    type: string;
    author: string;
}

export interface Domaine {
    id: number;
    domain: string;
    score: number;
    detection_date: string;
}

export type PayloadBrandIntel = Places[]
    & Places
    & SurfaceWeb[]
    & SocialMedia
    & SocialMedia[]
    & SocialMediaDetailPlatform
    & Dark[]
    & Dark
    & Domaine[]
    & Domaine
    & string
    & DnsResponse
    & SocialMediaPost
    & SocialMediaPost[]

export interface BranIntelAction {
    type: BrandIntelActions
    payload: PayloadBrandIntel
}


export interface DnsResponse {
    answers: {
        a?: string[];
        mx?: string[];
        ns?: string[];
        soa?: string[];
        txt?: string[];
        aaaa?: string[];
    };
    elapsed: number;
    hostname: string;
}

interface Location {
    available: boolean;
    delay: string | null;
    enabled: boolean;
    fqdn: string;
    lastscrape: string;
    slug: string;
    title: string;
    updated: string;
    version: number;
  }

interface RansomwareProfile {
    url: string;
  }

export interface GroupsRansomeDetail {
    id: number;                     // Unique identifier
    name: string;                   // Name of the service
    fqdn: string;                   // Fully Qualified Domain Name (FQDN)
    title: string;                  // Title or description of the service
    version: number;                // Version number
    slug: string;                   // URL or slug related to the service
    available: boolean;             // Availability status
    updated: string;                // Last updated timestamp (ISO format)
    lastscrape: string;             // Last scrape timestamp (ISO format)
    enabled: boolean;
}

export interface GroupsRansome {
    data: {
        id: number;                     // Unique identifier
    name: string;                   // Name of the service
    fqdn: string;                   // Fully Qualified Domain Name (FQDN)
    title: string;                  // Title or description of the service
    version: number;                // Version number
    slug: string;                   // URL or slug related to the service
    available: boolean;             // Availability status
    updated: string;                // Last updated timestamp (ISO format)
    lastscrape: string;             // Last scrape timestamp (ISO format)
    enabled: boolean;
    }[];
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export interface Victim {
    id: number;
    post_title: string;
    group_name: string;
    discovered: string;
    description: string;
    website: string;
    published: string;
    post_url: string;
    country: string;
}

export interface Victims {
    data:  {
        id: number;
    post_title: string;
    group_name: string;
    discovered: string;
    description: string;
    website: string;
    published: string;
    post_url: string;
    country: string;
    }[]
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export interface CountryDetail {
    countryDetail: {
        id: number;
        country_code: string;
        country_name: string;
        capital: string;
        population: number;
        area_sq_km: number;
        timezones: string;
    }[]
    certCountry: {
        id: number;
        team_name: string;
        website: string;
        country_code: string;
    }[]
    victims: Victim[];
}

export type TypePayloadRansomeWare =
    GroupsRansome[]
    & GroupsRansome
    & Negociations[]
    & Victims
    & MessageData[]
    & RansomeOverview
    & string
    & CountryDetail
    & Countries[]
    & Negociations
    & PageSort
    & GroupsRansomeDetail
    & Victim
    & SortVul

export type TypeRansomeWareActions = 
    typeof REQUEST_RANSOME
    | typeof SUCCESS_RANSOME
    | typeof FAILED_RANSOME
    | typeof REQUEST_VICTIMS
    | typeof SUCCESS_VICTIMS
    | typeof FAILED_VICTIMS
    | typeof REQUEST_NEGOCIATIONS
    | typeof SUCCESS_NEGOCIATIONS
    | typeof FAILED_NEGOCIATIONS
    | typeof SET_MESSAGES
    | typeof REQUEST_RANSOME_OVERVIEW
    | typeof SUCCESS_RANSOME_OVERVIEW
    | typeof FAILED_RANSOME_OVERVIEW
    | typeof REQUEST_RANSOME_COUNTRY_DETAIL
    | typeof SUCCESS_RANSOME_COUNTRY_DETAIL
    | typeof FAILED_RANSOME_COUNTRY_DETAIL
    | typeof SET_COUNTRY_CODE
    | typeof SET_LAST_UPDATED_COUNTRY
    | typeof REQUEST_RANSOME_COUNTRIES
    | typeof SUCCESS_RANSOME_COUNTRIES
    | typeof FAILED_RANSOME_COUNTRIES
    | typeof SET_GROUP_DETAIL
    | typeof SET_VIC_DETAIL


export interface RansomeWareAction {
    type: TypeRansomeWareActions
    payload: TypePayloadRansomeWare
}


export interface TakeDownRequest {
    domaine: string;
    status: string;
    reason: string;
}

interface UserCredential {
    userid: number;
    type: string;
    hwid: string;
    username: string;
    country: string;
    operatingsystem: string;
    ipaddress: string;
    malware_path: string;
    zipcode: string;
    location: string;
    log_date: string;
    created_at: string;
    antivirus: Antivirus[];
    screenshot: ScreenshotModel[];
    server: string;
}

interface Antivirus {
    antivirusid: number;
    userid: number;
    name: string;
    version: string;
}

interface ScreenshotModel {
    screenshotid: number;
    image_url: string;
    captured_at: string;
    userid: number;
}

export interface CredentialsLeak {
    antivirus: any[]
    data: {
        passwordid: number;
        ftpid: number;
    userid: number;
    url: string;
    password: string;
    username: string;
    application: string;
    riskscore: number;
    adjustedRiskScore: number;
    typeUser: string;
    total_risk_score:string;   
    user: UserCredential;
    }[],
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export type TypeThreatIntelAction = typeof REQUEST_CREADENTIALS_LEAK
    | typeof SUCCESS_CREADENTIALS_LEAK
    | typeof REQUEST_CREDITS_CARD
    | typeof SUCCESS_CREDITS_CARD
    | typeof FAILED_CREDENIALS_LEAK
    | typeof FAILED_CREDITS_CARD
    | typeof REQUEST_DOCUMENTS_LEAK
    | typeof SUCCESS_DOCUMENTS_LEAK
    | typeof FAILED_DOCUMENTS_LEAK
    | typeof REQUEST_TOTAL_RECORDS_CREDENTIALS
    | typeof SUCCESS_TOTAL_RECORDS_CREDENTIALS
    | typeof FAILED_TOTAL_RECORDS_CREDENTIALS
    | typeof REQUEST_DATABASELEAK
    | typeof SUCCESS_DATABASELEAK
    | typeof FAILED_DATABASELEAK

export type TypePayloadsThreatIntel = CredentialsLeak[]
    & CredentialsLeak
    & CreditCardsThreatIntel
    & DocumentsLeakIntel[]
    & number
    & HitEntry
    & string
    & PageSort

export interface ThreatIntel {
    type: TypeThreatIntelAction,
    payload: TypePayloadsThreatIntel
}

export interface NotificationBell {
  id: number;
  created_at: string; // ISO timestamp string
  data: any | null; // Replace 'any' with a specific type if you know the structure
  read: boolean | null;
  isCreditsCard: boolean | null;
  isDomains: boolean | null;
  isSurfaceWeb: boolean | null;
  isVul: boolean | null;
  isDarkWeb: boolean | null;
  isCredentials: boolean | null;
  isRansome: boolean | null;
}

export interface NotificationBellReq {
    usersId: number[];
}

type TypeNoticationsBellActions = typeof REQUEST_NOTIFICATIONS
    | typeof SUCCESS_NOTIFICATIONS
    | typeof SEND_NOTIFICATIONS
    | typeof ADD_NOTIFICATION
    | typeof UPDATE_NOTIFICATION
    | typeof DELETE_NOTIFICATION
    | typeof UPDATE_NOTIFICATION_USER
    | typeof ADD_NOTIFICATION_USER
    | typeof SUCCESS_NOTIFICATIONS_USER

type TypePayloadNotificationsBell = NotificationBell[]
    & NotificationBellReq
    & NotificationBell

export interface NotificationsReducer {
    type: TypeNoticationsBellActions;
    payload: TypePayloadNotificationsBell
}

type TypeDetailActions = typeof SET_CREDENTIAL_LEAK_DETAIL
type PayloadDetail = CredentialsLeak

export interface DetailStateReducer {
    type: TypeDetailActions
    payload: PayloadDetail
}

export interface CreditCardsThreatIntel {
    data: {
        cardid: number;
    userid: number;
    cardnumber: string;
    expiredate: string;
    brand: string;
    type: string;
    level: string;
    currency: string;
    nameoncard: string;
    countrycode: string;
    total_risk_score: string;
    bank_name: string;
    adjustedRiskScore: number;
    typeuser : string;
    created_at: string;
    }[];
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export interface InsideThreatsIntel {
    riskscore: number;
    type: string;
    fullName: string;
    id: number;
    numberCredentials: number;
}

export interface DocumentsLeakIntel {
    data: {
    fileid: number;
    file_name: string;
    updated_at: string;
    fullName: string;
    type: string;
    email: string;
    log_date: string;
    created_at:string
    }[];
    pagination: {
        totalRecords: number;
        totalPages:number;
        currentPage: string;
        pageSize: number;
    }
}

export interface DatabaseLeak {
    took: number;
    timed_out: boolean;
    _shards: ShardInfo;
    hits: Hits;
}

interface ShardInfo {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
}

interface Hits {
    total: {
        value: number;
        relation: string;
    };
    max_score: number;
    hits: Array<HitEntry>;
}

export interface HitEntry {
    _index: string;
    _id: string;
    _score: number;
    _source: Source;
}

interface Source {
    url?: string;
    login: string;
    password: string;
}



export interface StepsNavPage {
    vulStepName:string | null; 
}

type StepsNavPageActions = typeof SET_STEP_VUL

export interface StepsNavPageReducer {
    type: StepsNavPageActions
    payload: StepsNavPage
}

export interface Pagination {

}

export interface Negociations {
    chat_id: string;
    message_data: MessageData[];
    name_group: string;
    initial_ransom: string;
    negotiated_ransom: string;
    paid: string;
}

export interface Countries {
    country: string;
    countryName: string;
    victim_count: string;
    last_publish_date: string;
}

export interface RansomeOverview {
    groupsPost: {
        group_name: string;
        percent: number;
    }[]
    ransomeGroupsPosts: {
        group_name: string;
        nbr: string;
    }[]
    targetCountries: {
        country:string,
        nbr_of_victims: number
    }[]
    globalVictims: {
        nbr_of_victims: string;
        publish_date: string;
    }[]
}

export interface MessageData {
    content: string;
    party: string;
    timestamp: string
}

export interface HomeViewStats {
    totalRiskScoreHomeOverview: string
    averagePasswordRiskScore: number;
    averageCreditCardRiskScore: number;
    totalRiskScore: number;
    credentialsLeakCount: string;
    documentsLeak: {
        count: string;
    }[];
    totalVul: number;
    paymentCardsLeak: {
        count: string;
    }[];
    countUsersType: {
        countcustomer: string;
        countemployee: string;
        countexecutive: string;
    }[]
    credentialsTimeLine: {
            date: string; 
            credentials: string
        }[]
    riskScores: {
        Critical: number;
        High: number;
        Low: number;
        Medium: number;
        None: number
    };
    vulnerabilitites: {
        total_base_score: number | null;
    }[]
}

type StatsActions = typeof REQUEST_HOME_STATS
    | typeof SUCCESS_HOME_STATS
    | typeof FAILED_HOME_STATS

type PayloadStats = HomeViewStats
    & string

export interface Stats {
    type: StatsActions;
    payload: PayloadStats;
}


export interface DataLeaksView {
    credentialsLeakCount: number;
    compromizedUsers: number;
    compromizedExecutive: number
}

export interface UpdateRoleUserParams {
    userid: number;
    role: string;
  }



  
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const IS_FIRST_TIME_LOGIN = 'IS_FIRST_TIME_LOGIN';
export const SET_FIRST_TIME_NEW_PASSWORD_REQUEST = 'SET_FIRST_TIME_NEW_PASSWORD_REQUEST';
export const SET_FIRST_TIME_NEW_PASSWORD_SUCCESS = 'SET_FIRST_TIME_NEW_PASSWORD_SUCCESS';
export const OTP_REQUEST = 'OTP_REQUEST'
export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP = 'VERIFY_OTP';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';
export const SET_PICTURE_PROFILE = 'SET_PICTURE_PROFILE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const UPDATE_RECOVER_PASSWORD_REQUEST = 'UPDATE_RECOVER_PASSWORD_REQUEST';
export const UPDATE_RECOVER_PASSWORD_SUCCESS = 'UPDATE_RECOVER_PASSWORD_SUCCESS';
export const TOKEN_EXPIRATION = 'TOKEN_EXPIRATION'

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const UPDATE_LOGIN_ENTREPRISE = 'UPDATE_LOGIN_ENTREPRISE'

export const GET_ROLE_USER = 'GET_ROLE_USER';
export const SET_ROLE_USER = 'SET_ROLE_USER';
export const FAILED_ROLE_USER = 'FAILED_ROLE_USER';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';

export const GET_SECURITY_PAGE_REQUEST = 'GET_SECURITY_PAGE_REQUEST'
export const GET_SECURITY_PAGE = 'GET_SECURITY_PAGE'
export const UPDATE_SECURITY_PAGE = 'UPDATE_SECURITY_PAGE';
export const SET_MESSAGE = 'SET_MESSAGE';

export const GET_INFOS_ENTREPRISE = 'GET_INFOS_ENTREPRISE';
export const SET_INFOS_ENTREPRISE = 'SET_INFOS_ENTREPRISE';
export const UPDATE_INFOS_ENTREPRISE = 'UPDATE_INFOS_ENTREPRISE';
export const GET_USERS_ENTREPRISE = 'GET_USERS_ENTREPRISE';
export const SET_USERS_ENTREPRISE = 'SET_USERS_ENTREPRISE';
export const SAVE_USER_ENTREPRISE = 'SAVE_USER_ENTREPRISE';
export const REQUEST_GET_ALL_ENTREPRISES = 'GET_ALL_ENTREPRISES';
export const SUCCESS_GET_ALL_ENTREPRISES = 'SUCCESS_GET_ALL_ENTREPRISES'
export const FAILED_GET_ALL_ENTREPRISES = 'FAILED_GET_ALL_ENTREPRISES'
export const SET_ENTREPRISE_PICTURE_PROFILE = 'SET_ENTREPRISE_PICTURE_PROFILE'
export const LOGOUT = 'LOGOUT'
export const SET_JWT = 'SET_JWT'
export const SEND_INVITE_LINK = 'SEND_INVITE_LINK'
export const APPROUVE_USER = 'APPROUVE_USER'

export const GET_WATCH_LIST = 'GET_WATCH_LIST'
export const SET_WATCH_LIST = 'SET_WATCH_LIST'
export const FAILED_WATCH_LIST = 'FAILED_WATCH_LIST'
export const GET_WATCH_LIST_ASSETS = 'GET_WATCH_LIST_ASSETS'
export const SAVE_WATCH_LIST_ASSETS = 'SAVE_WATCH_LIST_ASSETS'
export const SET_WATCH_LIST_ASSETS = 'SET_WATCH_LIST_ASSETS'
export const SAVE_WATCH_LIST_BRAND_KEYWORD = 'SAVE_WATCH_LIST_BRAND_KEYWORD'
export const GET_WATCH_LIST_BRAND_KEYWORD = 'GET_WATCH_LIST_BRAND_KEYWORD'
export const SET_WATCH_LIST_BRAND_KEYWORD = 'SET_WATCH_LIST_BRAND_KEYWORD'
export const SAVE_WATCH_LIST_DOMAIN = 'SAVE_WATCH_LIST_DOMAIN'
export const GET_WATCH_LIST_DOMAIN = 'GET_WATCH_LIST_DOMAIN'
export const SET_WATCH_LIST_DOMAIN = 'SET_WATCH_LIST_DOMAIN'
export const SAVE_WATCH_LIST_IP = 'SAVE_WATCH_LIST_IP'
export const GET_WATCH_LIST_IP = 'GET_WATCH_LIST_IP'
export const SET_WATCH_LIST_IP = 'SET_WATCH_LIST_IP'
export const SAVE_WATCH_LIST_BIN = 'SAVE_WATCH_LIST_BIN'
export const GET_WATCH_LIST_BIN = 'GET_WATCH_LIST_BIN'
export const SET_WATCH_LIST_BIN = 'SET_WATCH_LIST_BIN'
export const SAVE_WATCH_LIST_EXECUTIVE = 'SAVE_WATCH_LIST_EXECUTIVE'
export const GET_WATCH_LIST_EXECUTIVE = 'GET_WATCH_LIST_EXECUTIVE'
export const SET_WATCH_LIST_EXECUTIVE = 'SET_WATCH_LIST_EXECUTIVE'
export const SAVE_WATCH_LIST_RANSOME = 'SAVE_WATCH_LIST_RANSOME'
export const GET_WATCH_LIST_RANSOME = 'GET_WATCH_LIST_RANSOME'
export const SET_WATCH_LIST_RANSOME = 'SET_WATCH_LIST_RANSOME'

export const GET_ALL_VULNERABILITIES_REQUEST = 'GET_ALL_VULNERABILITIES_REQUEST'
export const GET_ALL_VULNERABILITIES = 'GET_ALL_VULNERABILITIES'
export const SET_PAGE_TABLE = 'SET_PAGE_TABLE'
export const SEARCH_QUERY_VUL = 'SEARCH_QUERY_VUL'
export const GET_VULNERABILITIES_BY_CVE = 'GET_VULNERABILITIES_BY_CVE'
export const SET_VUL_DETAIL = "SET_VUL_DETAIL"
export const SUCCESS_VULNERABILITIES = "SUCCESS_VULNERABILITIES"
export const FAILED_VULNERABILITIES = "FAILED_VULNERABILITIES"

export const REQUEST_VULNERABILITIES_OVERVIEW = "REQUEST_VULNERABILITIES_OVERVIEW"
export const SUCCESS_VULNERABILITIES_OVERVIEW = "SUCCESS_VULNERABILITIES_OVERVIEW"
export const FAILED_VULNERABILITIES_OVERVIEW = "FAILED_VULNERABILITIES_OVERVIEW"

export const REQUEST_PLACES = 'REQUEST_PLACES'
export const SUCCESS_PLACES = 'SUCCESS_PLACES'
export const FAILURE_PLACES = 'FAILURE_PLACES'
export const SET_PLACE_DETAIL = 'SET_PLACE_DETAIL'



    export const REQUEST_SURFACEWEB = 'REQUEST_SURFACEWEB'
export const SUCCESS_SURFACEWEB = 'SUCCESS_SURFACEWEB'
export const FAILED_SURFACEWEB = 'FAILED_SURFACEWEB'

export const REQUEST_SOCIALMEDIA = 'REQUEST_SOCIALMEDIA'
export const SUCCESS_SOCIALMEDIA = 'SUCCESS_SOCIALMEDIA'
export const FAILED_SOCIALMEDIA = 'FAILED_SOCIALMEDIA'
export const SET_SOCIALMEDIA_DETAIL = 'SET_SOCIALMEDIA_DETAIL'

export const REQUEST_DARK = 'REQUEST_DARK'
export const SUCCESS_DARK = 'SUCCESS_DARK'
export const FAILED_DARK = 'FAILED_DARK'
export const SET_DARK = 'SET_DARK'

export const REQUEST_DOMAINE = 'REQUEST_DOMAINE'
export const SUCCESS_DOMAINE = 'SUCCESS_DOMAINE'
export const FAILED_DOMAINE = 'FAILED_DOMAINE'
export const SET_DOMAINE = 'SET_DOMAINE'

export const SET_STEP_PAGE = 'SET_STEP_PAGE'

export const REQUEST_RANSOME = "REQUEST_RANSOME"
export const SUCCESS_RANSOME = "SUCCESS_RANSOME"
export const FAILED_RANSOME = 'FAILED_RANSOME'
export const SET_GROUP_DETAIL = 'SET_GROUP_DETAIL'
export const SET_DNS = "SET_DNS"

export const REQUEST_VICTIMS = "REQUEST_VICTIMS"
export const SUCCESS_VICTIMS = "SUCCESS_VICTIMS"
export const FAILED_VICTIMS = "FAILED_VICTIMS"
export const SET_VIC_DETAIL = "SET_VIC_DETAIL"

export const REQUEST_CREADENTIALS_LEAK =  'REQUEST_CREADENTIALS_LEAK'
export const SUCCESS_CREADENTIALS_LEAK ="SUCCESS_CREADENTIALS_LEAK"
export const SET_CREDENTIAL_LEAK_DETAIL= "SET_CREDENTIAL_LEAK_DETAIL"
export const FAILED_CREDENIALS_LEAK = 'FAILED_CREDENIALS_LEAK'
export const REQUEST_CREDITS_CARD = 'REQUEST_CREDITS_CARD'
export const SUCCESS_CREDITS_CARD = 'SUCCESS_CREDITS_CARD'
export const FAILED_CREDITS_CARD = 'FAILED_CREDITS_CARD'

export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS"
export const SUCCESS_NOTIFICATIONS = "SUCCESS_NOTIFICATIONS"
export const SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const ADD_NOTIFICATION_USER = "ADD_NOTIFICATION_USER"
export const UPDATE_NOTIFICATION_USER = "UPDATE_NOTIFICATION_USER"
export const SUCCESS_NOTIFICATIONS_USER = "SUCCESS_NOTIFICATIONS_USER"

export const SET_STEP_VUL = 'SET_STEP_VUL'


export const REQUEST_NEGOCIATIONS = "REQUEST_NEGOCIATIONS"
export const SUCCESS_NEGOCIATIONS = "SUCCESS_NEGOCIATIONS"
export const FAILED_NEGOCIATIONS = 'FAILED_NEGOCIATIONS'
export const SET_MESSAGES = "SET_MESSAGES"

export const REQUEST_DOCUMENTS_LEAK = 'REQUEST_DOCUMENTS_LEAK'
export const SUCCESS_DOCUMENTS_LEAK = 'SUCCESS_DOCUMENTS_LEAK'
export const FAILED_DOCUMENTS_LEAK = 'FAILED_DOCUMENTS_LEAK'

export const REQUEST_TOTAL_RECORDS_CREDENTIALS = 'REQUEST_TOTAL_RECORDS_CREDENTIALS'
export const SUCCESS_TOTAL_RECORDS_CREDENTIALS = 'SUCCESS_TOTAL_RECORDS_CREDENTIALS'
export const FAILED_TOTAL_RECORDS_CREDENTIALS = 'FAILED_TOTAL_RECORDS_CREDENTIALS'

export const REQUEST_DATABASELEAK = "REQUEST_DATABASELEAK"
export const SUCCESS_DATABASELEAK = "SUCCESS_DATABASELEAK"
export const FAILED_DATABASELEAK = "FAILED_DATABASELEAK"

export const REQUEST_HOME_STATS = "REQUEST_HOME_STATS"
export const SUCCESS_HOME_STATS = 'SUCCESS_HOME_STATS'
export const FAILED_HOME_STATS = 'FAILED_HOME_STATS'

export const REQUEST_RANSOME_OVERVIEW = "REQUEST_RANSOME_OVERVIEW"
export const SUCCESS_RANSOME_OVERVIEW = "SUCCESS_RANSOME_OVERVIEW"
export const FAILED_RANSOME_OVERVIEW = "FAILED_RANSOME_OVERVIEW"

export const REQUEST_RANSOME_COUNTRY_DETAIL = 'REQUEST_RANSOME_COUNTRY_DETAIL'
export const SUCCESS_RANSOME_COUNTRY_DETAIL = 'SUCCESS_RANSOME_COUNTRY_DETAIL'
export const FAILED_RANSOME_COUNTRY_DETAIL = 'FAILED_RANSOME_COUNTRY_DETAIL'
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE'
export const SET_LAST_UPDATED_COUNTRY = 'SET_LAST_UPDATED_COUNTRY'
export const REQUEST_RANSOME_COUNTRIES = "REQUEST_RANSOME_COUNTRIES"
export const SUCCESS_RANSOME_COUNTRIES = 'SUCCESS_RANSOME_COUNTRIES'
export const FAILED_RANSOME_COUNTRIES = 'FAILED_RANSOME_COUNTRIES'


export const REQUEST_ROLES = 'REQUEST_ROLES'
export const SUCCESS_ROLES = 'SUCCESS_ROLES'
export const FAILED_ROLES = 'FAILED_ROLES'
