import React, { useEffect } from 'react'
import styles from '../dashboard.module.scss'
import Card from "./Card"
import ChartHome from './ChartHome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import CriticalRiskBar from './CriticalRiskBar'
import Piechart from './Piechart'
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import { CVEItem, GET_ALL_VULNERABILITIES, REQUEST_VULNERABILITIES_OVERVIEW, SortVul, Vulnerability, VulnerabilityList } from '../../../core/constants'
import { CircularProgress } from '@mui/material'
import ChartHomeVul from './ChartHomeVul'
import EmptyStateLogo from "../../../icons/Emptystate.svg"
import { useDispatch } from 'react-redux'
import moment from 'moment'

function OverviewComponent() {
    const dispatch = useDispatch()
    const { overviewVul, isLoadingVulOverview, vulnerabilies } = useSelector(vulnerabilitiesSelector)

    useEffect(() => {
     
      if(!overviewVul)
        dispatch({
            type: REQUEST_VULNERABILITIES_OVERVIEW
        })
        document.title = "Vulnerabilities Overview"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])
    const getRiskScore = (riskScore: number) => {
        if (riskScore === undefined) return "None"
        else if (riskScore <= 3.9) return "Low";
        else if (riskScore >= 4.0 && riskScore <= 6.9) return "Medium";
        else if (riskScore >= 7.0 && riskScore <= 8.9) return "High";
        else return "Critical";
    }
    const countRiskScores = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { Low: 0, Medium: 0, High: 0, Critical: 0, None: 0 };

        objects?.forEach(obj => {
            const category = getRiskScore(obj?.base_score);
            counts[category]++;
        });

        return counts;
    };

    type DataPoint = {
        date: string;
        victims: number;
    };

    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const threeMonthsInMilliseconds = 90 * 24 * 60 * 60 * 1000;


    const filterDataByPeriod = (data: DataPoint[]): DataPoint[] => {
        if (data.length < 2) return data;
    
        const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        const startDate = new Date(sortedData[0].date).getTime();
        const endDate = new Date(sortedData[sortedData.length - 1].date).getTime();
        const range = endDate - startDate;    
    
        if (range <= sevenDaysInMilliseconds) {
            // console.log("Applying 7-day filter");
            return data;
        } else if (range <= oneMonthInMilliseconds) {
            // console.log("Applying 1-month filter");
            return data.filter((_, index) => index % Math.ceil(data.length / 7) === 0);
        } else if (range <= threeMonthsInMilliseconds) {
            // console.log("Applying 3-month filter");
            return data.filter((_, index) => index % Math.ceil(data.length / 12) === 0);
        } else {
            // console.log("Applying default filter");
            const sampleRate = Math.max(1, Math.floor(data.length / 30));
            return data.filter((_, index) => index % sampleRate === 0);

            
            {/* data.forEach(item => {
                const monthKey = moment(item.date).format("YYYY-MM");
                if (!monthlyData[monthKey]) {
                    monthlyData[monthKey] = 0;
                }
                monthlyData[monthKey] += item.victims;
            });

            return Object.entries(monthlyData).map(([month, victims]) => ({
                date: moment(month, "YYYY-MM").format("MMM YYYY"),
                victims,
            })); */}


        }
    };



    const countRiskScoresExploibility = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { LOW: 0, MEDIUM: 0, HIGH: 0, CRITICAL: 0, NONE: 0 };

        objects?.forEach(obj => {
            const category = obj?.base_severity as "LOW" | "MEDIUM" | "HIGH" | "CRITICAL" | "NONE"
            counts[category]++;
        });

        return counts;
    };
    const countAttackVectors = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { NETWORK: 0, PHYSICAL: 0, ADJACENT: 0, LOCAL: 0 };

        objects?.forEach(obj => {
            const category = obj?.attack_vector as "NETWORK" | "LOCAL" | "ADJACENT" | "PHYSICAL"
            counts[category]++;
        });

        return counts;
    };
    const riskCounts = countRiskScores(overviewVul?.cves);
    const riskExploibility = countRiskScoresExploibility(overviewVul?.cves)
    const attackVectors = countAttackVectors(overviewVul?.cves)
    // console.log(vulnerabilies?.cves.filter(item => item.attack_vector === 'LOCAL').length)

    const denom = attackVectors.NETWORK + attackVectors.ADJACENT + attackVectors.LOCAL + attackVectors.PHYSICAL;
    const pieNetwork = denom !== 0 ? (attackVectors.NETWORK / denom * 100) : 0;
    const pieAdjacent = denom !== 0 ? (attackVectors.ADJACENT / denom * 100) : 0;
    const pieLocal = denom !== 0 ? (attackVectors.LOCAL / denom * 100) : 0;
    const piephysical = denom !== 0 ? (attackVectors.PHYSICAL / denom * 100) : 0;

    const data = overviewVul?.vulnTimeLines?.map(item => ({
        date: moment(item.published_date).format("MMM DD, YYYY"),
        vulnerabilities: Number(item.vulnerabilities),
        victims: Number()
    }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) || [];

    const filteredData = filterDataByPeriod(data);

    const totalRiskScoresExploibility = riskCounts.Critical + riskCounts.High + riskCounts.Medium + riskCounts.Low
    const totalattackVectors = riskExploibility.CRITICAL + riskExploibility.HIGH + riskExploibility.MEDIUM + riskExploibility.LOW
    // console.log("low: " + riskCounts.Critical / totalRiskScoresExploibility)
    
    
    
    return (
        isLoadingVulOverview ?
            <div className={styles.loaderVul}>
                <CircularProgress />
            </div>
            :
            <div className={styles.statsThreats}>
                <div className={styles.cards}>
                    <Card
                        text='Critical'
                        num={riskCounts.Critical}
                        percent={-60.15}
                    />
                    <Card
                        text='High'
                        num={riskCounts.High}
                        percent={+9.15}
                    />
                    <Card
                        text='Medium'
                        num={riskCounts.Medium}
                        percent={-20.15}
                    />

                    <Card
                        text='Low'
                        num={riskCounts.Low}
                        percent={+11.01}
                    />
                </div>
                <div className={styles.chartHomeWrapper}>
                    <div className={styles.timelineContainer}>
                        <div style={{ marginBottom: 24 }}>
                            <span style={{ color: "white", marginLeft: 25 }}>Vulnerabilities Timeline</span>
                        </div>
                        {
                            data?.length !== 0 ?
                                <ChartHomeVul data={filteredData} />
                                :
                                <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", height: "80%", justifyContent: "center" }}>
                                    <div>
                                        <span style={{ textAlign: "center", color: "#888888", fontSize: 12 }}>Data unavailable</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className={styles.vulnerabilityContainer}>
                        <div style={{ marginBottom: 24 }}>
                            <span style={{ color: "white" }}>Attack Vectors</span>
                        </div>
                        <Piechart
                            Network={pieNetwork}
                            Adjacent={pieAdjacent}
                            Local={pieLocal}
                            Physical={piephysical}
                        />
                    </div>
                </div>
                <div className={styles.threatAndMentionsBreakdown}>
                    <div className={styles.threatBreakdown}>
                        <div className={styles.recentThreats_Header}>
                            <span style={{ color: "white" }}>Severity Breakdown</span>

                        </div>
                        <div className={styles.riskProgressBar}>
                            <CriticalRiskBar total={Number(riskExploibility.CRITICAL)} value={(riskExploibility.CRITICAL / totalattackVectors) * 100} title="Critical" />
                            <CriticalRiskBar total={Number(riskExploibility.HIGH)} value={(riskExploibility.HIGH / totalattackVectors) * 100} title="High " />
                            <CriticalRiskBar total={Number(riskExploibility.MEDIUM)} value={(riskExploibility.MEDIUM / totalattackVectors) * 100} title="Medium" />
                            <CriticalRiskBar total={Number(riskExploibility.LOW)} value={(riskExploibility.LOW / totalattackVectors) * 100} title="Low" />
                        </div>
                    </div>
                    <div className={styles.mentionsBreakdown}>
                        <div className={styles.recentThreats_Header}>
                            <span style={{ color: "white" }}>Exploitability Potential</span>
                        </div>
                        <div className={styles.riskProgressBar}>
                            <CriticalRiskBar total={Number(riskCounts.Critical)} value={(riskCounts.Critical / totalRiskScoresExploibility) * 100} title="Critical" />
                            <CriticalRiskBar total={Number(riskCounts.High)} value={(riskCounts.High / totalRiskScoresExploibility) * 100} title="High" />
                            <CriticalRiskBar total={Number(riskCounts.Medium)} value={(riskCounts.Medium / totalRiskScoresExploibility) * 100} title="Medium" />
                            <CriticalRiskBar total={Number(riskCounts.Low)} value={(riskCounts.Low / totalRiskScoresExploibility) * 100} title="Low" />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default OverviewComponent