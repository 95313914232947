import { call, put } from "redux-saga/effects";
import { CredentialsLeak, CreditCardsThreatIntel, DatabaseLeak, DocumentsLeakIntel, FAILED_CREDENIALS_LEAK, FAILED_CREDITS_CARD, FAILED_DATABASELEAK, FAILED_DOCUMENTS_LEAK, FAILED_TOTAL_RECORDS_CREDENTIALS, HitEntry, NotificationBell, NotificationBellReq, REQUEST_NOTIFICATIONS, SEND_NOTIFICATIONS, SUCCESS_DATABASELEAK, SUCCESS_TOTAL_RECORDS_CREDENTIALS, ThreatIntel } from "../constants";
import { getCredentialsLeakApi, getCreditsCardAi, getDocumentsLeakApi, getTotalRecordsCredentialsApi, PageSort } from "../api/threatIntelApi";
import { setCredentialsLeak, setCreditsCards, setDocumentLeak, setTotalRecordsCredentialsLeak } from "../actions/threatIntelActions";
import { getNotificationsApi } from "../api/notificationApi";
import { sendNotificationAction } from "../actions/notificationActions";
import { getDatabaseLeaksApi } from "../api/databaseLeaksApi";
import { AxiosError } from "axios";

export function* getCredentialsLeakSaga(actions: ThreatIntel) {
    try { 
        const data: CredentialsLeak = yield call(getCredentialsLeakApi, actions.payload as PageSort)
        
        const usersId:number[] = data?.data?.map(item => item?.passwordid)
        const dataSendNotif: NotificationBellReq = {
            usersId
        }
        yield put(setCredentialsLeak(data))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* getCreditsCardSaga(actions: ThreatIntel) {
    try {
        const data: CreditCardsThreatIntel = yield call(getCreditsCardAi, actions.payload as PageSort)
        yield put(setCreditsCards(data))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getDocumentsLeakSaga(actions: ThreatIntel) {
    try {
        const data: DocumentsLeakIntel = yield call(getDocumentsLeakApi, actions.payload as PageSort)
        yield put(setDocumentLeak(data))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getTotalRecordsCredentialsSaga(actions: ThreatIntel) {
    try {
        const data: number = yield call(getTotalRecordsCredentialsApi)
        yield put(setTotalRecordsCredentialsLeak(data))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* searchDatabaseLeakSaga(actions: ThreatIntel) {
    try {
        const data:  HitEntry[] = yield call(getDatabaseLeaksApi, actions.payload as string)
        yield put({
            type: SUCCESS_DATABASELEAK,
            payload: data
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}