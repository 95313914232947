const processEnv = process.env;

interface EnvVariable {
  apiUrl: string;
  supabaseUrl: string;
  supabaseKey: string;
}
export const env: EnvVariable = {
  apiUrl: processEnv.REACT_APP_API_URL || '',
  supabaseUrl: processEnv.REACT_APP_SUPABASE_URL || '',
  supabaseKey: processEnv.REACT_APP_SUPABASE_KEY || ''
};