import { call, put } from "redux-saga/effects"
import { Countries, CountryDetail, FAILED_RANSOME, FAILED_RANSOME_COUNTRIES, FAILED_RANSOME_COUNTRY_DETAIL, FAILED_RANSOME_OVERVIEW, FAILED_VICTIMS, GroupsRansome, Negociations, RansomeOverview, RansomeWareAction, SortVul, SUCCESS_RANSOME_COUNTRIES, SUCCESS_RANSOME_COUNTRY_DETAIL, Victims } from "../constants"
import { getAllNegociationApi, getCountryDetailApi, getGroupsApi, getRansomeOverviexApi, getVictimsApi, getVictimsByCountryApi } from "../api/ransomeApi"
import { setGroupsAction, setNegociationAction, setRansomeOverviewSuccess, setVictimsAction } from "../actions/ransomeActions"
import { AxiosError } from "axios"
import { PageSort } from "../api/threatIntelApi"

export function* getGroupsSaga(actions: RansomeWareAction) {
    try {
        const response: GroupsRansome = yield call(getGroupsApi, actions.payload as PageSort)
        yield put(setGroupsAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* getVictimsSaga(actions: RansomeWareAction) {
    try {
        const response: Victims = yield call(getVictimsApi, actions.payload as PageSort)
        yield put(setVictimsAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getNegociationsSaga(actions: RansomeWareAction) {
    try {
        const response: Negociations[] = yield call(getAllNegociationApi)
        // console.log(response)
        yield put(setNegociationAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getRansomeOverviewSaga(actions: RansomeWareAction) {
    try {
        const response: RansomeOverview = yield call(getRansomeOverviexApi, actions.payload as string)
        yield put(setRansomeOverviewSuccess(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getCountryDetailSaga(actions: RansomeWareAction) {
    try {
        const code = actions.payload as string
        const response: CountryDetail = yield call(getCountryDetailApi, code)
        yield put({
            type: SUCCESS_RANSOME_COUNTRY_DETAIL,
            payload: response
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getCountriesSaga(actions: RansomeWareAction) {
    try {
        const response: Countries[] = yield call(getVictimsByCountryApi, actions.payload as SortVul)
        yield put({
            type: SUCCESS_RANSOME_COUNTRIES,
            payload: response
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}