import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import styles from '../dashboard.module.scss';
import { CircularProgress, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import ReactGA from 'react-ga4';
import { getAllVulnerabilitiesAPI } from '../../../core/api/vulnerabilityApi';
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer';
import { GET_ALL_VULNERABILITIES, SET_VUL_DETAIL, SortVul } from '../../../core/constants';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';
import EmptyStateVul from '../common/EmptyStateVul'
import { PageSort } from '../../../core/api/threatIntelApi';

function ListVulnerabilities() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vulnerabilies, isLoadingVul } = useSelector(vulnerabilitiesSelector);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>('ASC');
    const [all, setAll] = useState("all");
    const [severity, setSeverity] = useState("all");
    const [sortBy, setSortBy] = useState<'cve_id' | 'cve_date' | 'score'>('cve_date');
    const [search, setsearch] = useState("");
    const [isSortingById, setIsSortingById] = useState(false);
    const { notifs, notifsUser } = useSelector(notificationSelector)

    const [page, setPage] = useState(1)
    const handleChangeAll = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: event.target.value as string,
            sortBySeverity: severity,
            sortById: sortBy,
            search: search,
            page: page
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setAll(event.target.value as string);
    };

    const handleChangeSeverity = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: all,
            sortBySeverity: event.target.value as string,
            sortById: sortBy,
            search: search,
            page: page
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setSeverity(event.target.value as string);
    };

    {/*const handleChangeSortBy = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: all,
            sortBySeverity: severity,
            sortById: event.target.value as string
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setSortBy(event.target.value as string);
    }; */}

    const renderColors = (riskScore: number) => {
        if (riskScore === 0.0) return { textColor: "#757575", backgroundColor: "#E6E6E6", borderColor: "#757575" };
        if (riskScore >= 0.1 && riskScore <= 3.9) return { textColor: "#20b444", backgroundColor: "#ecf9ef", borderColor: "#20b444" };
        if (riskScore >= 4.0 && riskScore <= 6.9) return { textColor: "#A08100", backgroundColor: "#FFEB96", borderColor: "#D1A800" };
        if (riskScore >= 7.0 && riskScore <= 8.9) return { textColor: "#894C02", backgroundColor: "#FFD8A7", borderColor: "#894C02" };
        return { textColor: "#e11900", backgroundColor: "#FBD2C4", borderColor: "#e11900" };
    };

    const handleSort = (field: 'cve_id' | 'cve_date' | 'score') => {
        setIsSortingById(!isSortingById);
    };

    useEffect(() => {
        document.title = "Vulnerabilities";
        const dataSortVul: SortVul = {
            sortByDate: "all",
            sortBySeverity: "all",
            sortById: "all",
            search:search,
            page:1,
          };
          if(!vulnerabilies)
            dispatch({
                type: GET_ALL_VULNERABILITIES,
                payload: dataSortVul,
            });
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    let searchResults =  vulnerabilies?.cves
       

        let uniqueResults = vulnerabilies?.cves?.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.cve_id === item.cve_id)
        ).sort((a,b )=> {
            if(isSortingById){
                //@ts-ignore
                const [ , yearA, numA ] = a.cve_id.match(/CVE-(\d+)-(\d+)/).map(Number);
                //@ts-ignore
                const [ , yearB, numB ] = b.cve_id.match(/CVE-(\d+)-(\d+)/).map(Number);
                if (yearA !== yearB) {
                    return yearA > yearB ? 1 : -1
                  } else {
                    return numA > numB ? 1: -1
                  }
            }
            return b.published_date.localeCompare(a.published_date)
        });

    const vulNotif = notifs.filter(item => item.isVul === true && notifsUser.some((not: any) => not.notifId === item.id && not.read === false));

  

    const handleSearch = () => {
        const dataSortVul: SortVul = {
            sortByDate: "all",
            sortBySeverity: "all",
            sortById: "all",
            search:search,
            page:1,
          };
        dispatch({ type: GET_ALL_VULNERABILITIES, payload: dataSortVul })
    }

    const handleChangePage = (
        event: React.ChangeEvent<unknown>, value: number
      ) => {
        const dataSortVul: SortVul = {
            sortByDate: "all",
            sortBySeverity: "all",
            sortById: "all",
            search: "",
            page:value,
          };
        setPage(value)
        dispatch({ type: GET_ALL_VULNERABILITIES, payload: dataSortVul })
      };
    // console.log("vul notifs: ", vulNotif)
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, height: 45, gap: 16 }}>
                <div className={styles.searchAndIcon}>
                    <input value={search} className={styles.search} name="search" placeholder="Search" onChange={(e) => setsearch(e.target.value)} />
                    <FontAwesomeIcon className={styles.searchIcon} color="#333333" icon={faSearch} size="1x" onClick={handleSearch}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: "white", fontSize: "14px" }}>

                    {/*} <Select labelId="demo-simple-select-label" id="demo-simple-select" value={all} onChange={handleChangeAll} style={{ fontSize: 14, fontWeight: 500 }}>
                        <MenuItem value={'all'} disabled={all === 'all'}>Full Range</MenuItem>
                        <MenuItem value={'last7days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastmonth'}>Last month</MenuItem>
                        <MenuItem value={'last3months'}>Last 3 months</MenuItem>
                    </Select> */}
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={severity} onChange={handleChangeSeverity} style={{ fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}

                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#1f1f1f',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                    maxHeight: '360px',
                                    overflowY: 'auto',
                                },
                            },
                            MenuListProps: {
                                dense: false,
                                disablePadding: true,
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}

                    >
                        <MenuItem value={'all'} disabled={severity === 'severity'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>All Risks</MenuItem>
                        <MenuItem value={'CRITICAL'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Critical</MenuItem>
                        <MenuItem value={'HIGH'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>High</MenuItem>
                        <MenuItem value={'MEDIUM'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Medium</MenuItem>
                        <MenuItem value={'LOW'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Low</MenuItem>
                    </Select>
                </div>
            </div>

            {!isLoadingVul ? (
                searchResults && searchResults.length !== 0 ? (
                    <>
                    <table className={styles.table}>
                        <thead>
                            <tr className={styles.tableHead}>
                                <th></th>
                                <th style={{ paddingLeft: 8, display: 'flex', alignItems: "center", gap: 5, cursor: "pointer" }} onClick={() => handleSort('cve_id')}>
                                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                        <span style={{ marginRight: '4px' }}>CVE</span>
                                        <FontAwesomeIcon icon={faChevronDown} size="1x" height={12} width={11} style={{ transform: !isSortingById ? 'rotate(0deg)' : 'rotate(180deg)', }} />
                                    </p>
                                </th>
                                <th style={{ paddingLeft: 8 }} /*onClick={() => handleSort('cve_date')}*/>
                                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                        <span style={{ marginRight: '4px' }}>Description</span>
                                    </p>
                                </th>
                                <th style={{ paddingLeft: 8, display: 'flex', alignItems: "center", gap: 5 }} onClick={() => handleSort('score')}>
                                    { /*<p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                    <span style={{marginRight: '4px'}}>Score</span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7} style={{transform: sortBy === 'score' && sortOrder === 'ASC' ? 'rotate(180deg)' : 'rotate(0deg)',}}/>
                                </p> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueResults?.map((item) => (
                                <tr style={{ cursor: 'pointer' }} className={styles.tableActivityRowUsers} onClick={() => {
                                    dispatch({ type: SET_VUL_DETAIL, payload: item });
                                    navigate("/vulnerabilities/vulnerabilityDetail");
                                }}>
                                    <td className={styles.tableCell}>
                                        {
                                            vulNotif.some((respItem: any) => {

                                                return respItem?.data?.cve_id === item.cve_id;
                                            }) ? (
                                                <div className={styles.redPoint} />
                                            ) : (
                                                null // Nothing is displayed if the condition is not met
                                            )
                                        }
                                    </td>
                                    <td style={{ width: 140 }} className={styles.tableCell}>{item.cve_id}</td>
                                    <td className={styles.tableCell} style={{ padding: "16px 0px" }}>
                                        <span style={{ fontSize: '14', color: "white", lineHeight: 1.5 }}>{item?.description?.substring(0, 260)}...</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 16, gap: 5 }}>
                                                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                                                <span style={{ color: 'grey' }}>Published: {moment(item?.published_date).format("MMM D, YYYY - HH:mm")}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 16, gap: 5 }}>
                                                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey' />
                                                <span style={{ color: 'grey' }}>Last Modified: {moment(item?.last_modified_date).format("MMM D, YYYY - HH:mm")}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={styles.tableCell} style={{ paddingRight: 30 }}>
                                        {item?.base_score ? (
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <span className={styles.scoreSeverity} style={{
                                                    width: '22px',
                                                    color: renderColors(item?.base_score).textColor,
                                                    backgroundColor: renderColors(item?.base_score).backgroundColor,
                                                    borderColor: renderColors(item?.base_score).borderColor
                                                }}>
                                                    {item?.base_score}
                                                </span>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                <span className={styles.scoreUserInt} style={{ width: '22px', padding: '2px 6px', color: "" }}>
                                                    -
                                                </span>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                    showFirstButton
                    showLastButton
                    shape="rounded"
                    onChange={handleChangePage}
                    page={Number(vulnerabilies?.pagination?.currentPage)}
                    count={vulnerabilies?.pagination?.totalPages}
                    sx={{
                      '& .MuiPagination-ul': {
                        justifyContent: "center",
                        display: 'flex'
                      },
                      '& .Mui-selected': {
                        color: '#ffffff', // Text color for active page
                        backgroundColor: '#1f1f1f', // Background color for active page
                        opacity: 0.3, // Background color for active page
                      },
                      '& .MuiPaginationItem-root': {
                        color: '#ffffff', // Text color for inactive pages
                      },
                    }}
                  />
                  </>
                ) : (
                    <div className={styles.loaderVul}>
                        <EmptyStateVul />
                    </div>
                )
            ) : (

                <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>

            )}

        </div>
    )
}

export default ListVulnerabilities;


