import React, { useEffect } from 'react';
import styles from '../../Dashboard/dashboard.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from "../../Account/common/defendisLogoWhite.svg"
import { style } from '../../Account/PersonalAccount.style';
import { useSelector } from 'react-redux';
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';
import { getInfosEntrepriseRequest } from '../../../core/actions/entrepriseActions';
import { useDispatch } from 'react-redux';
import { menuItem, menuItems } from './MenuItems';
import { env } from '../../../environement/env';
function Menu() {
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const { entreprise, entreprises } = useSelector(entrepriseSelector)
    const currentPath = location.pathname;
    
    useEffect(() => {
        dispatch(getInfosEntrepriseRequest())
    }, [])
    

    return (
        <div className={ styles.menu }>
            <div className={styles.mainLogo}>
                <img src={logo} className={styles.logo} height={16}/>
            </div>
            <div className={styles.menuMain}>
            <div className={styles.menuList}>
                {
                    menuItems.map( (item: menuItem) => (
                        <NavLink to={item.path} style={{textDecoration: 'none', color: 'white'}}
                        className={currentPath.includes(item.path) ? styles.activeMenuItem : styles.menuItem}
                        key={item.id}>
                            <>{item.icon}</>
                           <p style={{fontSize: 14}}>{item.title}</p>
                        </NavLink>
                    ))
                }
            </div>
            <div className={styles.menuProfile}>
                <img src={
                      entreprise.profilePicture && entreprise?.profilePicture !== '' ? 
                    `${env.apiUrl}/uploads/${entreprise?.profilePicture}`:
                    '/profile.png'
                } className={styles.profileLogo} />
                <div>
                    <span className={styles.profileName}>{entreprise?.entrepriseName}</span>
                    <br/>
                    <span className={styles.profilePlan}>Demo License</span>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Menu
