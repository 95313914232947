import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './dashboard.module.scss'
import CountriesSection from './RansomeSections/CountriesSection'
import GroupsSection from './RansomeSections/GroupsSection'
import NegotiationsSection from './RansomeSections/NegotiationsSection'
import OverViewSection from './RansomeSections/OverViewSection'
import TelegramSection from './RansomeSections/TelegramSection'
import VictimsSection from './RansomeSections/VictimsSection'
import { setNamePageLocalStorage } from '../../core/actions/stepsNavPageActions'
import { REQUEST_RANSOME, REQUEST_RANSOME_COUNTRIES, REQUEST_RANSOME_OVERVIEW, REQUEST_VICTIMS } from '../../core/constants'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const nameSteps = [
    "overview",
    "countries",
    "groups",
    "negotiations",
    "telegram",
    "victims"
]

function RansomeView() {
    const dispatch = useDispatch()
    const [recent, setRecent] = useState('all')
    const storedStepName = localStorage.getItem('step') || 'overview';
    const [activeCompo, setactiveCompo] = useState(storedStepName)
    const handleChange = (event: SelectChangeEvent) => {
        if (storedStepName === "victims") {
            dispatch({
                type: REQUEST_VICTIMS,
                payload: event.target.value as string
            })
        } else if (storedStepName === "countries") {

            dispatch({
                type: REQUEST_RANSOME_COUNTRIES,
                payload: event.target.value as string
            })
        }
        else if (storedStepName === "groups") {

            dispatch({
                type: REQUEST_RANSOME,
                payload: event.target.value as string
            })
        }
        else if (storedStepName === "overview") {

            dispatch({
                type: REQUEST_RANSOME_OVERVIEW,
                payload: event.target.value as string
            })
        }
        setRecent(event.target.value as string);
    }

    const handleChangeStep = (newStepName: string) => {

        setNamePageLocalStorage(newStepName)
        setactiveCompo(newStepName);
    };

    useEffect(() => {
        if (nameSteps.includes(storedStepName))
            setactiveCompo(storedStepName);
        else
            setactiveCompo("overview")

        setRecent("all")

    }, [storedStepName]);

    const renderSection = () => {
        switch (activeCompo) {
            case 'overview':
                return <OverViewSection setStepSetAll={handleChangeStep} />
            case 'countries':
                return <CountriesSection />
            case 'groups':
                return <GroupsSection />
            case 'negotiations':
                return <NegotiationsSection />
            case 'telegram':
                return <TelegramSection />
            case 'victims':
                return <VictimsSection />
            default:
                return;
        }

    }
    return (
        <div className={styles.main}>
            <Header />
            <Menu />
            <div className={styles.content}>
                <div className={styles.menuContent}>
                    <div className={styles.firstSectionMenu}>
                        <p style={{ cursor: 'pointer' }} onClick={() => handleChangeStep('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</p>
                        <p style={{ cursor: 'pointer' }} onClick={() => handleChangeStep('victims')} className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Victims</p>
                        <p style={{ cursor: 'pointer' }} onClick={() => handleChangeStep('countries')} className={activeCompo === 'countries' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Countries</p>
                        <p style={{ cursor: 'pointer' }} onClick={() => handleChangeStep('groups')} className={activeCompo === 'groups' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Groups</p>
                        {
                            //<p style={{ cursor: 'pointer' }} onClick={() => handleChangeStep('negotiations')} className={activeCompo === 'negotiations' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Negotiations</p>//
                            //<p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('telegram')} className={activeCompo === 'telegram' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Telegram</p>
                        }
                    </div>
                    {/*
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={recent} onChange={handleChange} style={{ backgroundColor: "transparent", fontSize: 14, fontWeight: 500 }} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#1f1f1f',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                    maxHeight: '360px',
                                    overflowY: 'auto',
                                },
                            },
                            MenuListProps: {
                                dense: false,
                                disablePadding: true,
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        }}
                    >
                        <MenuItem value={'all'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Full Range</MenuItem>
                        <MenuItem value={'last7days'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonths'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Last month</MenuItem>
                        <MenuItem value={'last3Months'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px', fontSize: 14 }}>Last 3 months</MenuItem>
                    </Select> */}
                </div>
                <hr className={styles.divider} />
                {
                    renderSection()
                }
            </div>
        </div>
    )
}

export default RansomeView
