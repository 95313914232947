import axios from "axios";
import { Dark, DnsResponse, Domaine, Places, SocialMedia, SocialMediaPost, SurfaceWeb, TakeDownRequest } from "../constants";
import { env } from "../../environement/env";

export const getPlacesApi = async () => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/places/get`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Places[];
    } catch (error) {
        
    }
}

export const getSurfaceWebApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/surfaceWeb/get?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as SurfaceWeb[];
    } catch (error) {
        
    }
}

export const getSocialMediaApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/socialMedia/get?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as SocialMediaPost[];
    } catch (error) {
        
    }
}

export const getSocialMediaDetailApi = async (postId: number, platform: string ) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/brandIntel/socialMedia/postDetail`, {
            postId, platform
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        
    }
}

export const getSocialMediaDetailCommentsApi = async (postId: number ) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/brandIntel/socialMedia/postComments`, {
            postId
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        
    }
}



export const getDarkApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/dark/get?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Dark[];
    } catch (error) {
        
    }
}

export const getDomainsApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/domains/get?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Domaine[];
    } catch (error) {
        
    }
}


export const getDnsDomaineApi = async (domaineName: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/domains/dnsRecord?domaineName=${domaineName}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as DnsResponse
    } catch (error) {
        // console.log("error")
    }
}

export const getWhoisDomaineApi = async (domaineName: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/brandIntel/domains/whoisRecord?domaineName=${domaineName}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data
    } catch (error) {
        // console.log("error")
    }
}

export const saveTakeDownApi = async (takeDown: TakeDownRequest) => {
    try {
        await axios.post(`${env.apiUrl}brandIntel/takeDown/save`, takeDown, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
    } catch (error) {
        
    }
}

export const fetchImageApi = async (imageUrl: string): Promise<Blob | null> => {
    try {
        const response = await axios.get(`${env.apiUrl}/fetch-image`, {
            params: { url: encodeURIComponent(imageUrl) },
            responseType: 'blob', // Ensure response is treated as a binary blob
            headers: {
                'x-requested-by': 'ci-app',
                'Content-Type': 'application/json',
                'Origin': false
            }
        });
        return response.data;
    } catch (error) {
        // console.error('Error fetching the image:', error);
        return null;
    }
};
