import React, { useEffect, useState } from 'react'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'
import { VulnerabilityConfigurationNodes } from '../../../core/constants'
import EmptyState from '../common/EmptyState'
import EmptyStateConfig from '../common/EmptyStateConfig'

export default function Config() {
  const { vulDetail, vulnerabilies } = useSelector(vulnerabilitiesSelector)
  const references = vulnerabilies?.references.filter(item => item.cve_id === vulDetail?.cve_id)
  const [search, setsearch] = useState("");

  return (
    <div>
          <table className={styles.table} style={{marginTop:25}}>
            <tbody>
                    <tr className={styles.tableActivityRowRansomWatchList}>
                      <td style={{width: '150px', padding: '16px'}} className={styles.tableCell}>
                        <span style={{fontSize: 14}}>Vendor</span>
                      </td>
                      <td className={styles.tableCell}>
                        {vulDetail?.cpe_vendor}
                      </td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList}>
                      <td style={{padding: '16px'}} className={styles.tableCell}>
                        <span style={{fontSize: 14}}>Product</span>
                      </td>
                      <td  className={styles.tableCell}>
                        {vulDetail?.cpe_product}
                      </td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList}>
                      <td style={{padding: '16px'}} className={styles.tableCell}>
                        <span style={{fontSize: 14}}>Affected Versions</span>
                      </td>
                      <td className={styles.tableCell}>
                        Before {vulDetail?.cpe_product_version}
                      </td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList}>
                      <td style={{padding: '16px'}} className={styles.tableCell}>
                        <span style={{fontSize: 14}}>Configuration</span>
                      </td>
                      <td className={styles.tableCell}>
                        {vulDetail?.cpe23_uri}
                      </td>
                    </tr>
            </tbody>
          </table>
      </div>
  )
}
