import { faBell, faCalendar, faChevronDown, faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import styles from '../dashboard.module.scss'
import ReactGA from 'react-ga4';

export default function Submitted() {
    useEffect(() => {
      document.title = 'Compliance - Submitted'
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    }, [])
    
    return (
        <div style={{marginTop: 20}}>
             <div className={styles.searchAndIcon} style={{height:45}}>
                    <input className={styles.search} name='search' placeholder='Search'/>
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                </div>
           <table className={styles.table}>
                        <thead>
                            <tr className={styles.tableHead}>
                            <th style={{paddingLeft: 8}}>
                                Username {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}>
                                Password {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}>
                                Source {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            <th style={{paddingLeft: 8}}>
                                Date Submitted {" "}
                                <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={styles.tableActivityRowUsers} >
                                <td style={{width: '374px', padding: '10px 16px'}} className={styles.tableCell}>
                                    p.beasley@dundermifflin.com
                                </td>
                                
                                <td style={{width: '180px', padding: '10px 16px'}} className={styles.tableCell} >
                                    HRct76*****
                                </td>
                                <td style={{width: '374px', padding: '10px 16px'}} className={styles.tableCell} >
                                    https://member.dundermifflin.com
                                </td>
                                <td style={{width: '210px', padding: '10px 16px'}} className={styles.tableCell} >
                                    <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                    {" "}April 02, 2024
                                </td>
                                </tr>
                            
                            
                            </tbody>
                        </table> 
        </div>
    )
}
