import { faCalendar, faChevronDown, faChevronUp, faDownload, faSearch, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../dashboard.module.scss'
import { CreditCardsThreatIntel, REQUEST_CREDITS_CARD } from '../../../core/constants'
import { getCreditsCardAi, getCreditsCardExcelAi, PageSort } from '../../../core/api/threatIntelApi'
import moment from 'moment'
import { height } from '@mui/system'
import logoVisa from "../../../icons/CreditCards/visa.svg"
import logoMastercard from "../../../icons/CreditCards/mastercard.svg"
import logoAmex from "../../../icons/CreditCards/amex.svg"
import logoDiscover from "../../../icons/CreditCards/discover.svg"
import logoUndefined from "../../../icons/CreditCards/undefined.svg"
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import EmptyState from '../common/EmptyState'
import { CircularProgress, Pagination, Snackbar, Alert } from '@mui/material'
import { useDispatch } from 'react-redux'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'
import { exportToExcel } from '../components/ExportToExcel'
import dateFormats from '../dateFormats'

type SortOrder = 'asc' | 'desc';

export default function PaymentCardLeaks() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { creditsCard, isLoadingCreditCard } = useSelector(threatIntelSelector)
  const [search, setsearch] = useState("")
  const [page, setPage] = useState(1)
  const { notifs, notifsUser } = useSelector(notificationSelector)
  const [isDonwloaded, setisDonwloaded] = useState(false)
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    document.title = "Payment Card Leaks"
    ReactGA.send({ hitType: "Payment Card Leaks", page: window.location.pathname });

    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
    };
    if (!creditsCard) {
      dispatch({
        type: REQUEST_CREDITS_CARD,
        payload: dataCred
      })
    }
  }, [])

  const setDetailCreditCard = (item: any) => {

    const dataLocalStorage: any = item
    dataLocalStorage.color = renderColors(
      Number(item?.total_risk_score).toFixed(1)
    ).textColor
    dataLocalStorage.bgColor = renderColors(
      Number(item?.total_risk_score).toFixed(1)
    ).backgroundColor
    dataLocalStorage.borderColor = renderColors(
      Number(item?.total_risk_score).toFixed(1)
    ).borderColor

    console.log(dataLocalStorage)
    window.localStorage.setItem("item", JSON.stringify(dataLocalStorage))
    navigate('/threatIntel/creditCardDetail')
  }


  const renderColors = (score: string) => {
    const riskScore = parseFloat(score);
    if (riskScore === 0.0)
      return {
        textColor: "#757575",
        backgroundColor: "#e6e6e6",
        borderColor: "#757575"
      }
    else if (riskScore >= 0.1 && riskScore <= 3.9)
      return {
        textColor: "#20b444",
        backgroundColor: "#ecf9ef",
        borderColor: "#20b444"
      }
    else if (riskScore >= 4.0 && riskScore <= 6.9)
      return {
        textColor: "#A08100",
        backgroundColor: "#FFEB96",
        borderColor: "#A08100"
      }
    else if (riskScore >= 7.0 && riskScore <= 8.9)
      return {
        textColor: "#894C02",
        backgroundColor: "#FFD8A7",
        borderColor: "#894C02"
      }
    else
      return {
        textColor: "#e11900",
        backgroundColor: "#FBD2C4",
        borderColor: "#e11900"
      }
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      sortBy: search
    }
    setPage(value)
    dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
  };


  const handleSearch = (
  ) => {
    if (search !== "") {
      const dataCred: PageSort = {
        page: page,
        sortBy: search
      }
      dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
    } else {
      const dataCred: PageSort = {
        page: page,
        sortBy: null
      }
      dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
    }
  };


  console.log(notifs)

  const creditsCardNotif = notifs?.filter(item => item.isCreditsCard === true && notifsUser?.some((not: any) => not.notifId === item.id && not.read === false))

  const sortedResult = creditsCard?.data?.sort((a, b) => {
    const aInCardNotif = creditsCardNotif.some((notif) => notif?.data?.cardid === a.cardid);
    const bInCardNotif = creditsCardNotif.some((notif) => notif?.data?.cardid === b.cardid);

    // Sort elements in vulNotif to the top
    if (aInCardNotif && !bInCardNotif) return -1;
    if (!aInCardNotif && bInCardNotif) return 1;
    return 1
  })


  const finalSort = sortedResult?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'cardnumber') {
      comparison = a.cardnumber.localeCompare(b.cardnumber)
    } else if (sortBy === 'nameoncard') {
      comparison = a.nameoncard.localeCompare(b.nameoncard)
    } else if (sortBy === 'bank_name') {
      comparison = a.bank_name.localeCompare(b.nameoncard)
    } else if (sortBy === 'total_risk_score') {
      comparison = Number(a?.total_risk_score).toFixed(1).localeCompare(Number(b?.total_risk_score).toFixed(1))
    } else if (sortBy === 'brand') {
      comparison = a.brand.localeCompare(b.brand)
    } else if (sortBy === 'created_at') {
      comparison = a.created_at.localeCompare(b.created_at)
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })

  console.log(finalSort?.[0])



  const dataXlsx = sortedResult?.map(item => ({
    'Card Number': item?.cardnumber ? `${item.cardnumber.slice(0, 4)} ${item.cardnumber.slice(4, 6)}** **** ${item.cardnumber.slice(-4)}` : "N/A",
    'Expiry Date': item?.expiredate,
    'Card Owner': item?.nameoncard,
    'Brand': item?.brand,
    'Level': item?.level,
    'Risk Score': item?.total_risk_score,
  }))

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = async () => {
    const result = await getCreditsCardExcelAi()
    const dataXlsx = result?.map((item: any) => ({
      'Card Number': item?.cardnumber ? `${item.cardnumber.slice(0, 4)} ${item.cardnumber.slice(4, 6)}** **** ${item.cardnumber.slice(-4)}` : "N/A",
      'Expiry Date': item?.expiredate,
      'Card Owner': item?.nameoncard,
      'Brand': item?.brand,
      'Level': item?.level,
      'Risk Score': item?.total_risk_score,
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Payment_Cards_Leaks-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };


  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e) => setsearch(e.target.value)}  />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch} />
        </div>

        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 14 }} />
        </div>

      </div>
      <div style={{ width: "100%" }}>
        {
          !isLoadingCreditCard ?
            sortedResult && sortedResult.length !== 0 ?
              <>
                <table className={styles.table}>
                  <tr className={styles.tableHead}>
                    <th></th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('cardnumber')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Card Number</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'cardnumber' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Card Expiry</span>
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('nameoncard')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Full Name</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'nameoncard' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('total_risk_score')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Risk Score</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'total_risk_score' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8, paddingRight: 13 }} onClick={() => handleSort('brand')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Brand</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'brand' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                    <th style={{ paddingLeft: 8 }} onClick={() => handleSort('created_at')}>
                      <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                        <span style={{ marginRight: '4px' }}>Detected on</span>
                        <FontAwesomeIcon
                          icon={sortBy === 'created_at' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                          size="1x"
                          height={12}
                          width={11}
                        />
                      </div>
                    </th>
                  </tr>
                  {
                    finalSort?.map((item) =>
                      <tr className={styles.tableActivityRowCard} onClick={() => setDetailCreditCard(item)}>
                        <td className={styles.tableCell} style={{ width: 6 }}>
                          {
                            creditsCardNotif.some((respItem: any) => {

                              return respItem?.data?.cardid === item.cardid;
                            }) ? (
                              <div className={styles.redPoint} />
                            ) : (
                              null // Nothing is displayed if the condition is not met
                            )
                          }
                        </td>
                        <td className={styles.tableCell}>
                          {item?.cardnumber ? `${item.cardnumber.slice(0, 4)} ${item.cardnumber.slice(4, 6)}** **** ${item.cardnumber.slice(-4)}` : "-"}
                        </td>
                        <td className={styles.tableCell}>
                          <div style={{ display: 'flex', alignItems: "center", gap: 10 }}>
                            <FontAwesomeIcon icon={faCalendar} height={12} width={11} />
                            <span style={{ fontSize: 14 }}>{item?.expiredate ? item.expiredate : "**/****"}</span>
                          </div>
                        </td>
                        {/* <td style={{width: "140px"}} className={styles.tableCell}>
                                526
                            </td> */}
                        <td className={styles.tableCell}>
                        <span style={{ fontSize: 14 }}>{item?.nameoncard?item.nameoncard.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : "-"} </span>
                        </td>
                        <td className={styles.tableCell} style={{ width: "130px", padding: "13px 0px" }}>
                          <span style={{
                            width: 22,
                            color: renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.textColor,
                            backgroundColor: renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.backgroundColor,
                            border: `1px solid ${renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.borderColor}`


                          }} className={styles.scoreSeverity}>{
                              Number(item?.total_risk_score).toFixed(1)
                            }</span>
                        </td>
                        <td className={styles.tableCell} style={{ width: "120px" }}>
                          <div style={{ display: "flex", paddingRight: 13 }}>
                            <img src={
                              item?.brand?.toLowerCase() === "visa" ? logoVisa :
                                item?.brand?.toLowerCase() === "discover" ? logoDiscover :
                                  item?.brand?.toLowerCase() === "amex" ? logoAmex :
                                    item?.brand?.toLowerCase() === "mastercard" ? logoMastercard : logoUndefined
                            } />
                          </div>
                        </td>
                        <td className={styles.tableCell} style={{ paddingLeft: 8, width: 150 }}>
                          <div style={{}}>
                            {
                              item?.created_at ? (
                                <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                                  <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey" />
                                  <span style={{ fontSize: 14 }}> {moment(item.created_at).format('MMM DD, YYYY')}
                                  </span>
                                </div>
                              ) : "-"
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </table>
                <Pagination
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  onChange={handleChangePage}
                  page={Number(creditsCard?.pagination?.currentPage)}
                  count={creditsCard?.pagination?.totalPages}
                  sx={{
                    '& .MuiPagination-ul': {
                      justifyContent: "center",
                      display: 'flex'
                    },
                    '& .Mui-selected': {
                      color: '#ffffff', // Text color for active page
                      backgroundColor: '#1f1f1f', // Background color for active page
                    },
                    '& .MuiPaginationItem-root': {
                      color: '#ffffff', // Text color for inactive pages
                    },
                  }}
                />
              </>
              :
              <div className={styles.loaderVul}>
                <EmptyState />
              </div>
            :
            <div className={styles.loaderVul}>
              <CircularProgress />
            </div>
        }
      </div>

      <Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>

    </div>
  )
}
