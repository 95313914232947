import { take, takeLatest } from 'redux-saga/effects'
import { APPROUVE_USER, GET_ALL_VULNERABILITIES, GET_ALL_VULNERABILITIES_REQUEST, GET_INFOS_ENTREPRISE, GET_ROLE_USER, GET_SECURITY_PAGE_REQUEST, GET_USERS_ENTREPRISE, GET_USER_INFO_REQUEST, GET_VULNERABILITIES_BY_CVE, GET_WATCH_LIST, GET_WATCH_LIST_ASSETS, GET_WATCH_LIST_BIN, GET_WATCH_LIST_BRAND_KEYWORD, GET_WATCH_LIST_DOMAIN, GET_WATCH_LIST_EXECUTIVE, GET_WATCH_LIST_IP, GET_WATCH_LIST_RANSOME, LOGIN_REQUEST, LOGOUT, OTP_REQUEST, REQUEST_CREADENTIALS_LEAK, REQUEST_CREDITS_CARD, REQUEST_DARK, REQUEST_DATABASELEAK, REQUEST_DOCUMENTS_LEAK, REQUEST_DOMAINE, REQUEST_GET_ALL_ENTREPRISES, REQUEST_HOME_STATS, REQUEST_NEGOCIATIONS, REQUEST_NOTIFICATIONS, REQUEST_PLACES, REQUEST_RANSOME, REQUEST_RANSOME_COUNTRIES, REQUEST_RANSOME_COUNTRY_DETAIL, REQUEST_RANSOME_OVERVIEW, REQUEST_ROLES, REQUEST_SOCIALMEDIA, REQUEST_SURFACEWEB, REQUEST_TOTAL_RECORDS_CREDENTIALS, REQUEST_VICTIMS, REQUEST_VULNERABILITIES_OVERVIEW, RESET_PASSWORD_REQUEST, SAVE_USER_ENTREPRISE, SAVE_WATCH_LIST_ASSETS, SAVE_WATCH_LIST_BIN, SAVE_WATCH_LIST_BRAND_KEYWORD, SAVE_WATCH_LIST_DOMAIN, SAVE_WATCH_LIST_EXECUTIVE, SAVE_WATCH_LIST_IP, SAVE_WATCH_LIST_RANSOME, SEARCH_QUERY_VUL, SEND_INVITE_LINK, SEND_NOTIFICATIONS, SET_FIRST_TIME_NEW_PASSWORD_REQUEST, SET_PAGE_TABLE, SUCCESS_VICTIMS, UPDATE_INFOS_ENTREPRISE, UPDATE_LOGIN_ENTREPRISE, UPDATE_RECOVER_PASSWORD_REQUEST, UPDATE_SECURITY_PAGE, UPDATE_USER_INFO_REQUEST, VERIFY_OTP_REQUEST } from '../constants'
import { authSaga, logoutSaga, otpSendSaga, otpVerifySaga, resetPasswordSaga, setFirstTimeNewPasswordSaga, updatePasswordRecoverSaga } from './authSaga'
import { getInfosSecuritySaga, getRolesUserSaga, getRoleUserSaga, getUserInfoSaga, sendInviteLinkSaga, updateLoginEntrepriseSaga, updateSecurityInfoUserSaga, updateUserInfoSaga } from './userSaga';
import { addUserSaga, approuveUserSaga, getAllEntreprisesSaga, getInfosEntrepriseSaga, getUsersEntrepriseSaga, updateInfosEntrepriseSaga } from './entrepriseSage';
import { getWatchListBinSaga, getWatchListBrandKeywordSaga, getWatchListDomainSaga, getWatchListExecutiveSaga, getWatchListIpSaga, getWatchListRansomeSaga, getWatchListSaga, saveWatchListAssetsSaga, saveWatchListBinSaga, saveWatchListBrandKeywordSaga, saveWatchListDomainSaga, saveWatchListExecutiveSaga, saveWatchListIpSaga, saveWatchListRansomeSaga, setWatchListAssetsSaga } from './watchListSaga';
import { getAllVulnerabilitiesSaga, getOverviewVulSaga, getVulnerabilitieByCVESaga } from './vulnerabilitiesSaga';
import { getDarkSaga, getDomaineSaga, getPlacesSaga, getSocialMediaSaga, getSurfaceWebSaga } from './brandIntelSaga';
import { getCountriesSaga, getCountryDetailSaga, getGroupsSaga, getNegociationsSaga, getRansomeOverviewSaga, getVictimsSaga } from './ransomeSaga';
import { getCredentialsLeakSaga, getCreditsCardSaga, getDocumentsLeakSaga, getTotalRecordsCredentialsSaga, searchDatabaseLeakSaga } from './threatIntelSaga';
import { getNotificationsSaga, sendNotificationSaga } from './notificationsSaga';
import { getHomeViewSaga } from './statsSaga';

export function* watcherSaga() {
    yield takeLatest(LOGIN_REQUEST, authSaga);
    yield takeLatest(SET_FIRST_TIME_NEW_PASSWORD_REQUEST, setFirstTimeNewPasswordSaga)
    yield takeLatest(OTP_REQUEST, otpSendSaga);
    yield takeLatest(VERIFY_OTP_REQUEST, otpVerifySaga);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga)
    yield takeLatest(UPDATE_RECOVER_PASSWORD_REQUEST, updatePasswordRecoverSaga)
    yield takeLatest(GET_USER_INFO_REQUEST as any, getUserInfoSaga)
    yield takeLatest(UPDATE_USER_INFO_REQUEST, updateUserInfoSaga)
    yield takeLatest(GET_SECURITY_PAGE_REQUEST, getInfosSecuritySaga)
    yield takeLatest(UPDATE_SECURITY_PAGE, updateSecurityInfoUserSaga)
    yield takeLatest(GET_INFOS_ENTREPRISE as any, getInfosEntrepriseSaga)
    yield takeLatest(UPDATE_INFOS_ENTREPRISE, updateInfosEntrepriseSaga)
    yield takeLatest(GET_USERS_ENTREPRISE, getUsersEntrepriseSaga)
    yield takeLatest(SAVE_USER_ENTREPRISE, addUserSaga)
    yield takeLatest(LOGOUT, logoutSaga)
    yield takeLatest(SEND_INVITE_LINK, sendInviteLinkSaga)
    yield takeLatest(APPROUVE_USER, approuveUserSaga)
    yield takeLatest(GET_WATCH_LIST, getWatchListSaga)
    yield takeLatest(SAVE_WATCH_LIST_ASSETS, saveWatchListAssetsSaga)
    yield takeLatest(GET_WATCH_LIST_ASSETS, setWatchListAssetsSaga)
    yield takeLatest(SAVE_WATCH_LIST_BRAND_KEYWORD, saveWatchListBrandKeywordSaga)
    yield takeLatest(GET_WATCH_LIST_BRAND_KEYWORD, getWatchListBrandKeywordSaga)
    yield takeLatest(SAVE_WATCH_LIST_DOMAIN, saveWatchListDomainSaga)
    yield takeLatest(GET_WATCH_LIST_DOMAIN, getWatchListDomainSaga)
    yield takeLatest(SAVE_WATCH_LIST_IP, saveWatchListIpSaga)
    yield takeLatest(GET_WATCH_LIST_IP, getWatchListIpSaga)
    yield takeLatest(SAVE_WATCH_LIST_BIN, saveWatchListBinSaga)
    yield takeLatest(GET_WATCH_LIST_BIN, getWatchListBinSaga)
    yield takeLatest(SAVE_WATCH_LIST_EXECUTIVE, saveWatchListExecutiveSaga)
    yield takeLatest(GET_WATCH_LIST_EXECUTIVE, getWatchListExecutiveSaga)
    yield takeLatest(SAVE_WATCH_LIST_RANSOME, saveWatchListRansomeSaga)
    yield takeLatest(GET_WATCH_LIST_RANSOME, getWatchListRansomeSaga)
    yield takeLatest(GET_ALL_VULNERABILITIES, getAllVulnerabilitiesSaga)
    yield takeLatest(REQUEST_VULNERABILITIES_OVERVIEW, getOverviewVulSaga)
    yield takeLatest(GET_VULNERABILITIES_BY_CVE, getVulnerabilitieByCVESaga)
    yield takeLatest(REQUEST_PLACES, getPlacesSaga)
    yield takeLatest(REQUEST_SURFACEWEB, getSurfaceWebSaga)
    yield takeLatest(REQUEST_SOCIALMEDIA, getSocialMediaSaga)
    yield takeLatest(REQUEST_DARK, getDarkSaga)
    yield takeLatest(REQUEST_DOMAINE, getDomaineSaga)
    yield takeLatest(REQUEST_RANSOME, getGroupsSaga)
    yield takeLatest(REQUEST_VICTIMS, getVictimsSaga)
    yield takeLatest(REQUEST_CREADENTIALS_LEAK, getCredentialsLeakSaga)
    yield takeLatest(REQUEST_NOTIFICATIONS, getNotificationsSaga)
    yield takeLatest(SEND_NOTIFICATIONS, sendNotificationSaga)
    yield takeLatest(REQUEST_NEGOCIATIONS, getNegociationsSaga)
    yield takeLatest(REQUEST_CREDITS_CARD, getCreditsCardSaga)
    yield takeLatest(REQUEST_DOCUMENTS_LEAK, getDocumentsLeakSaga)
    yield takeLatest(REQUEST_TOTAL_RECORDS_CREDENTIALS, getTotalRecordsCredentialsSaga)
    yield takeLatest(REQUEST_DATABASELEAK, searchDatabaseLeakSaga)
    yield takeLatest(REQUEST_HOME_STATS, getHomeViewSaga)
    yield takeLatest(REQUEST_RANSOME_OVERVIEW, getRansomeOverviewSaga)
    yield takeLatest(REQUEST_RANSOME_COUNTRY_DETAIL, getCountryDetailSaga)
    yield takeLatest(REQUEST_RANSOME_COUNTRIES,getCountriesSaga)
    yield takeLatest(REQUEST_GET_ALL_ENTREPRISES, getAllEntreprisesSaga)
    yield takeLatest(UPDATE_LOGIN_ENTREPRISE, updateLoginEntrepriseSaga)
    yield takeLatest(GET_ROLE_USER, getRoleUserSaga)
    yield takeLatest(REQUEST_ROLES,getRolesUserSaga)
}