import React, { FormEvent, useState, useEffect } from 'react'
import Header from '../Dashboard/common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircleCheck, faCircleXmark, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest, updateSecurityInfoUserRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { Session, SecurityPage } from '../../core/constants'
import phonecodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import HeaderSecurity from './components/HeaderSecurity'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import ReactGA from 'react-ga4';
import { updateSecurityInfoUserAPI } from '../../core/api/userApi'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
      borderBottom: '1px solid green !important',
    }
  }),
);

const Security = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { security, userInfo } = useSelector(userSelector)
  const { auth } = useSelector(authSelector)
  const [recent, setRecent] = useState('10')
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [formState, setformState] = useState<SecurityPage>(security);
  const [openNotification, setopenNotification] = useState(false)
  const [newPassword, setnewPassword] = useState<string>(formState.newPassword)
  const [rePassword, setrePassword] = useState<string>(formState.password)
  const [emailStorage, setemailStorage] = useState<string | undefined>()
  const [hmacStorage, sethmacStorage] = useState<string | undefined>()
  const [fullName, setfullName] = useState<string | undefined>()
  const [msg, setmsg] = useState('')
  const [msgColor, setmsgColor] = useState('')
  const [sessionUpdate, setsessionUpdate] = useState<Session[]>([])
  const validatePassword = (password: string, retPassword: string): boolean => {
    return password !== retPassword
  };

  useEffect(() => {
    document.title = "Account Security"
    ReactGA.send({ hitType: "Account Security", page: window.location.pathname });
  }, [])

  const [dateState, setDateState] = useState('');
  const updateDateDisplay = (timestamp: string) => {
    const date = moment(timestamp); // Directly use the ISO string with moment
    console.log(date.format()); // Log the parsed date to check its format

    if (date.isSame(moment(), 'day')) {
      // If the date is today, set to "Current"
      return 'Current';
    } else {
      // Otherwise, format as "month day, year"
      return date.format("MMMM DD, YYYY");
    }
  };

  // Update the display based on the current timestamp

  const handleChange = (event: typesHandleChange) => {
    const { name, value } = event.target;
    if ('checked' in event.target && (name === 'verifyWithEmail' || name === 'verifyWithSms')) {
      const { name, checked } = event.target as HTMLInputElement;          // Handle the state update for a checkbox or switch here
      setformState(prevState => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setformState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }


    if (name === 'password') {
      setrePassword(value)
      setError(value !== formState.newPassword)
    }
    if (name === 'newPassword') {
      setError(value !== formState.password)
    }

  }




  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formState.sessions = sessionUpdate
    formState.email = userInfo.email
    try {
      const resp = await updateSecurityInfoUserAPI({
        security: formState
      })
      if (
        resp.message === "The actual password is wrong." ||
        resp.message === "New password must be different from the old one." ||
        resp.message === "New password must be required."
      ) {
        setmsg(resp.message)
        setmsgColor('error')
      } else if (resp.message === "Password updated successfully.") {
        setmsg(resp.message)
        setmsgColor('success')
      }
    } catch (error) {
      navigate("/")
    }
    setopenNotification(true)
  }

  const handleCloseNotification = () => {
    setopenNotification(false)
  }



  const handleDeleteSession = async (sessionId: number) => {

    const updatedSessions = formState.sessions.filter(session => session.id !== sessionId);
    setformState({ ...formState, sessions: updatedSessions });
    const sessionToDelete = formState.sessions.filter(session => session.id === sessionId)
    setsessionUpdate(sessionUpdate.concat(sessionToDelete))
    setmsg("Session deleted successfully")
    setmsgColor("success")
    // Optionally, show a notification to the user
    setopenNotification(true);
  };

  return (
    <div className={styles.main}>
      <Menu />
      <Header />
      <div className={styles.content}>
        <HeaderSecurity security={formState} password={rePassword} setpassword={setrePassword} newPassword={newPassword} setNewPassword={setnewPassword} setsessionUpdate={setsessionUpdate} sessions={sessionUpdate} title='Account Security' handleSubmit={handleSubmit} setformState={setformState as any} />
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" fullWidth>
            <div className={styles.avatarPersonalAccountCard}>
              <p className={styles.avatarPersonalAccountCard_title}>Change Password</p>
              <p className={styles.avatarPersonalAccountCard_text}>Choose a new password that you don’t use elsewhere, you'll receive an email to verify this change. Change your password anytime you think it might have been compromised.</p>
              <div className={styles.personalInfosInputs}>
                <div className={styles.primaryEmailPersonalInfo}>
                  <TextField InputProps={{ sx: { fontSize: 14 } }} type="password" name='password' value={formState.password} onChange={handleChange} id="standard-basic" label="Current password" variant="standard" />

                </div>
                <div className={styles.primaryEmailPersonalInfo}>
                  <TextField InputProps={{ sx: { fontSize: 14 } }} type='password' name='newPassword' value={formState.newPassword} onChange={handleChange} id="standard-basic" label="New password" variant="standard" />

                </div>
              </div>
            </div>
            {/* <div  style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                      <p className={styles.avatarPersonalAccountCard_title}>Two-step verification</p>
                      <p className={styles.avatarPersonalAccountCard_text}>
                      Secure your account by requiring an additional authentication method every time you sign-in. Receive sign-in verification codes by email or text message. If we detect an unusual login, we may email you a code to verify your identity even if you aren’t enrolled in two-step verification.
                      </p>
                      
                      <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                        <div className={styles.personalInfosInputs}>
                          <p className={styles.avatarPersonalAccountCard_text}>Verify with email</p>
                          <Switch checked={formState.verifyWithEmail} onChange={handleChange} name='verifyWithEmail'/>
                        </div>
                        <div className={styles.personalInfosInputs}>
                          <p className={styles.avatarPersonalAccountCard_text}>Verify with text messages (SMS)</p>
                          <Switch checked={formState.verifyWithSms} onChange={handleChange} name='verifyWithSms'/>
                        </div>
                      </div>
                      
                  </div> */}
            <div style={{ marginTop: 24 }} className={styles.avatarPersonalAccountCard}>
              <p className={styles.avatarPersonalAccountCard_title}>Sessions History</p>
              <p className={styles.avatarPersonalAccountCard_text}>The browsers and locations from which you have signed in recently are listed below. If you see unfamiliar browser or locations, change your password immediately and contact us.</p>
              <div className={styles.preferenceAccountInfo}>
                <table className={styles.table} style={{ paddingTop: 16 }}>
                  <thead>
                    <tr className={styles.tableHead}>
                      <th style={{ paddingLeft: 8 }}>Location</th>
                      <th style={{ paddingLeft: 8, width: 130 }}>Browser</th>
                      <th style={{ paddingLeft: 8, width: 180 }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formState.sessions.map((session) =>
                        <tr className={styles.tableActivityRow}>
                          <td className={styles.tableCell}>{session.location} ({session.ipAddress})</td>
                          <td className={styles.tableCell}>{session.browser}</td>
                          <td className={styles.tableCell} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey" />
                            <p>{updateDateDisplay(session.created_at)}</p>
                          </td>
                          <td className={styles.tableCell} >
                            <FontAwesomeIcon onClick={() => handleDeleteSession(session.id)} style={{ cursor: 'pointer' }} icon={faTrash} size='1x' />
                          </td>
                        </tr>)
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </FormControl>
        </form>
      </div>
      <ErrorNotification
        severity={msgColor}
        open={openNotification}
        message={msg}
        handleClose={handleCloseNotification}
      />
    </div>
  )
}

export default Security