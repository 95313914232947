import React, { FormEvent, useEffect, useRef, useState } from 'react'
import styles from '../account.module.scss'
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest, sendInviteLinkRequest } from '../../../core/actions/userActions'
import { GET_WATCH_LIST, OtpRequest, Roles, SecurityPage, Status, UserEntrepriseReq, UserInfo, WatchListAssetsReq, WatchListBinReq, WatchListBrandKeyWordReq, WatchListDomainReq, WatchListExecutiveReq, WatchListGroupReq, WatchListIpReq } from '../../../core/constants'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../core/reducers/userReducer'
import { Button, Drawer, MenuItem, Select, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { saveUserEntrepriseRequest } from '../../../core/actions/entrepriseActions'
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer'
import { typesHandleChange } from '../constants'
import { authSelector } from '../../../core/reducers/authReducer'
import ErrorNotification from '../common/ErrorNotification'
import { saveWatchListBinReq, saveWatchListBrandKeywordReq, saveWatchListDomainReq, saveWatchListExecutiveReq, saveWatchListIpReq, saveWatchListRansomeReq, setWatchListAssetsReq } from '../../../core/actions/watchListActions'
import * as XLSX from 'xlsx';
import { SaveWatchListBulkImportApi } from '../../../core/api/watchListApi'


interface props {
  title: any;
  goBack: any;
  selectType: string;
  open: boolean;
  setOpen: any
}

type ExcelDataRowAssets = {
  vendor: string;
  product: string;
  version: string;
}

type ExcelDataRowBrandKeywords = {
  keyword: string;
}

type ExcelDataRowDomains = {
  domaine: string;
}

type ExcelDataRowIps = {
  ip: string;
}

type ExcelDataRowBins = {
  bin: string;
}

type ExcelDataRowExecutive = {
  fullName:string;
  email: string;
  username: string;
}

type ExcelDataRowRansomes = {
  group: string;
}


type ExcelDataRowTypes = 
  ExcelDataRowAssets
  | ExcelDataRowBrandKeywords
  | ExcelDataRowDomains
  | ExcelDataRowIps
  | ExcelDataRowBins
  | ExcelDataRowExecutive
  | ExcelDataRowRansomes

function HeaderWatchList({ title, goBack,selectType, open, setOpen}:props) {
  const dispatch = useDispatch()
  const [shouldUpdate, setshouldUpdate] = useState<boolean>(false)
  
  const { auth } = useSelector(authSelector)
  const [emails, setEmails] = useState(['']);
  const [categorie, setcategorie] = useState(selectType)
  const [items, setItems] = useState<ExcelDataRowTypes[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openNotification, setopenNotification] = useState(false)
  const [openNotifBuild, setopenNotifBuild] = useState(false)
  const [messageBuilk, setmessageBuilk] = useState("")
  const { roleUser } = useSelector(userSelector)
  const [watchListAsset, setwatchListAsset] = useState<WatchListAssetsReq>({
    version: '',
    product: '',
    assetsStartDate: '',
    vendor: ''
  })
  const [watchListBrandKeyword, setwatchListBrandKeyword] = useState<WatchListBrandKeyWordReq>({
    keyword: '',
    status: Status.INREVIEW
  })
  const [watchListDomain, setwatchListDomain] = useState<WatchListDomainReq>({
    domain: '',
    status: Status.INREVIEW
  })
  const [watchListIp, setwatchListIp] = useState<WatchListIpReq>({
    ip: '',
    status: Status.INREVIEW
  })
  const [watchListBin, setwatchListBin] = useState<WatchListBinReq>({
    bin: '',
    status: Status.INREVIEW
  })
  const [watchListExecutive, setwatchListExecutive] = useState<WatchListExecutiveReq>({
    fullName: '',
    email: '',
    executiveStatus: Status.INREVIEW,
    username: ''
  })
  const [watchListRansome, setwatchListRansome] = useState<WatchListGroupReq>({
    group: '',
    status: Status.APPROUVED
  })

  const handleCloseNotification = () => {
    setopenNotification(false)
}

const handleCloseNotificationBuild = () => {
  setopenNotifBuild(false)
}

    useEffect(() => {
      setcategorie(selectType)
    }, [selectType])
    
    
  const handleCancel = () => {
    dispatch(getUserInfoRequest())
    setshouldUpdate(!shouldUpdate)
  }
  const rolesEntries = Object.entries(Roles).filter(([key, value]) => 
    auth.role === Roles.boClient ?  
        value !== Roles.boAdmin && 
        value !== Roles.boResellerAnalyst && 
        value !== Roles.boResellerManager
    : value !== Roles.boAdmin
    );

  const handleSubmit = () => { 
    if(categorie === 'assets') {
      console.log(watchListAsset)
      dispatch(setWatchListAssetsReq(watchListAsset))
    }
    else if(categorie === 'brandKeyword') {
      dispatch(saveWatchListBrandKeywordReq(watchListBrandKeyword))
    } else if(categorie === 'domains') {
      dispatch(saveWatchListDomainReq(watchListDomain))
    } else if(categorie === 'ips') {
      dispatch(saveWatchListIpReq(watchListIp))
    } else if(categorie === 'bins') {
      dispatch(saveWatchListBinReq(watchListBin))
    } else if(categorie === 'executives') {
      dispatch(saveWatchListExecutiveReq(watchListExecutive))
    }
    else if(categorie === 'ransomGroups') {
      dispatch(saveWatchListRansomeReq(watchListRansome))
    }

    setTimeout(() => {
      dispatch({type: GET_WATCH_LIST})
    }, 500);
    setOpen(!open)
};

const handleChange = (event: typesHandleChange) => {
  const {name, value} = event.target
  if(categorie === 'assets') {
    setwatchListAsset(prevState => ({
      ...prevState,
      [name]: value,
      }));
  } else if(categorie === 'brandKeyword') {
      setwatchListBrandKeyword(prevState => ({
      ...prevState,
      [name]: value,
      }));
  } else if(categorie === 'domains') {
    setwatchListDomain(prevState => ({
    ...prevState,
    [name]: value,
    }));
  } else if(categorie === 'ips') {
    setwatchListIp(prevState => ({
    ...prevState,
    [name]: value,
    }));
  } else if(categorie === 'bins') {
    setwatchListBin(prevState => ({
    ...prevState,
    [name]: value,
    }));
  } else if(categorie === 'executives') {
    setwatchListExecutive(prevState => ({
    ...prevState,
    [name]: value,
    }));
  }
  else if(categorie === 'ransomGroups') {
    setwatchListRansome(prevState => ({
    ...prevState,
    [name]: value,
    }));
  }
}

const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Determine the type of data by checking headers
        if (json.length > 0) {
          const headers = json[0] as string[];
          if (headers.includes('vendor') && headers.includes('product') && headers.includes('version') && headers.length === 3) {
            const data =  XLSX.utils.sheet_to_json<ExcelDataRowAssets>(worksheet).map(item => ({...item, assetsStartDate: new Date().toISOString()}))
            SaveWatchListBulkImportApi("assets",data)
            setmessageBuilk("Assets added successfully")
            setopenNotifBuild(true)
          } else if (headers.includes('keyword') && headers.length === 1) {
            const data = XLSX.utils.sheet_to_json<ExcelDataRowBrandKeywords>(worksheet).map(item => ({...item, status:Status.INREVIEW}))
            SaveWatchListBulkImportApi("brandKeyword",data)
            setmessageBuilk("Keywords added successfully")
            setopenNotifBuild(true)
          } else if (headers.includes('domaine') && headers.length === 1) {
            SaveWatchListBulkImportApi("domains", XLSX.utils.sheet_to_json<ExcelDataRowDomains>(worksheet))
            setmessageBuilk("Domains added successfully")
            setopenNotifBuild(true)
          } else if (headers.includes('ip')&& headers.length === 1) {
            const data = XLSX.utils.sheet_to_json<ExcelDataRowIps>(worksheet).map(item => ({...item, status: Status.INREVIEW}))
            SaveWatchListBulkImportApi("ips", data)
            setmessageBuilk("IPs added successfully")
            setopenNotifBuild(true)
          } else if (headers.includes('bin')&& headers.length === 1) {
            const data = XLSX.utils.sheet_to_json<ExcelDataRowBins>(worksheet).map(item => ({...item, status: Status.INREVIEW}))
            SaveWatchListBulkImportApi("bins", data)
            setmessageBuilk("BINs added successfully")
            setopenNotifBuild(true)
          } else if (headers.includes('group') && headers.length === 1) { // To differentiate from Bins
            SaveWatchListBulkImportApi("ransomGroups",XLSX.utils.sheet_to_json<ExcelDataRowRansomes>(worksheet))
            setmessageBuilk("Ransom Group is now monitored")
            setopenNotifBuild(true)
          } else if (headers.includes('fullName') && headers.includes('email') && headers.includes('username')&& headers.length === 3) {
            const data = XLSX.utils.sheet_to_json<ExcelDataRowExecutive>(worksheet).map(item => ({...item, executiveStatus: Status.INREVIEW}))
            SaveWatchListBulkImportApi('executives',data )
            setmessageBuilk("Executive added successfully")
            setopenNotifBuild(true)
          } else {
            setmessageBuilk("Unknown file type or headers")
            setopenNotifBuild(true)
          }
        }
    };
    reader.readAsBinaryString(file);
    setTimeout(() => {
      dispatch({type: GET_WATCH_LIST})
    }, 500);
  }
};  

const handleButtonClick = () => {
  fileInputRef.current?.click(); // Trigger the hidden file input click
};


const renderAssetsFieldsComponent = () => {
   return <>
        <TextField
            name={`vendor`}
            value={watchListAsset.vendor}
            id={`standard-basic`}
            onChange={handleChange}
            label='Vendor'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
          <TextField
          onChange={handleChange}
            name={`product`}
            value={watchListAsset.product}
            id={`standard-basic`}
            label='Product'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            name={`version`}
            onChange={handleChange}
            value={watchListAsset.version}
            id={`standard-basic`}
            label='Version'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderBrandKeyWordsFieldsComponent = () => {
    return <>
        <TextField
            name={`keyword`}
            value={watchListBrandKeyword.keyword}
            onChange={handleChange}
            id={`standard-basic`}
            label='Keyword'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderDomainFieldsComponent = () => {
    return <>
        <TextField
            name={`domain`}
            value={watchListDomain.domain}
            onChange={handleChange}
            id={`standard-basic`}
            label='Domain'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderIpsFieldsComponent = () => {
    return <>
        <TextField
            name={`ip`}
            value={watchListIp.ip}
            onChange={handleChange}
            id={`standard-basic`}
            label='IP Adress'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderBinsFieldsComponent = () => {
    return <>
          <TextField
            name={`bin`}
            value={watchListBin.bin}
            onChange={handleChange}
            id={`standard-basic`}
            label='BIN'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

console.log(items)

const renderExecutiveFieldsComponent = () => {
    return <>
        <TextField
            name={`fullName`}
            value={watchListExecutive.fullName}
            onChange={handleChange}
            id={`standard-basic`}
            label='Full Name'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            name={`email`}
            value={watchListExecutive.email}
            onChange={handleChange}
            id={`standard-basic`}
            label='Email'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            name={`username`}
            id={`standard-basic`}
            onChange={handleChange}
            value={watchListExecutive.username}
            label='Username'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderRansomGroupsFieldsComponent = () => {
    return <>
        <TextField
            name={`group`}
            value={watchListRansome.group}
            onChange={handleChange}
            id={`standard-basic`}
            label='Group'
            variant="standard"
            style={{ marginBottom: '10px' }}
          />
    </>
}

const renderFielsComponent = () => {
    switch (categorie) {
        case 'assets':
            return renderAssetsFieldsComponent()
        case 'brandKeyword':
            return renderBrandKeyWordsFieldsComponent()
        case 'domains':
            return renderDomainFieldsComponent()
        case 'ips':
            return renderIpsFieldsComponent()
        case 'bins':
            return renderBinsFieldsComponent()
        case 'executives':
            return renderExecutiveFieldsComponent()
        case 'ransomGroups':
            return renderRansomGroupsFieldsComponent()
        default:
            return null;
    }
}
  return (
    <form onSubmit={(event)=> event.preventDefault()} className={styles.personalInfoHeader}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            {goBack}
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>{title}</p>
        </div>
       {  roleUser?.companySettings && <div className={styles.personnalInfoHeader_secondSection}>
            <button onClick={()=>setOpen(!open)}  className={styles.personnalInfoHeader_secondSection_Save}>Add Indicator</button>
        </div>}
        
            <Drawer sx={{
    '& .MuiDrawer-paper': {
      backgroundColor: '#1a1a1a', // Drawer background color
      color: 'white', // Text color for contents
      width: '500px',
      padding: '54px 32px'
    },
  }} open={open} onClose={()=>setOpen(!open)} anchor='right'>
            <FontAwesomeIcon icon={faClose} style={{height:20, width:20, cursor: "pointer"}} onClick={()=>setOpen(!open)}/>
            <p className={styles.addTitleUser}>Add {
              categorie ===  "assets" ? "an Asset":
              categorie === "brandKeyword" ? "Brand Keyword":
              categorie === "domains" ? "Domain":
              categorie === "ips" ? "IP" :
              categorie === "bins" ? "BIN" :
              categorie === "executives" ? "Executive":
              categorie === "ransomGroups" ? "Ransom Group" : "Indicator"

            }</p>
            <span className={styles.textAddUser}>Add an indicator to one of your watchlists to monitor for leaks, vulnerabilities, of Ransom Group Activity.</span>
                <Select IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginRight: '20px' }} />} style={{ backgroundColor:"transparent", fontSize: 14}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={categorie}
                        onChange={(e)=>setcategorie(e.target.value)}
                        className={styles.selectPreferences}
                        
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              backgroundColor: '#1f1f1f',
                              borderRadius: '8px',
                              padding: '0px',
                              boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                              maxHeight: '360px',
                              overflowY: 'auto',
                            },
                          },
                          MenuListProps: {
                            dense: false,
                            disablePadding: true,
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}

                    >
                        <MenuItem value={'assets'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Assets</MenuItem>
                        <MenuItem value={'bins'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>BINs</MenuItem>
                        <MenuItem value={'brandKeyword'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Brand Keywords</MenuItem>
                        <MenuItem value={'domains'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Domains</MenuItem>
                        <MenuItem value={'executives'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Executives</MenuItem>
                        <MenuItem value={'ips'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>IPs</MenuItem>
                        <MenuItem value={'ransomGroups'} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>Ransom Groups</MenuItem>
                    </Select>

                    {
                        renderFielsComponent()
                    }
              <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop:15}} >
                  <div onClick={handleButtonClick} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <FontAwesomeIcon icon={faPlus} style={{fontSize:12, color: "#afafaf", paddingTop:5}} />
                    <p style={{color: "white",fontSize: 14}}>Add from file</p><p style={{color: "#c0c0c0",fontSize: 14}}>(Formats supported: .xlsx and .xls)</p>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }} // Hide the file input
                    accept=".xlsx,.xls"
                    onChange={handleFileUpload}
                    />
              </div>
            
            <div className={styles.saveUser}>
            <div className={styles.bottomAdd}>
             
              <Button onClick={handleSubmit} type='submit' style={{width:'117px', height:'40px', fontWeight:'500', fontSize:14}} >Add Indicator</Button>
            </div>
        </div>
        </Drawer>
        <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'url is copied successfully '}
                handleClose={handleCloseNotification}
            />

      <ErrorNotification
                severity={messageBuilk.includes("Unknown file type or headers")?"danger":"success"}
                open={openNotifBuild}
                message={messageBuilk}
                handleClose={handleCloseNotificationBuild}
            />
        </form>
  )
}

export default HeaderWatchList