import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ChartHome from '../components/ChartHome'
import CriticalRiskBar from '../components/CriticalRiskBar'
import Piechart from '../components/Piechart'
import styles from '../dashboard.module.scss'
import ReactGA from 'react-ga4';
import PieChartRansomeGroups from '../components/PieChartRansomeGroups'
import { RansomeOverview, REQUEST_RANSOME_OVERVIEW } from '../../../core/constants'
import { getRansomeOverviexApi } from '../../../core/api/ransomeApi'
import { setNamePageLocalStorage } from '../../../core/actions/stepsNavPageActions'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import ChartHomeRansome from '../components/ChartHomeRansome'
import EmptyStateLogo from "../../../icons/States/Emptystate.svg"
import { CircularProgress } from '@mui/material'
import { cp } from 'fs'
import { useDispatch } from 'react-redux'
import moment from 'moment'

interface props {
    setStepSetAll: any
}
function OverViewSection({ setStepSetAll }: props) {
    const dispatch = useDispatch()
    const { overview, isLoadingOveriview, victims, groups } = useSelector(ransomeSelector)
    const [isRender, setisRender] = useState(false)

    type DataPoint = {
        date: string;
        victims: number;
    };

    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const threeMonthsInMilliseconds = 90 * 24 * 60 * 60 * 1000;



    const filterDataByPeriod = (data: DataPoint[]): DataPoint[] => {
        if (data.length < 2) return data;

        // Calculate the year 5 years ago
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 3;

        const sortedData = data
            .filter(item => new Date(item.date).getFullYear() >= minYear)
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        if (sortedData.length < 2) return sortedData;

        const startDate = new Date(sortedData[0].date).getTime();
        const endDate = new Date(sortedData[sortedData.length - 1].date).getTime();
        const range = endDate - startDate;

        if (range <= sevenDaysInMilliseconds) {
            // console.log("Applying 7-day filter");
            return sortedData;
        } else if (range <= oneMonthInMilliseconds) {
            // console.log("Applying 1-month filter");
            return sortedData.filter((_, index) => index % Math.ceil(data.length / 7) === 0);
        } else if (range <= threeMonthsInMilliseconds) {
            // console.log("Applying 3-month filter");
            return sortedData.filter((_, index) => index % Math.ceil(data.length / 12) === 0);
        } else {
            // console.log("Applying default filter");
            const monthlyData: Record<string, number> = {};

            sortedData.forEach(item => {
                const monthKey = moment(item.date).format("YYYY-MM");
                if (!monthlyData[monthKey]) {
                    monthlyData[monthKey] = 0;
                }
                monthlyData[monthKey] += item.victims;
            });

            return Object.entries(monthlyData).map(([month, victims]) => ({
                date: moment(month, "YYYY-MM").format("MMMM YYYY"),
                victims,
            }));
        }
    };


    useEffect(() => {
        document.title = 'Ransomware Overview'
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        if (!overview) {
            dispatch({
                type: REQUEST_RANSOME_OVERVIEW
            })
        }
    }, [])
    // console.log(overview?.ransomeGroupsPosts)

    const dataGlobalVictims = overview?.globalVictims
        ?.map(item => ({
            date: moment(item.publish_date).format("MMM DD, YYYY"),
            victims: Number(item.nbr_of_victims)
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const ransomFilteredData = filterDataByPeriod(dataGlobalVictims || []);

    const setAll = (step: string) => {

        setStepSetAll(step)
    }

    const totalVictims = Number(overview?.targetCountries?.[0]?.nbr_of_victims)
        + Number(overview?.targetCountries?.[1]?.nbr_of_victims)
        + Number(overview?.targetCountries?.[2]?.nbr_of_victims)
        + Number(overview?.targetCountries?.[3]?.nbr_of_victims)
        + Number(overview?.targetCountries?.[4]?.nbr_of_victims)
    // console.log("total victims: "+totalVictims)
    // console.log

    const mergedData = overview?.ransomeGroupsPosts?.map(post => {
        const matchingGroup = overview?.groupsPost?.find(group => group.group_name === post.group_name);
        return {
            group_name: post.group_name,
            nbr: post.nbr,
            percent: matchingGroup?.percent || 0 // Use 0 if no match is found
        };
    });

    return (
        <>{
            !isLoadingOveriview ?

                <div className={styles.statsThreats}>
                    <div className={styles.chartHomeWrapper}>
                        <div className={styles.timelineContainer}>
                            <div style={{ marginBottom: 24 }}>
                                <span style={{ color: "white", marginLeft: 25 }}>Victims Timeline</span>
                            </div>
                            {
                                dataGlobalVictims?.length !== 0 ?
                                    <ChartHomeRansome data={ransomFilteredData} />
                                    :
                                    <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", height: "80%", justifyContent: "center" }}>
                                        <div>
                                            <p style={{ textAlign: "center", color: "#888888", fontSize: 12 }}>Data unavailable</p>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className={styles.vulnerabilityContainer}>
                            <div style={{ marginBottom: 24 }}>
                                <span style={{ color: "white" }}>Ransom Groups Notoriety</span>
                            </div>
                            {overview && <PieChartRansomeGroups overview={overview} />}
                        </div>
                    </div>
                    <div className={styles.threatAndMentionsBreakdown}>
                        <div className={styles.threatBreakdown}>
                            <div className={styles.recentThreats_Header}>
                                <span style={{ color: "white" }}>Targeted Countries</span>
                                <div className={styles.seeAll} onClick={() => setAll("countries")}>
                                    <span>See all</span>
                                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 9 }} />
                                </div>
                            </div>
                            <div className={styles.riskProgressBar}>
                                {
                                    overview?.targetCountries?.map(item =>
                                        <CriticalRiskBar total={Number(item?.nbr_of_victims)} value={(Number(item?.nbr_of_victims) / (totalVictims ? totalVictims : 1)) * 100} title={item?.country} />
                                    )
                                }
                            </div>
                        </div>
                        <div className={styles.mentionsBreakdown}>
                            <div className={styles.recentThreats_Header}>
                                <span style={{ color: "white" }}>Ransom Groups Posts</span>
                                <div className={styles.seeAll} onClick={() => setAll('groups')}>
                                    <span>See all</span>
                                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 9 }} />
                                </div>
                            </div>
                            <div className={styles.riskProgressBar}>
                                {
                                    mergedData?.map(item =>
                                        item?.group_name !== "Others" && <CriticalRiskBar total={Number(item?.nbr)} value={Number(item?.percent)} title={item?.group_name} />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
        }</>
    )
}

export default OverViewSection
