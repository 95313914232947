import React, { useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { GET_WATCH_LIST, GET_WATCH_LIST_BRAND_KEYWORD, WatchListBrandKeyWord } from '../../../core/constants'
import EmptyStateWatchList from '../../Dashboard/common/EmptyStateWatchList'
import ErrorNotification from '../common/ErrorNotification'
import { DeleteWatchListBrandsKeywordApi } from '../../../core/api/watchListApi'
import { CircularProgress } from '@mui/material'
import { userSelector } from '../../../core/reducers/userReducer'

interface props {
  open: boolean;
  setOpen: any;
}


function BrandKeywordsWatchList({open, setOpen}: props) {
  const dispatch = useDispatch()
  const { watchListBrandKeyword, isLoadingKeyword } = useSelector(watchListSelector)
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [notifMessage, setnotifMessage] = useState<string>("")
  const [openNotif, setOpenNotif] = useState(false)
  const { roleUser } = useSelector(userSelector)
    const handleCheckboxChange = (id: any) => {
        setSelectedAssets((prev: any) => {
            if (prev.includes(id)) {
                return prev.filter((item: any) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };
  const deleteKeywords = async () => {
    const { message } = await DeleteWatchListBrandsKeywordApi(selectedAssets)
    setnotifMessage(message)
    setOpenNotif(true)
    setTimeout(() => {
      dispatch({type: GET_WATCH_LIST_BRAND_KEYWORD})
      dispatch({type: GET_WATCH_LIST})
    }, 500);
}
  useEffect(() => {
    document.title ="Brand Watch list"
    dispatch({type: GET_WATCH_LIST_BRAND_KEYWORD})
  }, [])
  
  return (
    <>
      {
        !isLoadingKeyword ?
        watchListBrandKeyword && watchListBrandKeyword?.length > 0 ?
        <div className={styles.awaitingApproval}>
                        
                        <div className={styles.searchAndRemove}>
                        <div className={styles.searchAndIcon}>
                            <input className={styles.search} name='search' placeholder='Search'/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                        </div>
                        { roleUser?.companySettings && <div className={styles.removeSelected} onClick={deleteKeywords}>
                            <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                            <FontAwesomeIcon icon={faTrash} color='white' style={{fontSize: 12}} />
                        </div>}
                    </div>
                    <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}> </th>
                          <th style={{paddingLeft: 8}}>Keyword</th>
                          <th style={{paddingLeft: 8}}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          watchListBrandKeyword?.map((brand: WatchListBrandKeyWord)=>
                            <tr className={styles.tableActivityRowWatchList}>
                            <td style={{width: '32px'}} className={styles.tableCell}>
                            <input
                                type="checkbox"
                                className={styles.customCheckbox}
                                value={brand.id}
                                onChange={() => handleCheckboxChange(brand.id)}
                                checked={selectedAssets.includes(brand?.id)}
                            />
                            </td>
                              <td style={{width: '570px'}} className={styles.tableCell}>{brand.keyword}</td>

                              <td style={{width: '120px'}} className={styles.tableCell} >
                                <p style={{backgroundColor: "#e6e6e6",color:"#1a1a1a", textAlign:"center",verticalAlign:"middle", fontSize: 12, padding: '6px 4px',border:'none', borderRadius: 32,marginRight:20}} >{brand.status}</p>
                              </td>
                            </tr>
                          )
                        }
                        
                        </tbody>
                    </table>
                    <ErrorNotification 
                    severity="success"
                    open = {openNotif}
                    message={notifMessage}
                    handleClose={() => setOpenNotif(false)}
                    />
                      </div> 
                      :
                      <div className={styles.loaderVul}>
                      <EmptyStateWatchList 
                        open={open} 
                        setOpen={setOpen} 
                        title='Brand Keywords Watch list is empty'
                        addButtonText='Brand Keyword'
                        text='Add a keyword to start monitoring for cybersecurity risks'
                        />
                        </div>
                        :
                        <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
      }
    </>
  )
}

export default BrandKeywordsWatchList