import { call, put } from "redux-saga/effects";
import { getAllVulnerabilitiesAction, setVulDetailAction, successVulOverviewAction } from "../actions/vulnerabilitiesActions";
import { getAllVulnerabilitiesAPI, getOverviewVulAPI, getVulnerabilitieByCVEAPI } from "../api/vulnerabilityApi";
import { CVEItem, FAILED_VULNERABILITIES, FAILED_VULNERABILITIES_OVERVIEW, Page, SortVul, VulnerabilitiesAction, Vulnerability, VulnerabilityList } from "../constants";
import { AxiosError } from "axios";

export function* getAllVulnerabilitiesSaga(action: VulnerabilitiesAction) {
    try {
        
        const response:VulnerabilityList = yield call(getAllVulnerabilitiesAPI, action.payload as SortVul);
        yield put(getAllVulnerabilitiesAction(response))
    } catch (error) {
        yield put({
            type: FAILED_VULNERABILITIES
        })
    }
}

export function* getOverviewVulSaga(action: VulnerabilitiesAction) {
    try {
        
        const response:VulnerabilityList = yield call(getOverviewVulAPI, action.payload as string);
        yield put(successVulOverviewAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
            
        }
    }
}


export function* getVulnerabilitieByCVESaga(action: VulnerabilitiesAction) {
    try {
        const query = action.payload as string
        const response:Vulnerability = yield call(getVulnerabilitieByCVEAPI, query);
        yield put(setVulDetailAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}