import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, TextField } from '@mui/material'
import { LoginRequestAction, setNotificationAction } from '../../core/actions/authActions'
import { authSelector } from '../../core/reducers/authReducer'
import styles from './auth.module.scss'
import ErrorNotification from '../Account/common/ErrorNotification'
import logoSignSVG from '../../icons/Login-Illustration.svg'
import { useIntercom } from '../Dashboard/IntercomSetup'
import Logo from '../../icons/Defendis_Logo_White.svg'

function FirstTimeLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(authSelector);
    const { error, authenticated, loginEntreprise, errorCode, isLoadingLogin, jwt } = auth
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const [loading, setLoading] = useState(false)
    // Effect to update the document title and handle auth changes
    useEffect(() => {
        document.title = 'Sign In';
        if(authenticated && !loginEntreprise) {
            dispatch(setNotificationAction({
                error:'Contact your account manager to finish the Onboarding',
                errorCode: 400,
                success: true
             }))
             setOpenNotification(true)
        } else if(loading && loginEntreprise){
            navigate("/")
            setLoading(false);
        }
    }, [authenticated, loginEntreprise, jwt, error]);

    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = (window.localStorage.getItem("fullName") || "") + " " + (window.localStorage.getItem("name") || "");
    const profilePicture = window.localStorage.getItem("avatar")
  
    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed,
      avatar: profilePicture,
    })
    

    // Handle login action
    const login = () => {
        dispatch(LoginRequestAction({ email, password })); 
        setLoading(true)
        setTimeout(() => {
        dispatch(setNotificationAction({
            success: true,
            error: '',
            errorCode: null
          }))
          setLoading(false)
          setOpenNotification(false)
        }, 3500);
    };

    // Close notification handler
    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    return (
        <div style={{backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative'}}>
             <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
                <img src={Logo} className={styles.logo} alt="Defendis Logo" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh'}}>
                <div className={styles.login} style={{width: 'auto'}}>
                    <div style={{ padding:'4rem'}}>
                        <form onSubmit={e => e.preventDefault()}>  
                            <div className={styles.loginInputs} style={{marginTop: "-1em"}}>
                                <span className={styles.titleLogin}>Sign in</span>
                                <TextField InputProps={{sx: { fontSize: 14 }}}
                                    onChange={(e) => setEmail(e.target.value)} 
                                    id="email" 
                                    label="Work Email" 
                                    variant="standard"
                                    fullWidth 
                                    margin="normal" 
                                />
                                <TextField InputProps={{sx: { fontSize: 14 }}}
                                    onChange={(e) => setPassword(e.target.value)} 
                                    id="password" 
                                    label="Password" 
                                    variant="standard" 
                                    type="password" 
                                    fullWidth 
                                    margin="normal" 
                                />
                                <NavLink 
                                    to="/forgotPassword" 
                                    style={{ marginTop: 0, float: 'right'}} 
                                    className={styles.infoText}
                                >
                                    Forgot Password?
                                </NavLink>
                                <Button 
                                    onClick={login} 
                                    type="submit" 
                                    disabled = {isLoadingLogin}
                                    className={styles.btnLogin} 
                                    style={{ marginTop: 48, fontSize: 14, fontWeight: 500, cursor: isLoadingLogin ? "progress":"pointer" }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div style={{ paddingTop: '2rem', display: window.innerWidth < 768 ? 'none' : 'block'}}>
                        <img src={logoSignSVG} alt="Illustration"/> 
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center'}}>
                <span className={styles.loginFooterText}>© 2024 Defendis Technology Inc.</span>
                <a href="https://defendis.ai/terms" className={styles.loginFooterText} target="_blank">
                    <span>Terms</span>
                </a>
                <a href="https://defendis.ai/privacy" className={styles.loginFooterText} target="_blank">
                    <span>Privacy Policy</span>
                </a>
                <a href="https://help.defendis.ai" className={styles.loginFooterText} target="_blank">
                    <span>Support</span>
                </a>
            </div>
            {openNotification && auth.error !== "" && auth.errorCode && (
                <ErrorNotification
                    severity={errorCode !== 200 ? 'error' : 'success'}
                    open={openNotification}
                    message={error}
                    handleClose={handleCloseNotification}
                />
            )}
        </div>
    );
}

export default FirstTimeLogin;
