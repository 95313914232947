import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../../../dashboard.module.scss'

export default function Hardware({user}:any) {

    useEffect(() => {
        document.title = "User Profile - Hardware"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, [])
    

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', marginTop: 16}}>
        <div style={{ padding: "4px 16px 16px 16px", borderRadius: 8, border: "1px solid #333333"}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p style={{color: 'white', fontSize: 14, fontWeight: '700'}}>{user?.hwid}</p>
                    </div>
                    <div style={{marginTop:18}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                        <span  style={{color: 'white', fontSize: 14, width: 72}}>IP</span>
                        <span style={{color: '#afafaf', fontSize: 13}}>{user?.ipaddress}</span>
                    </div>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', marginTop: 4}}>
                        <span  style={{color: 'white', fontSize: 14, width: 72}}>Location</span>
                        <span style={{color: '#afafaf', fontSize: 13}}>{user?.location} {user?.country}</span>
                    
                    </div> 
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 32}}>
                                <div>
                                <span  style={{color: '#afafaf', fontSize: 12}}>{user?.username}</span>
                                </div>
                                <div>
                                <span style={{color: '#afafaf', fontSize: 12}}>{user?.operatingsystem}</span>
                                </div>
                            </div>
         </div>       
    </div>
    )
}
