import { faArrowLeft, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../common/Header'
import Menu from '../../common/Menu'
import styles from '../../dashboard.module.scss'
import Antivirus from './Antivirus'
import Banking from './Banking'
import Credentials from './Credentials'
import DataLeak from './DataLeak'
import Hardware from './Hardware'
import IdentityDoc from './IdentityDoc'
import MalwarePath from './MalwarePath'
import Software from './Software'
import { getEmailApi } from '../../../../core/api/threatIntelApi'

export default function UserProfile() {
    const navigate = useNavigate();
    const [activeCompo, setactiveCompo] = useState('hardware')

    
    

    const renderSections = () => {
        switch (activeCompo) {
            case 'credentials':
                return <Credentials/>
            case 'banking':
                return <Banking/>
            case 'identityDoc':
                return <IdentityDoc/>
            case 'hardware':
                return <Hardware/>
            case 'software':
                return <Software/>
            case 'antivirus':
                return <Antivirus/>
            case 'malwarePath':
                return <MalwarePath/>
            case 'dataLeak':
                return <DataLeak/>
            default:
                return;
        }
    }
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
                <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/threatIntel/creditCardDetail")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <div>
                        <span>Go Back / USE-2024-0913</span>
                        </div>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span style={{fontSize: 20, fontWeight: "600"}}>Michael Scott</span>
                    </div>  
                </div>
                    {/* <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <Button style={{width: '120px', height: '40px', gap: 10, fontSize:12, fontWeight:400, backgroundColor: "white", display: 'flex', alignItems: "center"}}>
                        User Profile
                    </Button>
                        </div> */}
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14, marginTop: 30}}>
                    <p style={{color: "#afafaf"}}>Last updated</p>
                    <p style={{color: "white"}}>January 11, 2024</p>
                    <p style={{color: "#afafaf"}}>Type</p>
                    <div className={styles.profileHeader}>
                        <img src="/logoProfile.png" className={styles.logoProfileHeader} />
                        <p style={{fontSize: 12}}>Executive</p>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                    <div>
                       <div className={styles.insightWrapper}>
                    <p className={styles.insight}>
                        <FontAwesomeIcon icon={faWandSparkles} height={12} width={12} color='#ce87bf'/>
                        Insight
                    </p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <p style={{color: '#cbcbcb', fontSize: 14}}>This is a high risk user. Credentials appeared in 4 data leaks in the last 2 months.</p>
                    </div> 
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <p style={{color: '#cbcbcb', fontSize: 14}}>Leaked passwords are easy to guess which puts user account at high risk of compromise.</p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <p style={{color: '#cbcbcb', fontSize: 14}}>Identity document ‘National ID’ linked with the name of this user has been detected in the dark web.</p>
                    </div>
                    </div>
                    
                    <div style={{backgroundColor: "#141414", width: '340px', padding: 8, borderRadius: 8}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500',width:'136px'}}>Email:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>m.scott@dundermifflin.com</p>
                        </div>
                        <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px',color: 'white', fontSize: 14, fontWeight: '500'}}>Phone Number:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>(+1) 917-374-1943</p>
                        </div>
                        <div style={{marginTop: 5, display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px', color: 'white', fontSize: 14, fontWeight: '500'}}>Gender:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>Male</p>
                        </div>
                        <div style={{ marginTop: 5,display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px',color: 'white', fontSize: 14, fontWeight: '500'}}>Age:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>45</p>
                        </div>
                        <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', gap: 40}}>
                            <p style={{width:'136px',color: 'white', fontSize: 14, fontWeight: '500'}}>Address:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>1725 Slough Avenue, Scranton, PA, USA</p>
                        </div>
                        <div style={{marginTop: 5,display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px', color: 'white', fontSize: 14, fontWeight: '500'}}>Social Media:</p>
                            <div style={{display: "flex", gap: 5, alignItems: 'center'}}>
                                <img src='/instagram.png' height={14} width={14}/>
                                <img src='/x.png' height={14} width={14}/>
                            </div>
                        </div>
                    </div>
                    
                    
                
                </div>
                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px' }} className={styles.firstSectionMenu}>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('hardware')} className={activeCompo === 'hardware' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Hardware</span>
                        <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('credentials')} className={activeCompo === 'credentials' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Credentials</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('banking')} className={activeCompo === 'banking' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Banking</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('dataLeak')} className={activeCompo === 'dataLeak' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Data Leaks</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('identityDoc')} className={activeCompo === 'identityDoc' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Identity Documents</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('software')} className={activeCompo === 'software' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Software</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('antivirus')} className={activeCompo === 'antivirus' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Antivirus</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('malwarePath')} className={activeCompo === 'malwarePath' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Malware Path</span>
                    </div>

                {
                    renderSections()
                }
            </div>
        </div>
    )
}
