import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import ChartHome from '../components/ChartHome'
import CriticalRiskBar from '../components/CriticalRiskBar'
import RecentThreats from '../components/RecentThreats'
import styles from '../dashboard.module.scss'
import { CVEItem, HomeViewStats, Vulnerability } from '../../../core/constants'
import Loader from "../../../icons/Loader.svg"
import { useSelector } from 'react-redux'
import { statsSelector } from '../../../core/selectors/statsSelector'
import LeakPersonnalBar from '../components/LeakPersonnalBar'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import PIeChartHomeTypeUser from '../components/PIeChartHomeTypeUser'
import EmptyStateLogo from "../../../icons/States/Emptystate.svg"
import CriticalBarVulBreakCategory from '../components/CriticalBarVulBreakCategory'
import dateFormats from '../dateFormats'
{/*import { Logtail as Browser } from "@logtail/browser"; */}


type User = {
    userType: 'External' | 'Executive' | 'Internal';
};

export default function Overview() {
    {/* const logger = new Browser("GA4Kxcru4QaxdrfE63bL4cA4") */}
    const { homeStats, isLoadingHome } = useSelector(statsSelector)
    const { credentialsLeak, databaseLeak, documentsLeak, creditsCard, } = useSelector(threatIntelSelector)

    const countUserTypes = (): Record<string, number> => {
        const counts = { External: 0, Executive: 0, Internal: 0 };

        credentialsLeak?.data.forEach(user => {
            if (user?.typeUser === 'External') {
                counts.External++;
            } else if (user?.typeUser === 'Executive') {
                counts.Executive++;
            } else if (user?.typeUser === 'Internal') {
                counts.Internal++;
            }
        });

        return counts;
    };

    const countRiskScores = (objects: any | undefined): Record<string, number> => {
        const counts = { Low: 0, Medium: 0, High: 0, Critical: 0, None: 0 };

        objects?.forEach((obj: any) => {
            const category = getRiskScore(obj?.total_base_score);
            counts[category]++;
        });

        return counts;
    };

    const getRiskScore = (riskScore: number) => {
        if (riskScore === undefined) return "None"
        else if (riskScore <= 3.9) return "Low";
        else if (riskScore >= 4.0 && riskScore <= 6.9) return "Medium";
        else if (riskScore >= 7.0 && riskScore <= 8.9) return "High";
        else return "Critical";
    }
    const getRiskScoreStyle = (riskScore: number) => {
        if (riskScore <= 3.9) return "LowStyle";
        else if (riskScore >= 4.0 && riskScore <= 6.9) return "MediumStyle";
        else if (riskScore >= 7.0 && riskScore <= 8.9) return "HighStyle";
        else return "CriticalStyle";
    }
    const riskCounts = countRiskScores(homeStats?.vulnerabilitites);

    // console.log(String(countUserTypes()))

    const data = homeStats?.credentialsTimeLine?.map(item =>
    ({
        date: moment(item?.date, dateFormats, true).format("MMMM DD, YYYY"),
        credentials: Number(item?.credentials),
    })
    )

    const totalCredentialsLeaks = Number(homeStats?.paymentCardsLeak?.[0]?.count)
        + Number(homeStats?.credentialsLeakCount)
        + Number(homeStats?.documentsLeak?.[0]?.count ? homeStats?.documentsLeak?.[0]?.count : 0)

    //start sorting timeline data
    type DataPoint = {
        date: string;
        credentials: number;
    };

    // Convert dates to timestamps after parsing for better sorting and validation
    const dataGlobalCredentials: DataPoint[] = (homeStats?.credentialsTimeLine || [])
        .filter(item => {
            const parsedDate = moment(item?.date, dateFormats, true);
            return parsedDate.isValid() && parsedDate.year() >= 2005;
        })
        .map(item => {
            const parsedDate = moment(item?.date, dateFormats, true);
            return {
                date: parsedDate.format("MMM DD, YYYY HH:mm:ss"),
                credentials: Number(item?.credentials),
            };
        })
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const threeMonthsInMilliseconds = 90 * 24 * 60 * 60 * 1000;

    const filterDataByPeriod = (data: DataPoint[]): DataPoint[] => {
        if (data.length < 2) return data;

        const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        const startDate = new Date(sortedData[0].date).getTime();
        const endDate = new Date(sortedData[sortedData.length - 1].date).getTime();
        const range = endDate - startDate;

        // console.log("Start Date:", String(startDate));
        // console.log("End Date:", String(endDate));
        // console.log("Range (ms):", String(range));
        // console.log("Data Length:", String(data.length));

        const validData = data.filter(item => !isNaN(new Date(item.date).getTime()));
        if (validData.length !== data.length) {
            //console.error("Invalid dates found in data.");
        }

        if (range <= sevenDaysInMilliseconds) {
            // console.log("Applying 7-day filter");
            return data;
        } else if (range <= oneMonthInMilliseconds) {
            // console.log("Applying 1-month filter");
            return data.filter((_, index) => index % Math.ceil(data.length / 7) === 0);
        } else if (range <= threeMonthsInMilliseconds) {
            // console.log("Applying 3-month filter");
            return data.filter((_, index) => index % Math.ceil(data.length / 12) === 0);
        } else {
            // console.log("Applying default filter");
            const monthlyData: Record<string, number> = {};

        data.forEach(item => {
            const monthKey = moment(item.date).format("YYYY-MM");
            if (!monthlyData[monthKey]) {
                monthlyData[monthKey] = 0;
            }
            monthlyData[monthKey] += item.credentials;
        });

        return Object.entries(monthlyData).map(([month, credentials]) => ({
            date: moment(month, "YYYY-MM").format("MMMM YYYY"),
            credentials,
        }));
        }
    };



    const ransomFilteredData = filterDataByPeriod(dataGlobalCredentials);

    //end sorting timeline date


    return (
        <>
            {
                !isLoadingHome ?

                    <div className={styles.statsThreats}>
                        <div className={styles.cards}>
                            <div style={{ padding: '16px 25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className={`${styles.card} ${!isNaN(Number(homeStats?.totalRiskScoreHomeOverview)) && styles[getRiskScoreStyle(Number(homeStats?.totalRiskScoreHomeOverview))]}`}>
                                <span style={{ fontSize: 14, fontWeight: 500, }}>Total Risk score</span>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 0, paddingTop: 32, paddingRight: 0 }}>
                                    <span style={{ fontSize: 20, fontWeight: 600 }}>{isNaN(Number(homeStats?.totalRiskScoreHomeOverview)) ? 0 : Number(homeStats?.totalRiskScoreHomeOverview).toFixed(1)}</span>
                                    {
                                        !isNaN(Number(homeStats?.totalRiskScoreHomeOverview)) &&
                                        <span style={{ fontSize: 12 }}>
                                            {getRiskScore(Number(homeStats?.totalRiskScoreHomeOverview)) || 0}
                                        </span>
                                    }
                                </div>
                            </div>
                            <Card
                                text='Leaked Credentials'
                                num={Number(homeStats?.credentialsLeakCount) || 0}
                            //percent={-60.15}
                            />

                            <Card
                                text='Leaked Payment Cards'
                                num={Number(homeStats?.paymentCardsLeak?.[0]?.count) || 0}
                            //percent={+11.01}
                            />

                            <Card
                                text='Leaked Documents'
                                num={Number(homeStats?.documentsLeak?.[0]?.count) || 0}
                            // percent={-20.15}
                            />

                        </div>
                        <div className={styles.chartHomeWrapper}>
                            <div className={styles.timelineContainer}>
                                <div style={{ marginBottom: 24 }}>
                                    <span style={{ color: "white", marginLeft: 25 }}>Data Leaks Timeline</span>
                                </div>
                                {
                                    ransomFilteredData?.length !== 0 ? (
                                        <ChartHome data={ransomFilteredData} />
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", height: "80%", justifyContent: "center" }}>
                                            <div>
                                                <span style={{ textAlign: "center", color: "#888888", fontSize: 12 }}>Data unavailable</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={styles.vulnerabilityContainer}>
                                <div style={{ marginBottom: 24 }}>
                                    <span style={{ color: "white" }}>User Type Breakdown</span>
                                </div>
                                {
                                    !(Number(homeStats?.countUsersType?.[0]?.countexecutive) === 0
                                        && Number(homeStats?.countUsersType?.[0]?.countemployee) === 0
                                        && Number(homeStats?.countUsersType?.[0]?.countcustomer) === 0)
                                        ?
                                        <PIeChartHomeTypeUser
                                            Executives={Number(homeStats?.countUsersType?.[0]?.countexecutive ?? 0)}
                                            Internal={Number(homeStats?.countUsersType?.[0]?.countemployee ?? 0)}
                                            External={Number(homeStats?.countUsersType?.[0]?.countcustomer ?? 0)}
                                        /> :
                                        <div style={{ display: "flex", alignItems: "center", verticalAlign: "middle", height: "80%", justifyContent: "center" }}>
                                            <div>
                                                <span style={{ textAlign: "center", color: "#888888", fontSize: 12 }}>Data unavailable</span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={styles.threatAndMentionsBreakdown}>
                            <div className={styles.threatBreakdown}>
                                <div className={styles.recentThreats_Header}>
                                    <span style={{ color: "white" }}>Risk Breakdown </span>
                                </div>
                                <div className={styles.riskProgressBar}>
                                    <CriticalRiskBar total={Number(riskCounts?.Critical) - 1} value={homeStats ? ((-1 + riskCounts?.Critical) / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length !== 0 ? homeStats?.vulnerabilitites.length : 1)) * 100 : 0} title="Critical" />
                                    <CriticalRiskBar total={Number(riskCounts?.High)} value={homeStats ? (riskCounts?.High / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length !== 0 ? homeStats?.vulnerabilitites.length : 1)) * 100 : 0} title="High" />
                                    <CriticalRiskBar total={Number(riskCounts?.Medium)} value={homeStats ? (riskCounts?.Medium / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length !== 0 ? homeStats?.vulnerabilitites.length : 1)) * 100 : 0} title="Medium" />
                                    <CriticalRiskBar total={Number(riskCounts?.Low)} value={homeStats ? (riskCounts?.Low / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length !== 0 ? homeStats?.vulnerabilitites.length : 1)) * 100 : 0} title="Low" />
                                </div>
                            </div>
                            <div className={styles.mentionsBreakdown}>
                                <div className={styles.recentThreats_Header}>
                                    <span style={{ color: "white" }}>Data Leak Type</span>
                                </div>
                                <div className={styles.riskProgressBar} style={{ marginTop: 20 }}>
                                    <CriticalRiskBar total={Number(homeStats?.credentialsLeakCount)} value={homeStats ? (Number(homeStats?.credentialsLeakCount) / (totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks)) * 100 : 0} title="Credentials" />
                                    <CriticalRiskBar total={Number(homeStats?.paymentCardsLeak?.[0]?.count)} value={homeStats ? (Number(homeStats?.paymentCardsLeak?.[0]?.count) / (totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks)) * 100 : 0} title="Payment Cards" />
                                    <CriticalRiskBar total={Number(homeStats?.documentsLeak?.[0]?.count)} value={homeStats ? (Number(homeStats?.documentsLeak?.[0]?.count ? homeStats?.documentsLeak?.[0]?.count : 0) / (totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks)) * 100 : 0} title="Documents" />
                                    {/* <CriticalRiskBar total={(0)} value={homeStats ? (Number(homeStats?.vulnerabilitites ? homeStats?.vulnerabilitites.length : 0) / (homeStats?.totalVul === 0 ? 1 : homeStats?.totalVul)) * 100 : 0} title="Documents" /> */}
                                </div>
                            </div>
                        </div>

                    </div> :
                    <div className={styles.loaderVul}>
                        <CircularProgress />
                    </div>
            }
        </>

    )
}
