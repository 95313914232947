import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, easing } from '@mui/material';
import { LoginRequestAction } from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import styles from "./auth.module.scss";
import ErrorNotification from '../Account/common/ErrorNotification';
import { AddUserInviteLinkReq, Roles, UserEntrepriseReq } from '../../core/constants';
import { typesHandleChange } from '../Account/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { saveUserEntrepriseRequest } from '../../core/actions/entrepriseActions';
import ReactGA from 'react-ga4';
import Logo from '../../icons/Defendis_Logo_White.svg'
import logoSignSVG from '../../icons/Login-Illustration.svg'
import { userSelector } from '../../core/reducers/userReducer';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
      color: 'white !important'
    },
    validEmail: { 
        borderBottom: '1px solid green !important',
        color: 'white !important'
    }
  }),
);

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  

function InviteLink() {
    const classes = useStyles();
    const query = useQuery();
    const fullName = query.get('fullName');
   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const { message } = useSelector(userSelector)
    const [formState, setformState] = useState<UserEntrepriseReq>({
        firstname: "",
        lastname: '',
        username: '',
        jwt: localStorage.getItem('jwt') || ""
        
    })
    const [openNotification, setOpenNotification] = useState(false);
    const [error, seterror] = useState(false)

    // React to auth state changes


    const login = () => {
        dispatch(saveUserEntrepriseRequest(formState))
        setTimeout(() => {
          setOpenNotification(true);
        }, 1000);
    };

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    const handleChange = (event: typesHandleChange) => {
        const { name, value} = event.target;
        
          setformState(prevState => ({
            ...prevState,
            [name]: value,
          })); 
    }

    useEffect(() => {
      document.title = "Account Setup"
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      if(auth.loginEntreprise) {
        navigate("/")
      } else if(auth.authenticated) {
        navigate("/login")
      }
    }, [auth])
    
    // console.log(openNotification)
    
    return (
      <div style={{backgroundColor: '#141414', width: '100vw', height: '100vh', position: 'relative'}}>
      <div style={{ position: 'absolute', top: 0, left: 0, padding: '6px 0px' }}>
         <img src={Logo} className={styles.logo} alt="Defendis Logo" />
     </div>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '95vh'}}>
         <div className={styles.login} style={{width: 'auto'}}>
             <div style={{ paddingLeft:'4rem', paddingRight:'4rem'}}>
                 <form onSubmit={e => e.preventDefault()}>  
                     <div className={styles.loginInputs}>
                         <span className={styles.titleLogin}>Account Setup</span>
                         <TextField InputProps={{sx: { fontSize: 14 }}}
                             onChange={handleChange}
                             name="firstname"
                             value={formState.firstname}
                             id="standard-basic"
                             label="First Name" 
                             variant="standard"
                             fullWidth 
                             margin="normal"
                         />
                         <TextField InputProps={{sx: { fontSize: 14 }}}
                             onChange={handleChange}
                             name="lastname" 
                             value={formState.lastname}
                             id="standard-basic"
                             label="Last Name" 
                             variant="standard"
                             fullWidth 
                             margin="normal"
                         />
                         <TextField InputProps={{sx: { fontSize: 14 }}}
                             onChange={handleChange}
                             name="username"
                             value={formState.username}
                             id="standard-basic"
                             label="Username" 
                             variant="standard" 
                             fullWidth 
                             margin="normal" 
                         />
                         <Button 
                             onClick={login} 
                             type="submit" 
                             className={styles.btnLogin} 
                             style={{ marginTop: 48, fontSize: 14, fontWeight: 500 }}
                         >
                             Submit for Review
                         </Button>
                     </div>
                 </form>
             </div>
             <div style={{ paddingTop: '2rem'}}>
                 <img src={logoSignSVG} alt="Illustration"/> 
             </div>
         </div>
     </div>
     <div style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center'}}>
         <span className={styles.loginFooterText}>© 2024 Defendis Technology Inc.</span>
         <a href="https://defendis.ai/terms" className={styles.loginFooterText} target="_blank">
             <span>Terms</span>
         </a>
         <a href="https://defendis.ai/privacy" className={styles.loginFooterText} target="_blank">
             <span>Privacy Policy</span>
         </a>
         <a href="https://help.defendis.ai" className={styles.loginFooterText} target="_blank">
             <span>Support</span>
         </a>
     </div>
         <ErrorNotification
         severity={message.includes("Username is already taken") || message.includes("Username must be at least 5 characters") ? "error" : "success"}
         open={openNotification}
         message={message}
         handleClose={handleCloseNotification}
     />
 </div>
    );
}

export default InviteLink;

{/*

  <TextField onChange={handleChange} name='firstname' value={formState.firstname} id="standard-basic" label="First Name" variant="standard" fullWidth margin="normal"/>
<TextField  onChange={handleChange} name='lastname' value={formState.lastname} id="standard-basic" label="Last Name" variant="standard"  fullWidth margin="normal"/>                
<TextField  onChange={handleChange} name='username' value={formState.username} id="standard-basic" label="Username" variant="standard"  fullWidth margin="normal"/>                
                
<Button onClick={login} type='submit' className={styles.btnLogin}>Continue</Button>


                    <ErrorNotification
                severity={message.includes("Username already taken.") || message.includes("Username must meet length requirements.") ? "error" : "success"}
                open={openNotification}
                message={message}
                handleClose={handleCloseNotification}
            />
          </div>

*/}