import React from 'react'
import styles from './dashboard.module.scss'
import Header from './common/Header'
import Menu from './common/Menu'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material'
export default function ReportDetail() {
    const navigate = useNavigate()
  return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
        <div className={styles.content}>
            <div className={styles.personalInfoHeader}>
            <div >
                        <div onClick={()=>navigate("/reports")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <p>Go Back / REP-2024-0012</p>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>April weekly threat report - Week 3</span>
                    </div>  
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <Button style={{width: '120px', height: '3.70vh', gap: 10, fontSize:12, fontWeight:400, backgroundColor: "white", display: 'flex', alignItems: "center"}}>
                        Export
                    </Button>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14, marginTop: 30}}>
                    <p style={{color: "#afafaf"}}>Detected on</p>
                    <p style={{color: "white"}}>January 11, 2024</p>
                    <p style={{color: "#afafaf"}}>Type</p>
                    <div className={styles.profileHeader}>
                        <img src="/logoProfile.png" className={styles.logoProfileHeader} />
                        <p style={{fontSize: 12}}>Executive</p>
                    </div>
                </div>

                <div style={{backgroundColor: '#141414', borderRadius: 8, padding: 16}}>
                    <p style={{color:'#cbcbcb'}}>Defendis threat report provides a summary of new threats that have emerged in this period. Please note that some of the information in the report might be compiled from external sources and is not a direct result of Defendis analysis.</p>
                </div>


                <p style={{color: 'white', fontSize: 18, fontWeight: '600'}}>Threat Intelligence</p>

                <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Username
            </th>
            <th style={{paddingLeft: 8}}>
            Password
            </th>
            <th style={{paddingLeft: 8}}>
            Source
            </th>
            <th style={{paddingLeft: 8}}>
            Application
            </th> 
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowUsers}>
              <td style={{width: '314px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>m.scott@dundermifflin.com</p>
              </td>
              
              <td style={{width: '180px'}} className={styles.tableCell} >
                <p>HRct76*****</p>
              </td>
              <td style={{width: '314px'}} className={styles.tableCell} >
                  <p style={{fontSize: 14, color: '#cbcbcb'}}>
                  https://member.dundermifflin.com
                  </p>
              </td>
              <td style={{width:'210px'}} className={styles.tableCell}>
                <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center', backgroundColor: 'white', borderRadius: '50%', height:"22px", width:"22px"}}>
                         <img src="/chrome.png" alt="" height={28} width={36}/>
                    </div>
                    <p style={{color: 'white', fontSize: "14px"}}>Google Chrome</p>
                </div>
              </td>
            </tr>
            
          </tbody>
      </table>
        </div>
    </div>
  )
}
