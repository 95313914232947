import React from 'react'
import PieChartThreatManagement from '../components/PieChartThreatManagement'
import styles from '../dashboard.module.scss'
import CriticalRiskBar from '../components/CriticalRiskBar'
function AnalyticsSection() {
  return (
    <div style={{display:'flex', justifyContent: 'center', marginBottom: 25, marginTop: 20}}>
        <div>
        <div style={{marginBottom: 64}}>
        <div><span style={{color: "#cbcbcb", fontSize: 14, display: "flex", justifyContent: "center"}}>Threat Analytics</span></div>
        <div style={{marginTop:8}}>
        <span style={{color:'white', fontSize: 20, fontWeight: '600', display: "flex", justifyContent: "center" }}>Insights about your threats and team workload.</span>
        </div>
        </div>
        <div style={{display: 'flex', alignItems: "center", gap: 25}}>
            <div style={{backgroundColor: '#141414',width: 400,height:320, borderRadius:8, padding: 20}}>
                <span style={{fontSize:15, color: 'white', fontWeight: 500}}>Status Overview</span>
                <br/>
                <span style={{color: '#afafaf', fontSize: 14}}>A snapshot of the status of your threats.</span>
                <div style={{marginTop: 48}}>
                <PieChartThreatManagement/>
                </div>
            </div>
            <div style={{backgroundColor: '#141414', height: '320px', width: 400, borderRadius:8, padding: 20}}>
                <span style={{fontSize:15, color: 'white', fontWeight: 500}}>Risk Breakdown</span>
                <br/>
                <span style={{color: '#afafaf', fontSize: 14}}>A holistic view of your risk score.</span>
                
            </div>
        </div>
        <div style={{display: 'flex', alignItems: "center", gap: 25, marginTop: 25}}>
            <div style={{backgroundColor: '#141414', height: "290px", width: 400, borderRadius:8, padding: 20}}>
                <span style={{fontSize:15, color: 'white', fontWeight: 500}}>Team Workload</span>
                <br/>
                <span style={{color: '#afafaf', fontSize: 14}}>A summary of the capacity of your team.</span>
                <div className={styles.riskProgressBar}>
                    <CriticalRiskBar total={(0)} value={15} title="Critical risk" />
                    <CriticalRiskBar total={(0)} value={32} title="High risk" />
                    <CriticalRiskBar total={(0)} value={6} title="Medium risk" />
                    <CriticalRiskBar total={(0)} value={8} title="Low risk" />
                    <CriticalRiskBar total={(0)} value={32} title="None" />
                </div>
            </div>
            <div style={{backgroundColor: '#141414', height: "290px", width: 400, borderRadius:8, padding: 20}}>
                <span style={{fontSize:15, color: 'white', fontWeight: 500}}>Threats Breakdown</span>
                <br/>
                <span style={{color: '#afafaf', fontSize: 14}}>A breakdown of threats by their types.</span>
                <div className={styles.riskProgressBar}>
                    <CriticalRiskBar total={(0)} value={15} title="Critical risk" />
                    <CriticalRiskBar total={(0)} value={32} title="High risk" />
                    <CriticalRiskBar total={(0)} value={6} title="Medium risk" />
                    <CriticalRiskBar total={(0)} value={8} title="Low risk" />
                    <CriticalRiskBar total={(0)} value={32} title="None" />
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default AnalyticsSection