import React from 'react'
import styles from '../../../dashboard.module.scss'
export default function Antivirus({antivirus}: any) {
    return (
        <table className={styles.table} style={{marginTop:8}}>
           {
            antivirus?.map((item: any) =>
                <tr  className={styles.tableActivityRowRansom1Col}>
                <td style={{width: '100%', padding: '15px 16px', color: '#ffffff'}} className={styles.tableCell}>
                    {
                        item?.name
                    }
                </td>
              </tr>
            )
           }
      </table>
    )
}
