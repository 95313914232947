import React, {FormEvent, useState, useEffect} from 'react'
import Header from '../Dashboard/common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { Avatar, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircleCheck, faCircleXmark, faSearch, faTrash, faXmarkCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import {  getUserInfoRequest, updateSecurityInfoUserRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { UserInfo, SecurityPage, UsersEntreprise, Roles, UpdateRoleUserParams } from '../../core/constants'
import phonecodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import HeaderSecurity from './components/HeaderSecurity'
import HeaderUsers from './components/HeaderUsers'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import { approuveUserRequest, getUsersEntrepriseRequest } from '../../core/actions/entrepriseActions'
import { deleteUserApi, deleteUsersAPI, getUsersEntrepriseAPI } from '../../core/api/entrepriseApi'
import ReactGA from 'react-ga4';
import EmptyStateUsers from '../Dashboard/common/EmptyStateUsers'
import { updateRoleUserAPI } from '../../core/api/userApi'
import { supabaseClient } from '../../core/supabase'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
        borderBottom: '1px solid green !important',
    }
  }),
);

const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const data: any = useSelector(entrepriseSelector)
    const {auth} = useSelector(authSelector)
    const navigate = useNavigate()
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [formState, setformState] = useState<UsersEntreprise[]>();
    const [openNotification, setopenNotification] = useState(false)
    const [newPassword, setnewPassword] = useState<string>('')
    const [rePassword, setrePassword] = useState<string>('')
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [emailStorage, setemailStorage] = useState<string | undefined>()
    const [hmacStorage, sethmacStorage] = useState<string | undefined>()
    const [fullName, setfullName] = useState<string | undefined>()
    const [open, setOpen] = useState(false)
    const [msg, setmsg] = useState('')
    const [colorNotf, setcolorNotf] = useState('')
    const [openNotifRole, setopenNotifRole] = useState(false)
    const { userInfo, roles, roleUser } = useSelector(userSelector)
    const [search, setsearch] = useState("")
    const [appouvedSearch, setappouvedSearch] = useState("")
  console.log(selectedUserIds)
    const validatePassword = (password: string, retPassword: string): boolean => {
        return password !== retPassword
    };

    

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // dispatch(updateSecurityInfoUserRequest({
        //     security: formState
        // }))
        // setopenNotification(true)
    }
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }


    const deleteUser = async(id: number) => {
      await supabaseClient.from("users").delete().eq('id', id)
      // setnotifMessage(message)
      // setOpenNotif(true)
      dispatch(getUsersEntrepriseRequest())
    }
  
    
    const handleDeleteSession = async (sessionId: number) => {
      // Step 3: Call Backend API to delete the session
      // This is a placeholder function call. Replace it with your actual API call.
      // await deleteSessionAPI(sessionId);
    
      // Step 2: Update State on Deletion
      // Assuming each session object has a unique identifier such as `id`
    //   const updatedSessions = formState.sessions.filter(session => session.id !== sessionId);
    //   setformState({...formState, sessions: updatedSessions});
    
      // Optionally, show a notification to the user
      setopenNotification(true);
    };

    useEffect(() => {
      document.title = "Members Settings"
      ReactGA.send({ hitType: "Members Settings", page: window.location.pathname });

      
      
      return
    }, [data])

    
    const approuveUser = (id: number) => {
      dispatch(approuveUserRequest({
        userId: id
      }))
    }

    const deleteUsers = () => {
      if(selectedUserIds.length > 0) {
        deleteUsersAPI(selectedUserIds);
      dispatch(getUsersEntrepriseRequest())
      }
    }
    
    const handleSelectUser = (userId: number, isChecked: boolean) => {
      if (isChecked) {
        // Add the user ID to the selectedUserIds array if not already present
        setSelectedUserIds(prevSelectedUserIds => [...prevSelectedUserIds, userId]);
      } else {
        // Remove the user ID from the selectedUserIds array
        setSelectedUserIds(prevSelectedUserIds => prevSelectedUserIds.filter(id => id !== userId));
      }
    };
      console.log(data?.entreprise?.users)
    const userAwaiting = data?.entreprise?.users?.filter((user:UsersEntreprise) => user.email.toLowerCase().includes(search.toLowerCase()) && userInfo.email !== user.email && (user.firstname === null && user.lastname  === null))
    const usersApproved = data?.entreprise?.users?.filter((user:UsersEntreprise) => user.email.toLowerCase().includes(appouvedSearch.toLowerCase()) &&   userInfo.email !== user.email && ( user.firstname ||  user.lastname))
  
    console.log(usersApproved?.[0])

    const handleChange = async (userid: number, event: SelectChangeEvent, email: string) => {
      const role =  event.target.value
     const data: UpdateRoleUserParams = {
      role,
      userid
     }
     const resp = await updateRoleUserAPI(data)
     setTimeout(() => {
      dispatch(getUsersEntrepriseRequest())
     }, 500);
     if(resp === 'FAILED') {
      setmsg(`Failed to change role for "${email}".`)
      setcolorNotf('error')
     }else {
      setmsg(`Successfully changed role for "${email}".`)
      setcolorNotf('success')
     }
     setopenNotifRole(true)
    }


    const rolesData =  !(roleUser?.role === "God Mode" || roleUser?.role?.includes("Support")) 
    ? roles?.filter(item => item.role !== "God Mode" && !item.role?.includes("Support")) // Display all roles except "God Mode" and "Support"
    : roles; // Display all roles
  
    console.log(JSON.stringify(rolesData))
    const usersData = data?.entreprise?.users.filter((user:UsersEntreprise ) => user.email !== userInfo.email)
    return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderUsers title='Members' open={open} setOpen={setOpen}/>
                <form onSubmit={handleSubmit} >
                  <FormControl component="fieldset" fullWidth>

                    {
                      userAwaiting?.length > 0 ?
                      <div className={styles.avatarPersonalAccountCard} style= {{width: '100%'}}>
                        <p className={styles.avatarPersonalAccountCard_title}>Open Invitations</p>
                        <div className={styles.searchAndIcon}>
                          <input className={styles.search} onChange={(e)=>setsearch(e.target.value)} placeholder='Search'/>
                          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                        </div>
                        <table className={styles.table} style={{marginTop:25}}>
                          <tbody>
                            {
                              userAwaiting?.map((user: UsersEntreprise) => 
                              <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                                <td className={styles.tableCell}>{user.email}</td>
                                <td style={{padding: '13px 7px'}}  className={styles.tableCell} >
                                  <div style={{marginRight: '-16px'}}>
                                  {/*  <p onClick={()=>approuveUser(user.id)} style={{backgroundColor: "#20b444",color:"white", textAlign:"center",verticalAlign:"middle", fontSize: 12, padding: '4px 8px',cursor:'pointer',  border:'none', borderRadius: 32}} >Approve</p> */}
                                  {
                                    roleUser?.userSettings &&   <FontAwesomeIcon onClick={()=>deleteUser(user.id)} style={{cursor: 'pointer'}} icon={faXmarkCircle} color='rgba(255, 255, 255, 0.5)' size='1x'/>
                                  }
                                  </div>
                                </td>
                              </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>  
                      : 
                      <></>
                    }


                    <div>
                      <div className={styles.avatarPersonalAccountCard} style= {{width: '100%'}}>
                        <p className={styles.avatarPersonalAccountCard_title}>Active Members</p>
                        <div className={styles.searchAndRemove}>
                          <div className={styles.searchAndIcon}>
                            <input className={styles.search} onChange={(e)=>setappouvedSearch(e.target.value)} placeholder='Search'/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                          </div>
                          {
                            roleUser?.userSettings &&
                            <div onClick={deleteUsers} className={styles.removeSelected}>
                              <p className={styles.avatarPersonalAccountCard_text}>Remove selection</p>
                              <FontAwesomeIcon icon={faTrash} color='#c0c0c0' style={{fontSize: 12}} />
                            </div>
                          }
                        </div>
                        {usersApproved?.length > 0 ? 
                        <table className={styles.table} style={{marginTop:16}}>
                          <thead>
                            <tr className={styles.tableHead}>
                              <th style={{paddingLeft: 8}}></th>
                              <th style={{paddingLeft: 8}}>Full Name</th>
                              <th style={{paddingLeft: 8}}>Email</th>
                              <th style={{paddingLeft: 22}}>Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              usersApproved?.map((user: UsersEntreprise) => 
                                <tr className={styles.tableActivityRowUsers} style={{height: 49}}>
                                  <td className={styles.tableCell} style={{width:20}}>
                                  {
                                    roleUser?.userSettings && 
                                    <input type="checkbox" onChange={(e) => handleSelectUser(user.id, e.target.checked)} checked={selectedUserIds.includes(user.id)}/>
                                  }
                                  </td>
                                  <td className={styles.tableCell}>{user.firstname} {user.lastname}</td>
                                  <td className={styles.tableCell}>{user.email}</td>
                                  <td className={styles.tableCell} >
                                    {/* <p style={{backgroundColor: "#e6e6e6",color:"#1a1a1a",verticalAlign:"middle", fontSize: 12, padding: '4px 12px',border:'none', borderRadius: 32, width:"fit-content"}} >{user.role ? user.role : 'No role'}</p>
                                    {/*style={{backgroundColor: "#e6e6e6",color:"#1a1a1a",verticalAlign:"middle", fontSize: 12, padding: '4px 0px',border:'none', borderRadius: 32, width:"fit-content"}}*/}
                                    <Select IconComponent={() => 
                                      user.permissions?.[0]?.roles?.toString() !== '5' ? (<FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />) : (<span style={{ marginLeft: '-30px'}}>Support</span>)}
                                      style={{ backgroundColor:"transparent", fontSize: 14}}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={user.permissions?.[0]?.roles?.toString()}
                                      onChange={(e)=> handleChange(user.id, e, user.email)}
                                      disabled={roleUser && roleUser.userSettings ? false: true}
                                      
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: '#1f1f1f',
                                            borderRadius: '8px',
                                            padding: '0px',
                                            boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                            maxHeight: '360px',
                                            overflowY: 'auto',
                                          },
                                        },
                                        MenuListProps: {
                                          dense: false,
                                          disablePadding: true,
                                        },
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left',
                                        },
                                      }}
                                      >
                                      {
                                      roles && rolesData?.map(item =>
                                          <MenuItem key={item.id.toString()} value={item.id.toString()} sx={{color: 'white', '&:hover': {backgroundColor: '#1a1a1a',}, padding: '8px 54px 8px 16px', fontSize: 14}}>{item.role}</MenuItem>
                                        )
                                      }
                                            
                                  </Select>
                                  </td>
                                </tr>
                              ) 
                            }
                          </tbody>
                        </table>
                        : 
                        <div>
                          <EmptyStateUsers open={open} setOpen={setOpen}/> 
                        </div>
                        }
                      </div>
                    </div>
                  </FormControl>
                </form>
            </div>
            <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'Data updated successfuly'}
                handleClose={handleCloseNotification}
            />
            <ErrorNotification
                severity={colorNotf}
                open={openNotifRole}
                message={msg}
                handleClose={()=>setopenNotifRole(false)}
            />
        </div>
  )
}

export default Users