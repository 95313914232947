import { call, put } from "redux-saga/effects";
import { EmailsInviteLink, ExpiredToken, FAILED_ROLE_USER, FAILED_ROLES, GET_ROLE_USER, GET_USER_INFO_REQUEST, LoginFirstTimeRes, MessageResp, OtpRequest, REQUEST_ROLES, RolesSupabase, Security, SecurityPage, SET_ROLE_USER, SUCCESS_ROLES, UPDATE_USER_INFO_SUCCESS, User, UserAction, UserReq } from "../constants";
import { getInfosSecurityAPI, getRolesUserAPI, getRoleUserAPI, getUserInfoAPI, sendInviteLinkAPI, updateLoginEntrepriseAPI, updateSecurityInfoUserAPI, updateUserInfoAPI } from "../api/userApi";
import { getInfoSecurityUser, getUserInfoAction, setMessageAction } from "../actions/userActions";
import { isUsersNotAuthenticated } from "./entrepriseSage";
import { LoginSuccessAction, setNotificationAction } from "../actions/authActions";
import axios, { AxiosError } from "axios";
import { updateInfosEntrepriseAPI } from "../api/entrepriseApi";

export function* getUserInfoSaga(action: UserAction) {
    try {
        const dataUser:User | ExpiredToken = yield call(getUserInfoAPI)
        if(isUsersNotAuthenticated(dataUser)) {
            yield put(LoginSuccessAction({
                id: null,
                login: '',
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue:'',
               profilePicture:'',
               dateToUpdateOtp: '',
                email: '',
                jwt: undefined,
                typeEntreprise: undefined
            }))
        } else {
            localStorage.setItem('loginEntreprise', dataUser.userInfo.loginEntreprise?.toString() || "")
            yield put(getUserInfoAction(dataUser))
        }
    } catch (error) {
        throw error;
    }
}


export function* updateUserInfoSaga(action: UserAction) {
    try {
        const dataUserUpdate = action.payload as UserReq;
        const response: LoginFirstTimeRes = yield call(updateUserInfoAPI, dataUserUpdate)
        yield put({
            type: UPDATE_USER_INFO_SUCCESS,
            payload: response.message
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getInfosSecuritySaga(action: UserAction) {
    try {
        const dataInfosSecurity: SecurityPage | ExpiredToken = yield call(getInfosSecurityAPI)
        if(isUsersNotAuthenticated(dataInfosSecurity)) {
            yield put(LoginSuccessAction({
                login: '',
id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue:'',
               profilePicture:'',
               dateToUpdateOtp: '',
                email: '',
                jwt: undefined,
                typeEntreprise: undefined
            }))
        } else {
            yield put(getInfoSecurityUser({
                security: dataInfosSecurity
            }))
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* updateSecurityInfoUserSaga(action: UserAction) {
    try {
        const response: LoginFirstTimeRes | ExpiredToken = yield call(updateSecurityInfoUserAPI, action.payload as Security)
        
        if(isUsersNotAuthenticated(response)) {
            yield put(LoginSuccessAction({
                login: '',
                id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue: '',
                profilePicture: "",
                dateToUpdateOtp: '',
                 email: ''
                 ,
                 jwt: undefined,
                 typeEntreprise: undefined
            }))
        } else {
            const dataInfosSecurity: SecurityPage = yield call(getInfosSecurityAPI)
            yield put(getInfoSecurityUser({
                security: dataInfosSecurity
            }))
        }
        
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* sendInviteLinkSaga(action: UserAction) {
    try {
        const data = action.payload as EmailsInviteLink;
        const response: LoginFirstTimeRes = yield call(sendInviteLinkAPI, data)
        if(response.message === MessageResp.INVITE_LINK) {
            yield put(setMessageAction({
                notification: 'Invite Link sent with success'
            }))
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* getRoleUserSaga(action: UserAction) {

    try {
        const data: RolesSupabase = yield call(getRoleUserAPI)
        yield put({
            type: SET_ROLE_USER,
            payload: data
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getRolesUserSaga(action: UserAction) {
    try {
        const data:RolesSupabase[] =  yield call(getRolesUserAPI)
        yield put({
            type: SUCCESS_ROLES,
            payload: data
        })
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* updateLoginEntrepriseSaga(action: UserAction) {
    try {
        const loginEntreprise: string = action.payload
        yield call(updateLoginEntrepriseAPI, loginEntreprise)
        
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && (axiosError.response.status === 401 || axiosError.response.status === 200 || axiosError.response.status === 201)) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}