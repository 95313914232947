import { faBell, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import styles from '../../Dashboard/dashboard.module.scss';
import MenuDropDownHeader from './MenuDropDownHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationBell, REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS } from '../../../core/constants';
import { useSelector } from 'react-redux';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';
import { getNotificationsApi, updateNotificationsApi } from '../../../core/api/notificationApi'

function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [notifs, setnotifs] = useState<NotificationBell[] | undefined>()
    const getNotifs = async () => {
        const data = await getNotificationsApi()
        setnotifs(data)
    }

    return (
        <div className={styles.header}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ cursor: "pointer", height: 40, width: 40 }} className={styles.iconHover}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ cursor: "pointer" }} />
                </div>

                <div className={styles.notification} onClick={() => {
                    navigate("/notifications")
                }
                }>
                    <div style={{ cursor: "pointer", height: 40, width: 40 }} className={styles.iconHover}>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                    {
                        notifs && notifs.length > 0 && <span className={styles.numberNotifications}>{notifs.length}</span>
                    }
                </div>
            </div>
            <MenuDropDownHeader />
        </div>
    )
}

export default Header
