import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

// MuiAlert requires a forwardRef to be passed for proper functioning with Snackbar
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ErrorNotificationProps {
  severity: any
  open: boolean;
  message: string;
  handleClose: () => void; // Function to handle closing of the notification
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ open, message, handleClose, severity }) => {
  return (
    <Snackbar 
      open={open} 
      autoHideDuration={5000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      
    >
      <Alert 
        iconMapping={{
          success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28}  color='white'/>,
          error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white'/>,
        }}
       severity={severity} sx={{ 
        width: '100%',
        backgroundColor:severity==="success"?"#00B0FF":"#E11900",
        borderRadius:12,
        fontWeight:500,
        fontSize:14
        }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorNotification;
