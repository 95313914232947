  import * as React from 'react';
  import Button from '@mui/material/Button';
  import Menu from '@mui/material/Menu';
  import MenuItem from '@mui/material/MenuItem';
  import styles from '../account.module.scss';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faArrowRightFromBracket, faBell, faBriefcase, faCircleInfo, faLock, faNetworkWired, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
  import { Navigate, NavLink, useNavigate } from 'react-router-dom';
  import { useDispatch } from 'react-redux';
  import { logoutAction } from '../../../core/actions/authActions';

  import logoPersonalAccount from "../../../icons/Settings/personal.svg"
  import logoSecurity from "../../../icons/Settings/security.svg"
  import logoEntreprise from "../../../icons/Settings/entreprise.svg"
  import logoUsers from "../../../icons/Settings/users.svg"
  import logoWatchList from "../../../icons/Settings/assets.svg"
  import logoNotifications from "../../../icons/Settings/notifications.svg"
  import logoSupport from "../../../icons/Settings/support.svg"
  import logoLogout from "../../../icons/Settings/logout.svg"
  import { useSelector } from 'react-redux';
  import { authSelector } from '../../../core/reducers/authReducer';
  import { userSelector } from '../../../core/reducers/userReducer';
import { env } from '../../../environement/env';
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';
import EmptyUser from '../../../icons/Empty Users/emptyUser.jpg'
import { Avatar } from '@mui/material';
  function MenuDropDownHeader() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {userInfo} = useSelector(userSelector)
    const { entreprise } = useSelector(entrepriseSelector)
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [name, setname] = React.useState<string | null >()
      const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const navigateToPersonnalActive = () => {
      navigate("/settings")
    }

    const logout = () => {
      dispatch(logoutAction())
      localStorage.clear()
    }

    React.useEffect(() => {
      const username = window.localStorage.getItem("name")
      if(username)
        setname(username)
    }, [])
    


    return (
      <div style={{ height: 'auto'}}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.profileHeader}
      >
        <p>{userInfo.firstName}</p>
        <Avatar src={
         userInfo?.profilePicture
        } variant='circular' style={{height: 32, width: 32, marginRight: -10}}/>
        {/* <img src={
         userInfo?.profilePicture
        } className={styles.logoProfileHeader} /> */}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={
          { mt: "1px", "& .MuiMenu-paper": 
            { backgroundColor: "#1f1f1f", color: 'white', width:280 ,borderRadius: 4}, 
          }
        }
      >
          <div className={styles.menuItemProfile}>
          <Avatar src={
         userInfo?.profilePicture
        } variant='circular' style={{height: 32, width: 32}}/>
                  <div>
                      <span className={styles.profileName} style={{fontWeight: 500}}>{userInfo.firstName+" "+userInfo.lastName}</span>
                      <br/>
                      <span className={styles.profilePlan} >{userInfo.email}</span>
                  </div>
              </div>
      <MenuItem style={{padding: 0, paddingLeft:10, marginTop:20}} >
        <NavLink to={'/settings'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoPersonalAccount} height={18} width={18}/>
        </div>
          <p>Profile</p>
        </NavLink>
        </MenuItem>
        <MenuItem style={{padding: 0, paddingLeft:10}}>
        <NavLink to={'/security'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoSecurity} height={18} width={18}/>
        </div>
          <p>Account Security</p>
        </NavLink>
        </MenuItem>
        <MenuItem style={{padding: 0, paddingLeft:10}}>
        <NavLink to={'/entreprise-setting'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoEntreprise} height={18} width={18}/>
        </div>
          <p>Workspace Settings</p>
        </NavLink>
        </MenuItem>

        <MenuItem style={{padding: 0, paddingLeft:10}}>
        
        <NavLink to={'/users'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoUsers} height={19} width={19}/>
        </div>
          <p>Members</p>
        </NavLink>
        </MenuItem>
        <MenuItem style={{padding: 0, paddingLeft:10}}>
        <NavLink to={'/watch-list'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoWatchList} height={19} width={19}/>
        </div>
          <p>Wachlists</p>
        </NavLink>
        </MenuItem>
        {/*<MenuItem style={{padding: 0, paddingLeft:10}}>
        <NavLink to={'/notification-setting'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoNotifications} height={18} width={18}/>
        </div>
          <p>Notification Settings</p>
        </NavLink>
        </MenuItem>*/}
        <MenuItem style={{padding: 0, paddingLeft:10}}>
        <NavLink to={'/support'} className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
        <img src={logoSupport} height={18} width={18}/>
        </div>
          <p>Get Support</p>
          </NavLink>
        </MenuItem>
        <MenuItem style={{padding: 0, paddingLeft:10}} onClick={logout}>
          <NavLink to={"/login"} replace className={styles.menuDropDownItem}>
        <div style={{width:23, height:21}}>
          <img src={logoLogout} height={18} width={18} />
          </div>
          <p>Logout</p>
          </NavLink>
        </MenuItem>
      </Menu>
    </div>
    )
  }

  export default MenuDropDownHeader