import React, { useEffect, useState } from 'react'
import Menu from './common/Menu'
import Header from '../Dashboard/common/Header'
import styles from './account.module.scss'
import HeaderWatchList from './components/HeaderWatchList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons'
import BrandKeywordsWatchList from './components/BrandKeywordsWatchList'
import AssetsWatchList from './components/AssetsWatchList'
import DomainsWatchList from './components/DomainsWatchList'
import BinsWatchList from './components/BinsWatchList'
import RansomGroupsWatchList from './components/RansomGroupsWatchList'
import ExecutiveWatchList from './components/ExecutiveWatchList'
import IpWatchList from './components/IpWatchList'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../core/reducers/watchListReducer'
import { useDispatch } from 'react-redux'
import { GET_WATCH_LIST } from '../../core/constants'
import ReactGA from 'react-ga4';
import { CircularProgress } from '@mui/material'

function WatchList() {
  const dispatch = useDispatch()
  const { watchList, isLoading } = useSelector(watchListSelector)
    const [selectType, setSelectType] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
      document.title = "Watchlists Settings"
      ReactGA.send({ hitType: "Watchlists Settings", page: window.location.pathname });

      dispatch({type: GET_WATCH_LIST})
      
    }, [])
    

    const renderComponent = () => {
        switch (selectType) {
            case 'assets':
                return <AssetsWatchList open={open} setOpen={setOpen}/>
            case 'brandKeyword':
                return <BrandKeywordsWatchList open={open} setOpen={setOpen}/>
            case 'domains':
                return <DomainsWatchList open={open} setOpen={setOpen}/>
            case 'bins':
                return <BinsWatchList open={open} setOpen={setOpen}/>
            case 'executives':
                return <ExecutiveWatchList open={open} setOpen={setOpen}/>
            case 'ransomGroups':
                return <RansomGroupsWatchList open={open} setOpen={setOpen}/>
            case 'ips':
                return <IpWatchList open={open} setOpen={setOpen}/>
            default:
                break;
        }
    }

    const renderComponentTitle = () => {
        switch (selectType) {
            case 'assets':
                return 'Assets Watchlist'
            case 'brandKeyword':
                return 'Brand Keywords Watchlist'
            case 'domains':
                return 'Domains Watchlist'
            case 'bins':
                return 'BINs Watchlist'
            case 'executives':
                return 'Executives Watchlist'
            case 'ransomGroups':
                return "Ransom Groups Watchlist"
            case 'ips':
                return "IPs Watchlist"
            default:
                break;
        }
    }

    console.log(isLoading)

  return (
    <div className={ styles.main }>
        <Menu/>
        <Header/>
        <div className={styles.content}>  
            <HeaderWatchList open={open} setOpen={setOpen} selectType={selectType}
            
            goBack={selectType === '' ? <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p> :
            <p  onClick={()=>setSelectType("")} style={{cursor: 'pointer',color:"#9d9d9d",fontSize:"13px",fontWeight:500, display: 'flex',alignItems: "center", gap:"7px"}}>
                <FontAwesomeIcon icon={faArrowLeft}/>
                Go Back
            </p>
            } 
            
            title={selectType === '' ? 'Watchlists': 
                  renderComponentTitle()
            }
            />
        
            {
              isLoading ?
              <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
                     :
                selectType === ''
                 ? 
                 <div className={styles.avatarPersonalAccountCard}>
                <p className={styles.avatarPersonalAccountCard_title}>Active Watchlists</p>
                <p className={styles.avatarPersonalAccountCard_text}> Watchlists are dynamic lists where you can add indicators you want to monitor closely.</p>
                 
             <table className={styles.table} style={{marginTop:25}}>
               <thead>
                 <tr className={styles.tableHead}>
                   <th style={{paddingLeft: 8}}>Type</th>
                   <th style={{paddingLeft: 8, width:90}}>Items</th>
                 </tr>
               </thead>
               <tbody>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('domains')} style={{cursor: 'pointer'}} className={styles.tableCell}>Domains Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.domains}</td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('bins')} style={{cursor: 'pointer'}} className={styles.tableCell}>BINs Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.bins}</td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('ips')} style={{cursor: 'pointer'}} className={styles.tableCell}>IPs Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.ips}</td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('assets')} style={{cursor: 'pointer'}} className={styles.tableCell}>Assets Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.assets}</td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('ransomGroups')} style={{cursor: 'pointer'}} className={styles.tableCell}>Ransom Groups Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.ransomes}</td>
                    </tr>
                    {/* 
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('brandKeyword')} style={{cursor: 'pointer'}} className={styles.tableCell}>Brand Keywords Watchist</td>
                       <td className={styles.tableCell}>{watchList?.brandKeyword}</td>
                    </tr>
                    <tr className={styles.tableActivityRowRansomWatchList} style={{height: 49}}>
                       <td onClick={()=>setSelectType('executives')} style={{cursor: 'pointer'}} className={styles.tableCell}>Executives Watchlist</td>
                       <td className={styles.tableCell}>{watchList?.executives}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                    </tr>
                      */}

                 </tbody>
             </table>
               </div>
                :
                renderComponent()
            }
        </div>
        
    </div>
  )
}

export default WatchList