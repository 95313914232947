import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import styles from '../../../dashboard.module.scss'
import accountStyles from "../../../../Account/account.module.scss"
import Loader from "../../../../../icons/Loader.svg"
import moment from 'moment'
import GoogleLogo from "../../../../../icons/BrowserIcons/Google-Chrome.svg"
import BraveLogo from "../../../../../icons/BrowserIcons/Brave.svg"
import EdgeLogo from "../../../../../icons/BrowserIcons/Edge.svg"
import FireFoxLogo from "../../../../../icons/BrowserIcons/firefox.svg"
import OperaLogo from "../../../../../icons/BrowserIcons/Opera.svg"
import SafariLogo from "../../../../../icons/BrowserIcons/safari.svg"
import { CircularProgress } from '@mui/material'
import dateFormats from '../../../dateFormats'

type SortOrder = 'asc' | 'desc';


function Credentials({ crendentials }: any) {
  useEffect(() => {
    document.title = "User Profile - Credentials"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [])

  const [sortBy, setSortBy] = useState<string>('log_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const renderColors = (riskScore: number) => {
    if (riskScore === 0.0)
      return {
        textColor: "#757575",
        backgroundColor: "#e6e6e6",
        borderColor: "#757575"
      }
    else if (riskScore >= 0.1 && riskScore <= 3.9)
      return {
        textColor: "#20b444",
        backgroundColor: "#ecf9ef",
        borderColor: "#20b444"
      }
    else if (riskScore >= 4.0 && riskScore <= 6.9)
      return {
        textColor: "#A08100",
        backgroundColor: "#FFEB96",
        borderColor: "#A08100"
      }
    else if (riskScore >= 7.0 && riskScore <= 8.9)
      return {
        textColor: "#894C02",
        backgroundColor: "#FFD8A7",
        borderColor: "#894C02"
      }
    else if (riskScore >= 9.0 && riskScore <= 10.0)
      return {
        textColor: "#e11900",
        backgroundColor: "#FBD2C4",
        borderColor: "#e11900"
      }
    else
      return {
        textColor: "#757575",
        backgroundColor: "#e6e6e6",
        borderColor: "#757575"
      }
  }

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  interface Credential {
    username: string
    url: string
    total_risk_score: number
    user: {
      log_date: string
    }
  }

  const finalSort = (crendentials as Credential[] | undefined)?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'username') {
      comparison = a.username.localeCompare(b.username)
    } else if (sortBy === 'url') {
      comparison = a.url.localeCompare(b.url)
    } else if (sortBy === 'total_risk_score') {
      comparison = Number(a?.total_risk_score).toFixed(1).localeCompare(Number(b?.total_risk_score).toFixed(1))
    } else if (sortBy === 'log_date') {
      comparison = a.user?.log_date?.localeCompare(b.user?.log_date ?? '') ?? 0
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })


  return (
    <>
      {
        crendentials ?
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableHead}>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Username</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Password</span>
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('username')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Source</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'username' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('total_risk_score')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Risk Score</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'total_risk_score' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Application</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                crendentials?.map((item: any) =>
                  <tr key={item.passwordid} className={styles.tableActivityRowWatchListAssets}>
                    <td className={styles.tableCell}>
                      <span style={{ fontSize: 14 }}>{item?.username ? item.username.length > 45 ? `${item.username.slice(0, 45)}...` : item.username : "-"}</span>
                    </td>
                    <td className={styles.tableCell} style={{ width: "150px" }}>
                      <span>{item.password ? `${item.password.slice(0, 3)}${item.password.slice(3, 15).replace(/./g, '*')}` : "-"}</span>
                    </td>
                    <td className={styles.tableCell} >
                      <span>{item?.url?.includes('android') ? item?.url?.split('@')[1] : item?.url?.substring(0, 43)}</span>
                    </td>
                    <td className={styles.tableCell} style={{ width: '110px' }}>
                      <span className={styles.scoreSeverity} style={{
                        width: '22px',
                        color: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).textColor,
                        border: `1px solid ${renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).borderColor}`,
                        backgroundColor: renderColors(Math.round(Number(item?.total_risk_score) * 10) / 10).backgroundColor
                      }}>{
                          Number(item?.total_risk_score).toFixed(1)
                        }</span>


                    </td>
                    <td className={styles.tableCell} style={{ display: "flex", padding: "13px 8px" }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: '50%', height: "22px", width: "22px" }}>
                          <img src={
                            item?.application?.toLowerCase()?.includes("google") ? GoogleLogo
                              : item?.application?.toLowerCase()?.includes("brave") ? BraveLogo
                                : item?.application?.toLowerCase()?.includes("edge") ? EdgeLogo
                                  : item?.application?.toLowerCase()?.includes("firefox") ? FireFoxLogo
                                    : item?.application?.toLowerCase()?.includes("opera") ? OperaLogo
                                      : SafariLogo
                          } alt="" height={28} width={36} />
                        </div>
                        <div>
                          <span style={{ color: 'white', fontSize: "14px", paddingLeft: 10, }}>{
                            item?.application?.toLowerCase()?.includes("google") ? "Google Chrome"
                              : item?.application?.toLowerCase()?.includes("brave") ? "Brave"
                                : item?.application?.toLowerCase()?.includes("edge") ? "Microsoft Edge"
                                  : item?.application?.toLowerCase()?.includes("firefox") ? "Mozilla Firefox"
                                    : item?.application?.toLowerCase()?.includes("opera") ? "Opera"
                                      : "Safari"
                          }</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
          :
          <div className={styles.loader}>
            <CircularProgress />
          </div>
      }
    </>
  )
}

export default Credentials
