import React, { useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { GET_WATCH_LIST, GET_WATCH_LIST_BIN, WatchListBin } from '../../../core/constants'
import EmptyStateWatchList from '../../Dashboard/common/EmptyStateWatchList'
import ErrorNotification from '../common/ErrorNotification'
import { DeleteWatchListBinApi } from '../../../core/api/watchListApi'
import { CircularProgress } from '@mui/material'
import { userSelector } from '../../../core/reducers/userReducer'
import { supabaseClient } from '../../../core/supabase'

interface props {
  open: boolean;
  setOpen: any;
}

function BinsWatchList({open, setOpen}: props) {
  const dispatch = useDispatch()
  const { watchListBin, isLoadingBin } = useSelector(watchListSelector)
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [notifMessage, setnotifMessage] = useState<string>("")
  const [openNotif, setOpenNotif] = useState(false)
  const { roleUser } = useSelector(userSelector)
    const handleCheckboxChange = (id: any) => {
        setSelectedAssets((prev: any) => {
            if (prev.includes(id)) {
                return prev.filter((item: any) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };


    const deleteBins = async () => {
      await supabaseClient.from("watchlistBin").delete().in('id', selectedAssets)
      // setnotifMessage(message)
      // setOpenNotif(true)
        setTimeout(() => {
          dispatch({type: GET_WATCH_LIST_BIN})
        }, 500);
    }
  useEffect(() => {
    document.title = "BINs Watch list"
    dispatch({type: GET_WATCH_LIST_BIN})
  }, [])

  console.log(watchListBin)
  
  return (
    <>
      {
        !isLoadingBin ? 
        watchListBin && watchListBin.length > 0 ?
        <div className={styles.awaitingApproval}>
                        
        <div className={styles.searchAndRemove}>
        <div className={styles.searchAndIcon}>
            <input className={styles.search} name='search' placeholder='Search'/>
            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
        </div>
        {  <div className={styles.removeSelected} onClick={deleteBins}>
            <p className={styles.avatarPersonalAccountCard_text}>Remove selection</p>
            <FontAwesomeIcon icon={faTrash} color='#c0c0c0' style={{fontSize: 12}} />
        </div>}
    </div>
    <table className={styles.table}style={{marginTop:25}}>
      <thead>
        <tr className={styles.tableHead}>
        <th style={{paddingLeft: 8}}> </th>
          <th style={{paddingLeft: 8}}>BIN</th>
          <th style={{paddingLeft: 8}}>Status</th>
        </tr>
      </thead>
      <tbody>
        {
          watchListBin?.map((data: WatchListBin) => 
          <tr className={styles.tableActivityRowWatchList}>
            <td style={{width: '20px'}} className={styles.tableCell}>
            <input
                                type="checkbox"
                                className={styles.customCheckbox}
                                value={data.id}
                                onChange={() => handleCheckboxChange(data.id)}
                                checked={selectedAssets.includes(data?.id)}
                            />
            </td>
              <td style={{width: '570px'}} className={styles.tableCell}>{data.bin}</td>
              <td style={{width: '120px'}} className={styles.tableCell} >
                <p style={{backgroundColor: "#e6e6e6",color:"#1a1a1a", textAlign:"center",verticalAlign:"middle", fontSize: 12, padding: '6px 4px',border:'none', borderRadius: 32,marginRight:20}} >{data.status}</p>
              </td>
            </tr>
          )
        }
        
        </tbody>
    </table>
    <ErrorNotification 
                    severity="success"
                    open = {openNotif}
                    message={notifMessage}
                    handleClose={() => setOpenNotif(false)}
                    />
      </div> 
      :
      
      <div className={styles.loaderVul}>
      <EmptyStateWatchList 
          open={open} 
          setOpen={setOpen} 
          title='BINs Watchlist is empty'
          addButtonText='Bin'
          text='Add your BIN numbers to start monitoring for leaked payment card information.'
          />
          </div>
          :
          <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
      } 
    </>
  )
}

export default BinsWatchList