import styles from '../../dashboard.module.scss'
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import { faCalendar, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { CircleFlag } from 'react-circle-flags'
import { CircularProgress } from '@mui/material'
import EmptyStateVictims from '../../common/EmptyStateVictims'
import { SET_VIC_DETAIL } from '../../../../core/constants';
import { useNavigate } from 'react-router-dom';

function Victims() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { groupDetail, victims, isLoadingGroups } = useSelector(ransomeSelector);

    useEffect(() => {
        document.title = 'Ransom Group Victims';
        ReactGA.send({ hitType: 'Ransom Group Victims', page: window.location.pathname });
    }, []);
    

    const filteredVictims = groupDetail && victims
        ? victims?.data?.filter((victim:any) => victim.group_name === groupDetail.name)
        : [];

    return (
        <>
            {
                !isLoadingGroups ?
                    groupDetail?.name.length && filteredVictims?.length ? (
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.tableHead}>
                                    <th style={{ paddingLeft: 24, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Country</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 16, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Victims</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 8, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Published on</span>
                                        </div>
                                    </th>
                                    <th style={{ paddingLeft: 8, cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <span>Attacked on</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                filteredVictims && filteredVictims.map((item:any) => (
                                    <tr key={item.id} className={styles.tableActivityRow} style={{cursor:"pointer"}} onClick={() => {
                                        dispatch({ type: SET_VIC_DETAIL, payload: item });
                                       navigate("/ransomwareIntel/VictimsDetail");
                                    }}>
                                        <td style={{ paddingLeft: 24, width: '120px' }} className={styles.tableCell}>
                                            <CircleFlag countryCode={item?.country?.toLowerCase() ?? 'default'} height="24" />
                                        </td>
                                        <td style={{ padding: '16px' }} className={styles.tableCell}>
                                            {item?.post_title}
                                        </td>
                                        <td style={{ width: '180px' }} className={styles.tableCell}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'start' }}>
                                                <FontAwesomeIcon icon={faCalendar} width={10} height={11} color='grey' />
                                                {' ' + moment(item?.discovered).format('MMM DD, YYYY')}
                                            </div>
                                        </td>
                                        <td style={{ paddingLeft: 8, width: '138px' }} className={styles.tableCell}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'start' }}>
                                                <FontAwesomeIcon icon={faCalendar} width={10} height={11} color='grey' />
                                                {' ' + moment(item?.published).format('MMM DD, YYYY')}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>

                    ) : (
                        <div className={styles.loaderVul}>
                            <EmptyStateVictims />
                        </div>
                    )
                    : (
                        <div className={styles.loaderVul}><CircularProgress /></div>
                    )
            }
        </>


    );
}

export default Victims;