import { call, put } from "redux-saga/effects";
import { FAILED_HOME_STATS, HomeViewStats, Stats } from "../constants";
import { getHomeViewStatsApi } from "../api/statsApi";
import { successStatsAction } from "../actions/statsActions";
import { AxiosError } from "axios";

export function* getHomeViewSaga(actions: Stats) {
    try {
        const data: HomeViewStats = yield call(getHomeViewStatsApi, actions.payload as string)
        yield put(successStatsAction(data))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                // console.log("Caught error:", error);
                // console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}