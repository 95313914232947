import React from 'react'
import styles from "../dashboard.module.scss"
 
import EmptyStateLogo from "../../../icons/States/Emptystate.svg"
export default function EmptyStateSocialMedia() {
    return (
        <div className={styles.emptyState}>
        <div style={{width: '320px', margin: 32}}>
            <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>
              <img src={EmptyStateLogo}/>
            </div>
          <div style={{marginTop: 40}}> 
            <h2 style={{textAlign: "center",color:"white", fontWeight:"600",fontSize:20, marginBottom: 0}}>No Social Media Posts</h2>
            <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14, marginBottom: 16}}>No mention on social media detected. Make sure you add your keywords to the watchlist.</p>
          </div>
         
        </div>
      </div>
      )
}
