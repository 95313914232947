import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../../dashboard.module.scss'
import moment from 'moment'
import logoVisa from "../../../../../icons/CreditCards/visa.svg"
import logoMastercard from "../../../../../icons/CreditCards/mastercard.svg"
import logoAmex from "../../../../../icons/CreditCards/amex.svg"
import logoDiscover from "../../../../../icons/CreditCards/discover.svg"
import logoUndefined from "../../../../../icons/CreditCards/undefined.svg"
import EmptyState from '../../../common/EmptyState'
export default function Banking({creditsCard}: any) {
    return (
        <>
            {
                creditsCard && creditsCard?.length!==0 ? <div className={styles.creditsCard}>
                {
                    creditsCard?.map((item: any) => <>
                        <div style={{ padding: "4px 16px 16px 16px", borderRadius: 8, border: "1px solid #333333"}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>{item?.nameoncard}</p>
                                <img src={
                                item?.brand?.toLowerCase()  === "visa" ? logoVisa : 
                                item?.brand?.toLowerCase()  === "discover" ? logoDiscover :
                                item?.brand?.toLowerCase()  === "amex" ? logoAmex : 
                                item?.brand?.toLowerCase()  === "mastercard" ? logoMastercard : logoUndefined
                            }/>
                            </div>
                            <div style={{marginTop: 18}}>
                            <span style={{color: 'white', fontSize: 14}}>{item?.cardnumber? `${item.cardnumber.slice(0, 4)} ${item.cardnumber.slice(4, 6)}** **** ${item.cardnumber.slice(-4)}`: "N/A"}</span> <br/>
                            <span style={{color: 'white', fontSize: 14}}>{item?.expiredate}
                            {/* <span style={{marginLeft: 16}}>490</span> */}
                            </span>
                            </div>
                            
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 32}}>
                                <div>
                                <span style={{color: '#afafaf', fontSize: 12}}>{item?.level} {item?.type}</span>
                                </div>
                                <div>
                                <span  style={{color: '#afafaf', fontSize: 12}}>{item?.currency}</span>
                                </div>
                            </div>
                        </div>
                        
                        </>
                    )
                }
                        
            </div>
            :
            <div className={styles.loaderVul}>
                     <EmptyState/>
                     </div>
            }
        </>
    )
}
