import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ChartHome from '../components/ChartHome'
import CriticalRiskBar from '../components/CriticalRiskBar'
import Piechart from '../components/Piechart'
import styles from '../dashboard.module.scss'
import ReactGA from 'react-ga4';
import PieChartRansomeGroups from '../components/PieChartRansomeGroups'
import { RansomeOverview, REQUEST_RANSOME_OVERVIEW } from '../../../core/constants'
import { getRansomeOverviexApi } from '../../../core/api/ransomeApi'
import { setNamePageLocalStorage } from '../../../core/actions/stepsNavPageActions'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import ChartHomeRansome from '../components/ChartHomeRansome'
import EmptyStateLogo from "../../../icons/States/Emptystate.svg"
import { CircularProgress } from '@mui/material'
import { cp } from 'fs'
import { useDispatch } from 'react-redux'

interface props {
    setStepSetAll: any
}
function OverViewSection({setStepSetAll}: props) {
    const dispatch = useDispatch()
    const {overview, isLoadingOveriview,victims, groups} =useSelector(ransomeSelector)
    const [isRender, setisRender] = useState(false)
   
    type DataPoint = {
        date: string;
        victims: number;
    };

    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const threeMonthsInMilliseconds = 90 * 24 * 60 * 60 * 1000;

    const filterDataByPeriod = (data: DataPoint[]): DataPoint[] => {
        if (data.length < 2) return data;
    
        const startDate = new Date(data[0].date).getTime();
        const endDate = new Date(data[data.length - 1].date).getTime();
        const range = endDate - startDate;
    
        if (range <= sevenDaysInMilliseconds) {
            return data;
        } else if (range <= oneMonthInMilliseconds) {
            return data.filter((_: unknown, index: number) => index % 7 === 0);
        } else if (range <= threeMonthsInMilliseconds) {
            return data.filter((_: unknown, index: number) => index % 28 === 0);
        } else {
            const sampleRate = Math.floor(data.length / 50);
            return data.filter((_: unknown, index: number) => index % sampleRate === 0);
        }
    };
    
    
    useEffect(() => {
      document.title = 'Ransomware Overview'
      ReactGA.send({ hitType: "Ransomware Overview", page: window.location.pathname });
      if(!overview) {
        dispatch({
            type: REQUEST_RANSOME_OVERVIEW
        })
      }
    }, [])
    console.log(overview?.ransomeGroupsPosts)
    
    const dataGlobalVictims = overview?.globalVictims
    ?.map(item => ({
        date: item?.publish_date,
        victims: Number(item.nbr_of_victims)
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const ransomFilteredData = filterDataByPeriod(dataGlobalVictims || []);

    const setAll = (step: string) => {
        
        setStepSetAll(step)
    }

    const totalVictims = Number(overview?.targetCountries?.[0]?.nbr_of_victims) 
                            + Number(overview?.targetCountries?.[1]?.nbr_of_victims)
                            + Number(overview?.targetCountries?.[2]?.nbr_of_victims)
                            + Number(overview?.targetCountries?.[3]?.nbr_of_victims)
                            + Number(overview?.targetCountries?.[4]?.nbr_of_victims)
    console.log("total victims: "+totalVictims)
    console.log
    return (
    <>{
        !isLoadingOveriview ? 
   
        <div className={styles.statsThreats}>
            <div className={styles.chartHomeWrapper}>
                <div className={styles.timelineContainer}>
                    <div style={{marginBottom: 24}}>
                        <span style={{color: "white", marginLeft: 25}}>Victims Timeline</span>
                    </div>
                    {
                        dataGlobalVictims?.length !== 0 ?
                        <ChartHomeRansome data={ransomFilteredData}/>
                        :
                        <div style={{display:"flex", alignItems: "center", verticalAlign:"middle", height:"80%",  justifyContent:"center"}}>
                            <div>
                                <p style={{textAlign: "center", color:"#888888", fontSize: 12}}>Data unavailable</p>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.vulnerabilityContainer}>
                    <div style={{marginBottom: 24}}>
                        <span style={{color: "white"}}>Ransom Groups Notoriety</span>
                    </div>
                    {overview && <PieChartRansomeGroups overview={overview}/>}
                </div>
            </div>
            <div className={styles.threatAndMentionsBreakdown}>
                <div className={styles.threatBreakdown}>
                        <div className={styles.recentThreats_Header}>
                            <span style={{color: "white"}}>Targeted Countries</span>
                            <div className={styles.seeAll} onClick={()=>setAll("countries")}>
                                <span>See all</span>
                                <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 9}}/>
                            </div>
                        </div>
                        <div className={styles.riskProgressBar}>
                        {
                                    overview?.targetCountries?.map(item => 
                                        <CriticalRiskBar value={(Number(item?.nbr_of_victims )/(totalVictims ? totalVictims : 1))*100} title={item?.country} />
                                    )
                                }
                        </div>
                </div>
                <div className={styles.mentionsBreakdown}>
                            <div className={styles.recentThreats_Header}>
                                <span style={{color: "white"}}>Ransom Groups Posts</span>
                                <div className={styles.seeAll} onClick={()=>setAll('groups')}>
                                    <span>See all</span>
                                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 9}}/>
                                </div>
                            </div>
                            <div className={styles.riskProgressBar}>
                                {
                                    overview?.groupsPost?.map(item => 
                                    item?.group_name !== "Others" && <CriticalRiskBar value={Number(item?.percent )} title={item?.group_name} />
                                    )
                                }
                            
                            </div>
                </div>
                </div>
            </div>
    :
        <div className={styles.loaderVul}>
        <CircularProgress />
        </div>
    }</> 
    )
}

export default OverViewSection
