import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import styles from './auth.module.scss';
import logoSignSVG from '../../icons/Login-Illustration.svg';
import logo from '../../icons/Defendis_Logo_White.svg';
import { REQUEST_GET_ALL_ENTREPRISES } from '../../core/constants';
import { selectCompanyAPI } from '../../core/api/entrepriseApi';

export default function SelectCompany() {
    const dispatch = useDispatch();

    const { entreprises } = useSelector((state: any) => state.entreprise);
    const [ent, setEnt] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        // console.log(JSON.stringify(event.target.value));
        setEnt(event.target.value);
    };

    const handleSubmit = async () => {
        const userId = localStorage.getItem('userid')
        // console.log(userId)
        if(userId){
            const response = await selectCompanyAPI(userId, Number(ent))
            // console.log(response)
        }
    }

    useEffect(() => {
       const userid = localStorage.getItem('userid')
       if(userid) {
        dispatch({
            type: REQUEST_GET_ALL_ENTREPRISES,
        });
       } else {
          
       }
    }, [dispatch]);

    return (
        <div className={styles.main}>
            <form onSubmit={(e) => e.preventDefault()}>
                <img src={logo} alt="Defendis Logo" className={styles.logo} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0 }}>
                    <div className={styles.login}>
                        <div style={{ width: '50%' }}>
                            <div className={styles.loginInputs}>
                                <p className={styles.titleLogin}>
                                    Choose your company
                                </p>
                                <Select
                                    labelId="company-select-label"
                                    id="company-select"
                                    fullWidth
                                    variant="outlined"
                                    value={ent}
                                    onChange={handleChange}
                                    style={{ fontSize: 14, fontWeight: "500", width: "100%", marginTop: 30, border: "1px solid white", color: "white", paddingTop: 20, paddingBottom:20 }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select your company' }}
                                >
                                    <MenuItem value="" disabled selected>
                                        Select your company
                                    </MenuItem>
                                    {entreprises?.map((item: any) => (
                                        <MenuItem key={item?.id?.toString()} value={item?.id?.toString()}>
                                            {item?.entrepriseName}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={styles.btnLogin}
                                    style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
                                >
                                    Select
                                </Button>
                            </div>
                        </div>
                        <img src={logoSignSVG} className={styles.illus} style={{ marginTop: '20%' }} alt="Illustration" />
                    </div>
                </div>
            </form>
        </div>
    );
}
