import React from 'react'
import styles from '../../../dashboard.module.scss'
export default function Software({sofwares}: any) {
    return (
        <table className={styles.table}style={{marginTop:8}}>
            {
                sofwares?.map((item: any) => 
                    <tr  className={styles.tableActivityRowRansom1Col}>
                <td style={{width: '100%', padding: '15px 16px', color: '#ffffff'}} className={styles.tableCell}>
                    {item?.name.replace(/^\d+\)\s*/, '')}
                </td>
              </tr>
                )
            }
            
      </table>
    )
}
