import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Comments from '../BrandIntelSections/Comments'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../dashboard.module.scss'
import Certs from './CountryDetailComponents/Certs'
import Overview from './CountryDetailComponents/Overview'
import Victims from './CountryDetailComponents/Victims'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import { useDispatch } from 'react-redux'
import { REQUEST_RANSOME_COUNTRY_DETAIL } from '../../../core/constants'
import moment from 'moment'
import { CircleFlag } from 'react-circle-flags'

function CountryDetail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { code, last_publish_date, countryDetail } = useSelector(ransomeSelector)
    const [activeCompo, setactiveCompo] = useState('victims')

    const renderSections = () => {
        switch (activeCompo) {
            case 'victims':
                return <Victims/>
            case 'overview':
                return <Overview/>
            case 'certs':
                return <Certs/>
            default:
                return;
        }
    }
    useEffect(() => {
      if(code) {
        dispatch({
            type: REQUEST_RANSOME_COUNTRY_DETAIL,
            payload: code
          })
      }
    }, [])
    
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
            <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/ransomwareIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <span>Go Back</span>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 12, marginTop:16}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <CircleFlag countryCode={countryDetail?.countryDetail?.[0]?.country_code.toLowerCase() ?? 'default'} height="24" />
                        <span style={{fontSize: 20, fontWeight: 600}}>{countryDetail && countryDetail?.countryDetail?.[0]?.country_name?.charAt(0).toUpperCase() + countryDetail?.countryDetail?.[0]?.country_name?.slice(1)} Country Profile</span>
                    </div>
                </div>
            </div>
            {/*<div style={{marginTop: 25, fontSize: 14}}>
                    <span style={{color: "#afafaf"}}>Last updated </span>
                    <span style={{color: "white"}}>{moment(last_publish_date).format('MMMM DD, YYYY') </span>
            </div>*/}

                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px'   }} className={styles.firstSectionMenu}>
                <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('victims')} className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Victims</span> 
                    <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('certs')} className={activeCompo === 'certs' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>CERTs</span>
                    </div>
            {renderSections()}
           { 
           // <Comments/>
            }
            </div>

        </div>
    )
}

export default CountryDetail
