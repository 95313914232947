import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import EmptyStateCerts from '../../common/EmptyStateCerts'
import { CircularProgress } from '@mui/material'


function Certs() {
  const dispatch = useDispatch()
  const { victims, isLoadingVictims } = useSelector(ransomeSelector)
  const { isLoadingCountryDetail, countryDetail } = useSelector(ransomeSelector)
  const openlinkDetail = (link:any) => {
    window.open(link, '_blank');
};

  // console.log(countryDetail)  
  return (
    <>
      {
        !isLoadingVictims? 
        countryDetail?.certCountry?.length!== 0 ?
      
        <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            {
            countryDetail?.certCountry?.map(item => 
              <tr className={styles.tableActivityRowRansomWatchList}>
                <td style={{width: '200px',padding: '16px', color:'white'}} className={styles.tableCell}>
                {item?.team_name}
                </td>
                <td style={{padding: '16px', color: "#e9bd08", textDecorationLine: 'underline'}} className={styles.tableCell}>
                  <span onClick={()=>openlinkDetail(item?.website)} style={{cursor:"pointer"}}>  {item?.website?.startsWith('https://www.first.org/') ? item.website.replace('https://www.first.org/', 'https://defendis.ai/') : item.website}</span>
                </td>
              </tr>
              )
            }  
          </tbody>
        </table>
        : 
        <div className={styles.loaderVul}>
          <EmptyStateCerts/>
        </div>
        :
        <div className={styles.loaderVul}><CircularProgress /></div>
      } 

    </>
    )
}

export default Certs
