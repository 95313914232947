import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from "../dashboard.module.scss"
import { faChevronUp, faCalendar, faChevronDown, faCreditCard, faDownload, faHandSparkles, faMagicWandSparkles, faSearch, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InsideThreatsIntel, REQUEST_DOCUMENTS_LEAK } from '../../../core/constants'
import { getInsideThreatsApi, getDocumentsLeakExcelApi, PageSort } from '../../../core/api/threatIntelApi'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import ReactGA from 'react-ga4'
import moment from 'moment'
import Loader from "../../../icons/Loader.svg"
import { CircularProgress, Pagination } from '@mui/material'
import { exportToExcel } from '../components/ExportToExcel'
import dateFormats from '../dateFormats'

type SortOrder = 'asc' | 'desc';

export default function InsideThreats() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [search, setsearch] = useState("")
  const navigate = useNavigate()
  const { documentsLeak, isLoadingDocumentsLeak } = useSelector(threatIntelSelector)
  const [insideThreatsData, setinsideThreatsData] = useState<InsideThreatsIntel[] | undefined>()
  const getInsideThreatsData = async () => {
    const data = await getInsideThreatsApi()
    setinsideThreatsData(data)
  }

  const [sortBy, setSortBy] = useState<string>('updated_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [isDonwloaded, setisDonwloaded] = useState(false)




  useEffect(() => {
    getInsideThreatsData()
    document.title = "Compromised Victims"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [])


  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      if(column.includes('updated_at')) {
        const dataCred: PageSort = {
          page: page,
          sortBy: sortOrder === 'asc' ? 'desc' : 'asc',
          search: search
        }
        dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
      }
    } else {
      setSortBy(column);
      setSortOrder('asc');
      if(column.includes('updated_at')) {
        const dataCred: PageSort = {
          page: page,
          sortBy: "asc",
          search: search
        }
        dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
      }
    }
  };

  const setCredentialDetail = (item: any) => {

    const itemForDisplay = {
      ...item,
      typeUser: item.type,
      userid: item.userid,
      username: item.email
    };
    window.localStorage.setItem('typeUser', JSON.stringify(item.type))
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
    navigate("/threatIntel/userProfile")
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      sortBy: sortOrder,
      search: search
    }
    setPage(value)
    dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
  };
  const handleSearch = (
  ) => {
    if (search !== "") {
      const dataCred: PageSort = {
        page: page,
        sortBy: sortOrder,
        search: search
      }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
    } else {
      const dataCred: PageSort = {
        page: page,
        sortBy: sortOrder,
        search: ""
      }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
    }
  };

  const finalSort = documentsLeak?.data
  .filter((item) => item.file_name)

  .sort((a, b) => {
    let comparison = 0
    if (sortBy === 'email') {
      comparison = a.email.localeCompare(b.email)
    } else if (sortBy === 'type') {
      comparison = a?.type.localeCompare(b?.type)
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })
  .filter((item, index, self) =>
    index === self.findIndex((t) => t.email === item.email) // Ensure unique emails
  )

  const handleExportList = async () => {
    const result = await getDocumentsLeakExcelApi()
    const dataXlsx = result?.map((item:any) => ({
      'File Name':item?.file_name,
      'Username': item?.fullName,
      'Type': item?.type,
      'Leaked on': moment(item?.updated_at, dateFormats, true).format("MMM DD, YYYY"),
      
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Documents_Leaks-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  return (
    <div className={styles.awaitingApproval} style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e) => setsearch(e.target.value)} />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch} />
        </div>
        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 14 }} />
        </div>
      </div>
      {
        documentsLeak ?
          <div style={{ width: "100%" }}>
            <table className={styles.table}>
              <tr className={styles.tableHead}>
                <th></th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('email')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Username</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'email' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('type')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Type</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'type' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('updated_at')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Detected on</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'updated_at' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
              </tr>
              {
                finalSort?.map(item =>
                  <tr className={styles.tableActivityRow} onClick={() => setCredentialDetail(item)} style={{ cursor: "pointer" }}>
                    <td className={styles.tableCell} style={{ width: 6 }}>
                    </td>
                    <td style={{ padding: "16px 8px 15px 0px"}} className={styles.tableCell}>
                      <span>{item.fullName ? item.fullName : "-"}</span>
                    </td>
                    <td style={{ paddingLeft: 8, width: 180 }} className={styles.tableCell}>
                      <span style={{ padding: '4px 8px', color: "#1a1a1a", backgroundColor: "white", borderRadius: '32px', fontSize: 12, width: '72px', textAlign: 'center' }}>{item.type}</span>
                    </td>
                    <td style={{ width: 150 }} className={styles.tableCell} >
                      <div style={{}}>
                        {
                          item?.updated_at ?
                            <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                              <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey"/>
                              <span>{ moment(item?.created_at).format("MMM DD, YYYY")}</span>
                            </div>
                            :
                            <div>
                            <span>-</span>
                            </div>
                        }
                      </div>
                    </td>
                  </tr>
                )
              }

            </table>
            <Pagination
              showFirstButton
              showLastButton
              shape="rounded"
              onChange={handleChangePage}
              page={Number(documentsLeak?.pagination?.currentPage)}
              count={documentsLeak?.pagination?.totalPages}
              sx={{
                '& .MuiPagination-ul': {
                  justifyContent: "center",
                  display: 'flex'
                },
                '& .Mui-selected': {
                  color: '#ffffff', // Text color for active page
                  backgroundColor: '#1f1f1f', // Background color for active page
                  opacity: 0.3, // Background color for active page
                },
                '& .MuiPaginationItem-root': {
                  color: '#ffffff', // Text color for inactive pages
                },
              }}
            />
          </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }
    </div>
  )
}

{/* <div style={{ width: "100%" }}>
<table className={styles.table}>
  <tr className={styles.tableHead}>
    <th style={{ paddingLeft: 8 }}>
      Employees {" "}
      <FontAwesomeIcon icon={faChevronDown} width={7} height={8} />
    </th>
    <th style={{ paddingLeft: 8 }}>
      Type {" "}
      <FontAwesomeIcon icon={faChevronDown} width={7} height={8} />
    </th>
    <th style={{ paddingLeft: 8, textAlign: 'center' }}>
      Credentials Leaked
    </th>
    <th style={{ paddingLeft: 8 }}>
      Severity {" "}
      <FontAwesomeIcon icon={faChevronDown} width={7} height={8} />
    </th>
    <th style={{ paddingLeft: 8 }}>
      Last Updated {" "}
      <FontAwesomeIcon icon={faChevronDown} width={7} height={8} />
    </th>
  </tr>
  {
    insideThreatsData?.map(item =>
      <tr className={styles.tableActivityRowWatchListAssets}>
        <td style={{ width: '328px', paddingLeft: 8 }} className={styles.tableCell}>
          {item.fullName}
        </td>
        <td style={{ width: '128px', paddingLeft: 8 }} className={styles.tableCell}>
          <p style={{ padding: '4px 8px', color: "black", backgroundColor: "white", borderRadius: '32px', fontSize: 12, width: '72px', textAlign: 'center' }}>{item.type}</p>
        </td>
        <td style={{ width: '328px' }} className={styles.tableCell}>
          <p style={{ color: '#cbcbcb', fontSize: 14, textAlign: 'center' }}>{item.numberCredentials}</p>
        </td>
        <td style={{ width: '120px', paddingLeft: 8 }} className={styles.tableCell} >
          <span style={{ width: "32px" }} className={styles.scoreSeverity}>{item.riskscore}</span>
        </td>
        <td style={{ width: '190px', paddingLeft: 8 }} className={styles.tableCell} >
          <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#  afafaf" /> April 02, 2024

        </td>
      </tr>
    )
  }

</table>
</div> */}