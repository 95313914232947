import React, { useEffect, useState } from 'react'
import styles from "../../../../Dashboard/dashboard.module.scss"
import { faArrowLeft, faCalendar, faChevronDown, faChevronUp, faCircleCheck, faCircleXmark, faDownload, faHandSparkles, faMagicWandSparkles, faSearch, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentsLeakIntel, InsideThreatsIntel, REQUEST_DOCUMENTS_LEAK } from '../../../../../core/constants'
import { getDocumentsLeakApi, getDocumentsLeakExcelApi, getInsideThreatsApi, PageSort } from '../../../../../core/api/threatIntelApi'
import ReactGA from 'react-ga4';
import moment from 'moment'
import Loader from "../../../../../icons/Loader.svg"
import EmptyState from '../../../common/EmptyState'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../../core/selectors/threatIntelSelector'
import { renderColors } from '../../CredentialsLeaks'
import { useNavigate } from 'react-router-dom'
import { Alert, CircularProgress, Pagination, Snackbar } from '@mui/material'
import { useDispatch } from 'react-redux'
import { exportToExcel } from '../../../components/ExportToExcel'
import dateFormats from '../../../dateFormats'

type SortOrder = 'asc' | 'desc';

export default function DocumentLeaks() {
  const dispatch = useDispatch()
  const { documentsLeak, isLoadingDocumentsLeak } = useSelector(threatIntelSelector)
  const [page, setPage] = useState(1)
  const [search, setsearch] = useState("")
  const navigate = useNavigate()
  const [sortBy, setSortBy] = useState<string>('updated_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [isDonwloaded, setisDonwloaded] = useState(false)

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };


  useEffect(() => {
    document.title = "Document Leaks"
    ReactGA.send({ hitType: "Document Leaks", page: window.location.pathname });
 
    const dataCred: PageSort = {
      page: 1,
      sortBy: null,
    };
    if(!documentsLeak) {
      dispatch({
        type: REQUEST_DOCUMENTS_LEAK,
        payload: dataCred 
      })
    }
  }, [])


  const setCredentialDetail = (item: any) => {

    const itemForDisplay = {
      ...item,
      typeUser: item.type,
      userid: item.userid,
      username: item.email
    };
    window.localStorage.setItem('typeUser', JSON.stringify(item.type))
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
    navigate("/threatIntel/userProfile")
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>, value: number
  ) => {
    const dataCred: PageSort = {
      page: value,
      sortBy: search
    }
    setPage(value)
    dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
  };
  const handleSearch = (
  ) => {
    if (search !== "") {
      const dataCred: PageSort = {
        page: page,
        sortBy: search
      }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
    } else {
      const dataCred: PageSort = {
        page: page,
        sortBy: null
      }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
    }
  };


  const finalSort = documentsLeak?.data.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'file_name') {
      comparison = a.file_name.localeCompare(b.file_name)
    } else if (sortBy === 'email') {
      comparison = a.email.localeCompare(b.email)
    } else if (sortBy === 'type') {
      comparison = a?.type.localeCompare(b?.type)
    } else if (sortBy === 'updated_at') {
      comparison = a.updated_at.localeCompare(b?.updated_at)
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })

  const handleExportList = async () => {
    const result = await getDocumentsLeakExcelApi()
    const dataXlsx = result?.map((item:any) => ({
      'File Name':item?.file_name,
      'Username': item?.fullName,
      'Type': item?.type,
      'Leaked on': moment(item?.updated_at, dateFormats, true).format("MMM DD, YYYY"),
      
    }))
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Defendis-Documents_Leaks-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)
  return (
    <div className={styles.awaitingApproval} style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e) => setsearch(e.target.value)} />
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch} />
        </div>
        <div className={styles.removeSelected} onClick={handleExportList}>
          <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
          <FontAwesomeIcon icon={faDownload} color='white' style={{ fontSize: 14 }} />
        </div>
      </div>
      {
        !isLoadingDocumentsLeak ? documentsLeak && documentsLeak.data.length !== 0 ?
          <div style={{ width: "100%" }}>
            <table className={styles.table}>
              <tr className={styles.tableHead}>
                <th></th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('file_name')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>File Name</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'file_name' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('email')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Username</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'email' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('type')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Type</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'type' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('updated_at')}>
                  <div style={{ display: 'flex', fontSize: 14, color: "white", fontWeight: 500, alignItems: 'center', gap: 5 }}>
                    <span style={{ marginRight: '4px' }}>Detected on</span>
                    <FontAwesomeIcon
                      icon={sortBy === 'updated_at' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                      size="1x"
                      height={12}
                      width={11}
                    />
                  </div>
                </th>
              </tr>
              {
                finalSort?.map(item =>
                  <tr className={styles.tableActivityRowWatchListAssets} onClick={() => setCredentialDetail(item)} style={{ cursor: "pointer" }}>
                    <td className={styles.tableCell} style={{ width: 6 }}>
                    </td>
                    <td className={styles.tableCell}>
                      <span style={{ color: '#cbcbcb', fontSize: 14, textAlign: 'start' }}>{(item?.file_name ? item.file_name.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "-")  }</span>
                    </td>
                    <td style={{ padding: "16px 8px 15px 0px"}} className={styles.tableCell}>
                      <span>{item.fullName ? item.fullName : "-"}</span>
                    </td>
                    <td style={{ paddingLeft: 8 }} className={styles.tableCell}>
                      <span style={{ padding: '4px 8px', color: "#1a1a1a", backgroundColor: "white", borderRadius: '32px', fontSize: 12, width: '72px', textAlign: 'center' }}>{item.type}</span>
                    </td>
                    <td style={{ width: 150 }} className={styles.tableCell} >
                      <div style={{}}>
                        {
                          item?.updated_at ?
                            <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start" }}>
                              <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="grey"/>
                              <span>{item?.updated_at && moment(item?.updated_at, dateFormats, true).format("MMM DD, YYYY")}</span>
                            </div>
                            :
                            <div>
                            <span>-</span>
                            </div>
                        }
                      </div>
                    </td>
                  </tr>
                )
              }

            </table>
            <Pagination
              showFirstButton
              showLastButton
              shape="rounded"
              onChange={handleChangePage}
              page={Number(documentsLeak?.pagination?.currentPage)}
              count={documentsLeak?.pagination?.totalPages}
              sx={{
                '& .MuiPagination-ul': {
                  justifyContent: "center",
                  display: 'flex'
                },
                '& .Mui-selected': {
                  color: '#ffffff', // Text color for active page
                  backgroundColor: '#1f1f1f', // Background color for active page
                  opacity: 0.3, // Background color for active page
                },
                '& .MuiPaginationItem-root': {
                  color: '#ffffff', // Text color for inactive pages
                },
              }}
            />
          </div>
          :
          <div className={styles.loaderVul}>
            <EmptyState />
          </div>
          :
          <div className={styles.loaderVul}>
            <CircularProgress />
          </div>
      }

<Snackbar
        open={isDonwloaded}
        autoHideDuration={3000}
        onClose={handleOpenNotf}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert severity={"success"} variant={"filled"} sx={{ width: '100%', backgroundColor: '#00B0FF', borderRadius: 12 }}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} height={28} width={28} color='white' />,
            error: <FontAwesomeIcon icon={faCircleXmark} height={28} width={28} color='white' />
          }}>
          {"List exported successfully"}
        </Alert>
      </Snackbar>
    </div>
  )
}
