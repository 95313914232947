import { Reducer } from "react";
import { FAILED_WATCH_LIST, GET_WATCH_LIST, GET_WATCH_LIST_ASSETS, GET_WATCH_LIST_BIN, GET_WATCH_LIST_BRAND_KEYWORD, GET_WATCH_LIST_DOMAIN, GET_WATCH_LIST_EXECUTIVE, GET_WATCH_LIST_IP, GET_WATCH_LIST_RANSOME, SAVE_WATCH_LIST_ASSETS, SAVE_WATCH_LIST_BIN, SAVE_WATCH_LIST_BRAND_KEYWORD, SAVE_WATCH_LIST_DOMAIN, SAVE_WATCH_LIST_EXECUTIVE, SAVE_WATCH_LIST_IP, SAVE_WATCH_LIST_RANSOME, SET_WATCH_LIST, SET_WATCH_LIST_ASSETS, SET_WATCH_LIST_BIN, SET_WATCH_LIST_BRAND_KEYWORD, SET_WATCH_LIST_DOMAIN, SET_WATCH_LIST_EXECUTIVE, SET_WATCH_LIST_IP, SET_WATCH_LIST_RANSOME, Status, WatchList, WatchListActions, WatchListAssets, WatchListAssetsReq, WatchListBin, WatchListBinReq, WatchListBrandKeyWord, WatchListBrandKeyWordReq, WatchListDomain, WatchListDomainReq, WatchListExecutive, WatchListExecutiveReq, WatchListGroup, WatchListGroupReq, WatchListIp, WatchListIpReq } from "../constants";

interface State {
    watchList: WatchList,
    isLoading: boolean,
    watchListAssetsReq: WatchListAssetsReq,
    watchListBrandKeywordReq: WatchListBrandKeyWordReq
    watchListDomainReq: WatchListDomainReq
    watchListIpReq: WatchListIpReq
    watchListBinReq: WatchListBinReq
    watchListExecutiveReq: WatchListExecutiveReq
    watchListGroupReq: WatchListGroupReq
    watchListAssets: WatchListAssets[]
    isLoadingAssets: boolean
    watchListBrandKeyword: WatchListBrandKeyWord[]
    isLoadingKeyword: boolean
    watchListDomain: WatchListDomain[]
    isLoadingDomain: boolean
    watchListIp: WatchListIp[]
    isLoadingIp: boolean
    watchListBin: WatchListBin[]
    isLoadingBin: boolean
    watchListExecutive: WatchListExecutive[]
    isLoadingExecutive: boolean
    watchListGroup: WatchListGroup[]
    isLoadingGroup: boolean
}

const initialDataWatchList: WatchList = {
    assets: 0,
    bins: 0,
    brandKeyword: 0,
    domains: 0,
    executives: 0,
    ips: 0,
    ransomes: 0
}

const initialDataAssetsReq: WatchListAssetsReq = {
    vendor: '',
    assetsStartDate: '',
    product: '',
    version:''
}

const initialDataBrandKeyword: WatchListBrandKeyWordReq = {
    keyword: '',
    status: Status.INREVIEW
}

const initialDataDomain: WatchListDomainReq = {
    domain: '',
    status: Status.INREVIEW
}

const initialDataIp: WatchListIpReq = {
    ip: '',
    status: Status.INREVIEW
}

const initialDataBin: WatchListBinReq = {
    bin: '',
    status: Status.INREVIEW
}

const initialDataExecutive: WatchListExecutiveReq = {
    fullName: '',
    email: '',
    executiveStatus: Status.INREVIEW,
    username:''
}

const initialDataGroup: WatchListGroupReq = {
    group: '',
    status: Status.APPROUVED
}

const initialData: State = {
    watchList: initialDataWatchList,
    isLoading: false,
    watchListAssetsReq: initialDataAssetsReq,
    watchListBrandKeywordReq: initialDataBrandKeyword,
    watchListDomainReq: initialDataDomain,
    watchListIpReq: initialDataIp,
    watchListBinReq: initialDataBin,
    watchListExecutiveReq: initialDataExecutive,
    watchListGroupReq: initialDataGroup,
    watchListAssets: [],
    isLoadingAssets: false,
    watchListBrandKeyword: [],
    isLoadingKeyword: false,
    watchListDomain: [],
    isLoadingDomain: false,
    watchListIp: [],
    isLoadingIp: false,
    watchListBin: [],
    isLoadingBin: false,
    watchListExecutive: [],
    isLoadingExecutive: false,
    watchListGroup: [],
    isLoadingGroup: false
}



export const watchListReducer: Reducer<State, WatchListActions> = (state, action): State => {
    if(state === undefined) return initialData;
    switch (action.type) {
        case GET_WATCH_LIST_ASSETS:
            return {
                ... state,
                isLoadingAssets: true
            }
       
        case GET_WATCH_LIST_BIN:
            return {
                ... state,
                isLoadingBin: true
            }
        case GET_WATCH_LIST_BRAND_KEYWORD:
            return {
                ... state,
                isLoadingKeyword: true
            }
        case GET_WATCH_LIST_DOMAIN: 
            return {
                ...state,
                isLoadingDomain: true
            }
        case GET_WATCH_LIST_EXECUTIVE:
            return {
                ...state,
                isLoadingExecutive: true
            }
        case GET_WATCH_LIST_IP:
            return {
                ... state,
                isLoadingIp: true
            }
        case GET_WATCH_LIST_RANSOME:
            return {
                ...state,
                isLoadingGroup: true
            }
        case GET_WATCH_LIST:
            return {
                ... state,
                isLoading: true
            }
        case FAILED_WATCH_LIST:
            return {
                ... state,
                isLoading: false
            }
        case SET_WATCH_LIST:
            return {
                ...state,
                watchList: action.payload as WatchList,
                isLoading: false
            }
        case SAVE_WATCH_LIST_ASSETS:
            return {
                ...state,
                
                watchListAssetsReq: action.payload as WatchListAssetsReq
            }
        case SET_WATCH_LIST_ASSETS:
            return {
                ...state,
                isLoadingAssets: false,
                watchListAssets: action.payload as WatchListAssets[]
            }
        case SAVE_WATCH_LIST_BRAND_KEYWORD:
            return {
                ...state,
                watchListBrandKeywordReq: action.payload as WatchListBrandKeyWordReq
            }
        case SET_WATCH_LIST_BRAND_KEYWORD: 
            return {
                ...state,
                isLoadingKeyword: false,
                watchListBrandKeyword: action.payload as WatchListBrandKeyWord[]
            }
        case SAVE_WATCH_LIST_DOMAIN:
            return {
                ...state,
                watchListDomainReq: action.payload as WatchListDomainReq
            }
        case SET_WATCH_LIST_DOMAIN: 
            return {
                ...state,
                isLoadingDomain: false,
                watchListDomain: action.payload as WatchListDomain[]
            }
        case SAVE_WATCH_LIST_IP:
            return {
                ...state,
                watchListIpReq: action.payload as WatchListIpReq
            }
        case SET_WATCH_LIST_IP: 
            return {
                ...state,
                isLoadingIp: false,
                watchListIp: action.payload as WatchListIp[]
            }
        case SAVE_WATCH_LIST_BIN:
            return {
                ...state,
                watchListBinReq: action.payload as WatchListBinReq
            }
        case SET_WATCH_LIST_BIN: 
            return {
                ...state,
                isLoadingBin: false,
                watchListBin: action.payload as WatchListBin[]
            }
        case SAVE_WATCH_LIST_EXECUTIVE:
            return {
                ...state,
                watchListExecutiveReq: action.payload as WatchListExecutiveReq
            }
        case SET_WATCH_LIST_EXECUTIVE: 
            return {
                ...state,
                isLoadingExecutive: false,
                watchListExecutive: action.payload as WatchListExecutive[]
            }
        case SAVE_WATCH_LIST_RANSOME:
            return {
                ...state,
                watchListGroupReq: action.payload as WatchListGroupReq
            }
        case SET_WATCH_LIST_RANSOME: 
            return {
                ...state,
                isLoadingGroup: false,
                watchListGroup: action.payload as WatchListGroup[]
            }
        default:
            return state;
    }
}

export const watchListSelector = (state: any) => state.watchList; 