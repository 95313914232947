import { faChevronDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../../dashboard.module.scss'
import { Negociations } from '../../../../core/constants'
import { getAllNegociationApi } from '../../../../core/api/ransomeApi'
import Loader from "../../../../icons/Loader.svg"
import ReactGA from 'react-ga4';
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import EmptyStateConfig from '../../common/EmptyStateConfig'
import EmptyStateNegociations from '../../common/EmptyStateNegociations'
function NegociationChats() {
  const { groupDetail, victims, isLoadingGroups, negociations, isLoadingNegociations } = useSelector(ransomeSelector)

  useEffect(() => {
    document.title = 'Ransom Group Negotiations';
    ReactGA.send({ hitType: 'Ransom Group Negotiations', page: window.location.pathname });
  }, []);


  const filteredGroups = groupDetail && negociations
    ? negociations.filter((negociation: Negociations) => negociation.name_group.toLowerCase() === groupDetail.name.toLowerCase())
    : [];


  return (
    <>
      {
        !isLoadingGroups ?
          groupDetail?.name.length && filteredGroups.length !== 0 ?
            (
              <table className={styles.table} style={{ marginTop: 25 }}>
                <thead>
                  <tr className={styles.tableHead}>
                    <th style={{ paddingLeft: 8 }}>
                      Name {" "}
                      <FontAwesomeIcon icon={faChevronDown} width={11} height={12} />
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      Messages {" "}
                      <FontAwesomeIcon icon={faChevronDown} width={11} height={12} />
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      Initial Ransom {" "}
                      <FontAwesomeIcon icon={faChevronDown} width={11} height={12} />
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      Negotiated Ransom {" "}
                      <FontAwesomeIcon icon={faChevronDown} width={11} height={12} />
                    </th>
                    <th style={{ paddingLeft: 8 }}>
                      Paid {" "}
                      <FontAwesomeIcon icon={faChevronDown} width={11} height={12} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredGroups && filteredGroups.map(item =>
                      <tr className={styles.tableActivityRowWatchListAssets}>
                        <td style={{ width: '438px', padding: '10px 16px' }} className={styles.tableCell}>
                          {item.chat_id}
                        </td>
                        <td style={{ width: '200px', padding: '10px 16px' }} className={styles.tableCell}>
                          7 messages
                        </td>
                        <td style={{ width: '200px' }} className={styles.tableCell}>
                          $900,000
                        </td>
                        <td style={{ width: '200px', padding: '10px 16px' }} className={styles.tableCell} >
                          -
                        </td>
                        <td style={{ width: '80px', padding: '10px 16px' }} className={styles.tableCell} >
                          <FontAwesomeIcon icon={faCircleCheck} width={11} height={12} color="#afafaf" />

                        </td>
                      </tr>
                    )
                  }


                </tbody>
              </table>
            ) : (
              <div className={styles.loaderVul}>
                <EmptyStateNegociations />
              </div>
            ) : (
            <div className={styles.loader}>
              <CircularProgress />
            </div>)
      }
    </>
  )
}

export default NegociationChats
