import React, { useEffect, useState } from 'react'
import styles from './account.module.scss'
import { Switch } from '@mui/material'
import Header from '../Dashboard/common/Header'
import Menu from './common/Menu'
import ReactGA from 'react-ga4'

function Nottifications() {
 
  useEffect(() => {
    document.title = "Notification Settings"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  }, [])

  return (
    <div className={styles.main}>
      <Menu />
      <Header />
      <div className={styles.content}>
        <div className={styles.personnalInfoHeadr_firstSection}>
          <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
          <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>Notifications</p>
        </div>
        <div className={styles.avatarPersonalAccountCard}>
          <p className={styles.avatarPersonalAccountCard_title}>Email Notifications</p>
          <div className={styles.personalInfosInputs} style={{ justifyContent: "space-between" }}>
          <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>New threat</p>
              <Switch name='New Threat Email' 
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#20B444',
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: '#FFFFFF',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#4caf50',
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#20B444',
                  },
                }}
              
              />
            </div>
            <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Asset Approval</p>
              <Switch name='Asset Approval Email' 
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#20B444',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#FFFFFF',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#4caf50',
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#20B444',
                },
              }}
            />
            </div>
          </div>

          <div className={styles.personalInfosInputs} style={{ justifyContent: "space-between" }}>
            <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Account Changes</p>
              <Switch name='Account Changes Email' 
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#20B444',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#FFFFFF',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#4caf50',
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#20B444',
                },
              }}
            />
            </div>
            <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Weekly Digest</p>
              <Switch name='Weekly Digest Email' 
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#20B444',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#FFFFFF',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#4caf50',
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#20B444',
                },
              }}
            />
            </div>
          </div>

        </div>
        <div className={styles.avatarPersonalAccountCard}>
          <p style={{ width: '235px' }} className={styles.avatarPersonalAccountCard_title}>Dashboard Notifications</p>
          <div className={styles.personalInfosInputs} style={{ justifyContent: "space-between" }}>
          <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>New Threat</p>
              <Switch name='New Threat Email In-App' 
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#20B444',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#FFFFFF',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#4caf50',
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#20B444',
                },
              }}
            />
            </div>
            <div style={{ width: "50%" }} className={styles.personalInfosInputs}>
              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Asset Approval</p>
              <Switch name='Asset Approval In-App' 
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#20B444',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#FFFFFF',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#4caf50',
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#20B444',
                },
              }}
            />
            </div>

          </div>

          {/* <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                            <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment mention</p>
                              <Switch name='verifyWithEmail'/>
                            </div>
                            <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                              <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment resolved</p>
                              <Switch   name='verifyWithSms'/>
                            </div>
                          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Nottifications