import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { idText } from 'typescript'
import { setDnsAction } from '../../../core/actions/brandIntelActions'
import { getDnsDomaineApi } from '../../../core/api/brandIntelApi'
import { DnsResponse, GET_WATCH_LIST_DOMAIN } from '../../../core/constants'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import styles from '../dashboard.module.scss'

function DnsRecords() {
  const { domaineDetail } = useSelector(brandIntelSelector)
  const dispatch = useDispatch()
  const [response, setresponse] = useState<DnsResponse | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const getDnsResponse = async () => {
    if(domaineDetail) {
      const data: DnsResponse | undefined= await getDnsDomaineApi(domaineDetail.domain);
      setresponse(data)
      
    }
    
  }
  useEffect(() => {
    setIsLoading(true)
    getDnsResponse()
    setIsLoading(false)
    
  },[]) 

  // console.log(JSON.stringify(response))
  return (
    <table className={styles.table}style={{marginTop:25}}>
     {
       isLoading ?
       <Skeleton variant="rectangular" width={210} height={118} />
       :
       <tbody>
        {
          response?.answers?.a && response?.answers?.a?.map(item =>
            <tr  className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '200px', padding: '0px 16px'}} className={styles.tableCell}>
                A
            </td>
                <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                    {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
                </td>
          </tr>
            )
        }
        {
          response?.answers?.aaaa && response?.answers?.aaaa?.map(item =>
            <tr  className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '200px', padding: '0px 16px'}} className={styles.tableCell}>
                AAAA
            </td>
                <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                    {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
                </td>
          </tr>
            )
        }
         {
           response?.answers?.mx && response?.answers?.mx?.map(item=>
            <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
                MX
            </td>
            <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                  {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
                </td>
          </tr>
            )
         }
        {
          response?.answers?.ns && response?.answers?.ns?.map(item =>
            <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
          NS
          </td>
          <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
          <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
              </td>
        </tr>
            )
        }
       {
         response?.answers?.soa && response?.answers?.soa?.map(item=>
          <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
          SOA
          </td>
          <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
              </td>
        </tr>
          )
       }
        {
          response?.answers?.txt && response?.answers?.txt?.map(item =>
            
            <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
            TXT
            </td>
            <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
          <p style={{fontSize: 14, color: 'white'}}>{item}</p>
                  {/* <p style={{fontSize: 12, color: '#757575', marginTop: -15}}>April 24, 2024 14:34</p> */}
                  </td>
          </tr>
            )
        }
        
      </tbody>
     }
  </table>
  )
}

export default DnsRecords