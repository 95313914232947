import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'
export default function ChangeHistory() {
  const { vulDetail } = useSelector(vulnerabilitiesSelector)
    return (
        <div>
            <p style={{fontSize: 18, fontWeight: '600', color: 'white'}}>
            Initial Analysis - January 11, 2024 10:17
            </p>
            <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Type
            </th>
            <th style={{paddingLeft: 8}}>
            Old Value
            </th>
            <th style={{paddingLeft: 8}}>
            New Value
            </th> 
          </tr>
        </thead>
          <tbody>
              <tr className={styles.tableActivityRowWatchList}>
                <td style={{width: '200px'}} className={styles.tableCell}>
                    <p style={{fontSize: 14}}>CVSS V3.1</p>
                </td>
                
                <td style={{width: '459px'}} className={styles.tableCell} >
                  
                </td>
                <td style={{width:'459px'}} className={styles.tableCell}>
                  {vulDetail?.vector_string}
                </td>
              </tr>
              <tr className={styles.tableActivityRowWatchList}>
                <td style={{width: '200px'}} className={styles.tableCell}>
                    <p style={{fontSize: 14}}>Reference Type</p>
                </td>
                
                <td style={{width: '459px'}} className={styles.tableCell} >
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing No Types Assigned
                </td>
                <td style={{width:'459px'}} className={styles.tableCell}>
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing Exploit, Third Party Advisory
                </td>
              </tr>
              <tr className={styles.tableActivityRowWatchList}>
                <td style={{width: '200px'}} className={styles.tableCell}>
                    <p style={{fontSize: 14}}>Reference Type</p>
                </td>
                
                <td style={{width: '459px'}} className={styles.tableCell} >
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing No Types Assigned
                </td>
                <td style={{width:'459px'}} className={styles.tableCell}>
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing Exploit, Third Party Advisory
                </td>
              </tr>
              <tr className={styles.tableActivityRowWatchList}>
                <td style={{width: '200px'}} className={styles.tableCell}>
                    <p style={{fontSize: 14}}>Reference Type</p>
                </td>
                
                <td style={{width: '459px'}} className={styles.tableCell} >
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing No Types Assigned
                </td>
                <td style={{width:'459px'}} className={styles.tableCell}>
                https://drive.google.com/file/d/13xhOZ3Zg-XoviVC744PPDorTxYbLUgbv/view?usp=sharing Exploit, Third Party Advisory
                </td>
              </tr>
          </tbody>
        </table>
                     <p style={{fontSize: 18, fontWeight: '600', color: 'white'}}>
                     New CVE - January 05, 2024 14:15
            </p>
        <table className={styles.table}style={{marginTop:25}}>
          <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Type
            </th>
            <th style={{paddingLeft: 8}}>
            Old Value
            </th>
            <th style={{paddingLeft: 8}}>
            New Value
            </th> 
          </tr>
          </thead>
          <tbody>
            <tr className={styles.tableActivityRowWatchList}>
              <td style={{width: '200px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>Description</p>
              </td>
              
              <td style={{width: '459px'}} className={styles.tableCell} >
                
              </td>
              <td className={styles.tableCell}>
              A vulnerability classified as critical was found in CodeAstro Online Food Ordering System 1.0. This vulnerability affects unknown code of the file /admin/ of the component Admin Panel. The manipulation of the argument Username leads to sql injection. The attack can be initiated remotely. The exploit has been disclosed to the public and may be used. VDB-249778 is the identifier assigned to this vulnerability.
              </td>
              </tr>
              
             
          </tbody>
        </table>
        </div>
    )
}
