import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from "../../dashboard.module.scss"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getAllNotificationsApi } from '../../../../core/api/notificationApi'
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import iconMenu5 from "../../../../icons/Main/Menu-5.svg"
import iconMenu6 from "../../../../icons/Main/Menu-6.svg"
import iconMenu4 from "../../../../icons/Main/Menu-4.svg"
import { notificationSelector } from '../../../../core/selectors/notificationBellSelector'
import moment from 'moment'
import EmptyStateNot from '../EmptyStateNot'

function formatTimestamp(timestamp: string) {
  const now = moment();
  const inputTime = moment(timestamp);
  const duration = moment.duration(now.diff(inputTime));

  const seconds = duration.asSeconds();
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();

  if (seconds < 60) {
    return `${Math.floor(seconds)} seconds ago`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minutes ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hours ago`;
  } else {
    return inputTime.format('MMM DD, YYYY');
  }
}

export default function AllNotification() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { credentialsLeak } = useSelector(threatIntelSelector)
   const { notifs, notifsUser } = useSelector(notificationSelector)
    // const data = credentialsLeak?.data?.filter( item => {
    //     if(allNotifs && allNotifs?.filter(da => da.userid === item.userid).length>0){
    //         return item
    //     }
    // })
   
const renderVul = (msg: any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:'pointer'}} onClick={() => {
            navigate("/vulnerabilities")
            }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img src={iconMenu5} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell} style={{padding: 16}}>
                <span style={{ fontSize: '14', color: 'white'}}>{msg?.cve_id} - {msg?.description?.substring(0,150)} ...</span>
                <div style={{display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                    <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                    <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
                </div>
            </td>
        </tr>  
          </tbody>
  }
const renderRansom = (msg: any, created_at?:string) => {
      return <tbody>
      <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:'pointer'}} onClick={() => {
          navigate("/ransomwareIntel")
      }}>
          <td className={styles.tableCell} style={{width: 50,}}>
              <div style={{display:'flex', justifyContent:'center'}}>
                  <img src={iconMenu6} width={18}/>
              </div>
          </td>
          <td className={styles.tableCell} style={{padding: 16}}>
              <span style={{ fontSize: '14', color: 'white'}}>New ransomware attack by {msg.name} detected.</span>
              <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                  <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                  <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
              </div>
          </td>
      </tr>   
          </tbody>
  }
const renderDarkweb = (msg: any, created_at?:string) => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:"pointer"}} onClick={() => {
        navigate("/ransomwareIntel")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu4} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell} style={{padding: 16}}>
            <span style={{ fontSize: '14', color: 'white'}}>New Dark Web post by {msg.author?.replace('(', '')?.replace(')','')} mentionned your organisation.</span>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
            </div>
        </td>       
    </tr>    
        </tbody>
}
const renderSurfaceWeb = (msg: any, created_at?:string) => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:"pointer"}} onClick={() => {
        navigate("/brandIntel")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu6} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell} style={{padding: 16}}>
            <span style={{ fontSize: '14', color: 'white'}}>{msg.profile_name} mentionned you in a new article: '{msg.post_title ? ` titled '${msg.post_title}.'`:"."}'.</span>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
            </div>
        </td>
    </tr>
        </tbody>
  }
const renderDomain = (msg: any, created_at?:string) => {
  return <tbody>
  <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:"pointer"}} onClick={() => {
      navigate("/brandIntel")
  }}>
      <td className={styles.tableCell} style={{width: 50,}}>
          <div style={{display:'flex', justifyContent:'center'}}>
              <img src={iconMenu4} width={18}/>
          </div>
      </td>
      <td className={styles.tableCell} style={{padding: 16}}>
          <span style={{ fontSize: '14', color: 'white'}}>potential domain phishing attack detected: '{msg.domain}'</span>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
              <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
              <span style={{color: 'grey'}}>{created_at && formatTimestamp(created_at)}</span>
          </div>
      </td>
  </tr>    
      </tbody>
}
const creditsCard = notifs.filter(item => item.isCreditsCard === true)
const renderCreditsCard = (item:any) => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:"pointer"}} onClick={() => {
        navigate("/threatIntel")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu6} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell} style={{padding: 16}}>
            <span style={{ fontSize: '14', color: 'white'}}>Payment Card {item?.cardnumber 
  ? `${item.cardnumber.slice(0, 4)} ${item.cardnumber.slice(4, 6)}** **** ${item.cardnumber.slice(-4)}`
  : ""} has appeared in a recent data leak.</span>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5}}>
                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                <span style={{color: 'grey'}}>{item?.created_at && formatTimestamp(item.created_at)}</span>
            </div>
        </td>
    </tr>
        </tbody>
}
const renderCredential = (msg:any) => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList} style={{cursor:"pointer"}} onClick={() => {
        navigate("/threatIntel")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu6} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell} style={{padding: 16}}>
            <span style={{ fontSize: '14', color: 'white'}}>Login credentials for {msg?.username} linked with one of your domains has appeared in a recent data leak.</span>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, gap: 5 }}>
                <FontAwesomeIcon icon={faCalendar} size="1x" height={11} width={10} color='grey'/>
                <span style={{color: 'grey'}}>{ formatTimestamp(msg.created_at)}</span>
            </div>
        </td>
    </tr>
        </tbody>
}

return (
    <div style={{width: "100%", marginTop: 25}}>
        {
        notifsUser.some((not:any) => not.read) === true && notifsUser?.length!== 0 ?
        <table className={styles.table}>
            {
            notifs.map(item => {
                if(item.isVul)
                    return renderVul(item.data, item.created_at)
                  else if(item.isRansome)
                      return renderRansom(item.data, item.created_at)
                  else if(item.isDarkWeb)
                    return renderDarkweb(item.data, item.created_at)
                  else if(item.isDomains)
                    return renderDomain(item.data, item.created_at)
                  else if(item.isSurfaceWeb && item.data?.type?.includes("news"))
                    return renderSurfaceWeb(item.data, item.created_at)
                  else if(item.isCreditsCard)
                      return renderCreditsCard(item?.data)
                  else if(item.isCredentials)
                      return renderCredential(item?.data)
              })
            }
        </table>
         :
         <div className={styles.loaderVul}>
         <EmptyStateNot />
         </div>
        }
    </div>
    )
}
