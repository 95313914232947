import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'


function capitalizeFirstLetter(data: any) {
    return data?.charAt(0)?.toUpperCase() + data?.slice(1)?.toLowerCase();
}

const renderColors = (riskScore: number | string | undefined) => {
    // Default color scheme for undefined riskScore
    const defaultColorScheme = {
       textColor: "#757575",
       backgroundColor: "#E6E6E6",
       borderColor: "#757575"
    };
 
    if (riskScore === undefined) {
       return defaultColorScheme;
    }
 
    if (typeof riskScore === 'number') {
       if (riskScore === 0.0) {
           return {
             textColor: "#757575",
             backgroundColor: "#e6e6e6",
             borderColor: "#757575"
           }
       } else if (riskScore >= 0.1 && riskScore <= 3.9) {
           return {
             textColor: "#20b444",
             backgroundColor: "#ecf9ef",
             borderColor: "#20b444"
           }
       } else if (riskScore >= 4.0 && riskScore <= 6.9) {
           return {
               textColor: "#f5c500",
               backgroundColor: "#fff7d5",
               borderColor: "#f5c500"
           }
       } else if (riskScore >= 7.0 && riskScore <= 8.9) {
           return {
               textColor: "#fc9418",
               backgroundColor: "#feead1",
               borderColor: "#fc9418"
           }
       } else {
           return {
             textColor: "#e11900",
             backgroundColor: "#fdede8",
             borderColor: "#e11900"
           }
       }
    } else {
       // Assume riskScore is a string and use capitalizeFirstLetter to normalize input
       const normalizedRisk = capitalizeFirstLetter(riskScore);
       switch (normalizedRisk) {
           case "Low":
               return {
                textColor: "#20b444",
                 backgroundColor: "#ecf9ef",
                 borderColor: "#20b444"
               };
           case "Medium":
               return {
                   textColor: "#A08100",
                   backgroundColor: "#FFEB96",
                   borderColor: "#A08100"
                 };
           case "High":
               return {
                   textColor: "#E11900",
                   backgroundColor: "#FBD2C4",
                   borderColor: "#E11900"
                 };
           case "Critical":
               return {
                   textColor: "#E11900",
                   backgroundColor: "#FBD2C4",
                   borderColor: "#E11900"
                 };
            case "Network":
                return {
                    textColor: "#E11900",
                   backgroundColor: "#FBD2C4",
                   borderColor: "#E11900"
                };
            case "Adjacent_network":
                return {
                    textColor: "#894C02",
                    backgroundColor: "#FFD8A7",
                    borderColor: "#894C02"
                };
            case "Local":
                return {
                    textColor: "#A08100",
                    backgroundColor: "#FFEB96",
                    borderColor: "#A08100"
                };
            case "Physical":
                return {
                    textColor: "#20b444",
                    backgroundColor: "#ecf9ef",
                     borderColor: "#20b444"
                };

           default:
               return defaultColorScheme;
       }
    }
 
    // Return default colors if none of the conditions match
    return defaultColorScheme;
 };
 

export default function ImpactSection() {
    const { vulDetail } = useSelector(vulnerabilitiesSelector)
    console.log(renderColors(capitalizeFirstLetter(vulDetail?.confidentiality_impact)))
   
    return (
        <div style={{marginTop: 32}}>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 10}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <span style={{ padding: '16px', fontSize: 14}}>Attack Vector</span>
                        <div style={{padding: '16px'}}>
                            {
                                vulDetail?.attack_vector ?
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors((vulDetail?.attack_vector)).textColor,
                                backgroundColor:renderColors((vulDetail?.attack_vector)).backgroundColor,
                                borderColor:renderColors((vulDetail?.attack_vector)).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.attack_vector)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                        </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <span style={{ padding: '16px', fontSize: 14}}>Impact</span>
                        <div style={{padding: '16px'}}>
                            {
                                vulDetail?.impact_score ? 
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors(vulDetail.impact_score).textColor,
                                backgroundColor:renderColors(vulDetail?.impact_score).backgroundColor,
                                borderColor:renderColors(vulDetail?.impact_score).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.impact_score)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                     </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 10, marginTop:10}}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                <span style={{ padding: '16px', fontSize: 14}}>Scope</span>
                    <div style={{padding: '16px'}}>
                            {
                                vulDetail?.scope ?
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors((vulDetail?.scope)).textColor,
                                backgroundColor:renderColors((vulDetail?.scope)).backgroundColor,
                                borderColor:renderColors((vulDetail?.scope)).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.scope)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                    </div>
                </div> 
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <span style={{ padding: '16px', fontSize: 14}}>Attack Complexity</span>
                        <div style={{padding: '16px'}}>
                            {
                                vulDetail?.attack_complexity ?
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors((vulDetail?.attack_complexity)).textColor,
                                backgroundColor:renderColors((vulDetail?.attack_complexity)).backgroundColor,
                                borderColor:renderColors((vulDetail?.attack_complexity)).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.attack_complexity)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                        </div>
                    </div>
                  
            </div>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 10, marginTop:10}}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <span style={{ padding: '16px', fontSize: 14}}>User Interaction</span>
                    <div style={{padding: '16px'}}>
                        {
                            vulDetail?.user_interaction ? 
                            <span className={styles.scoreSeverity} style={{
                                color: renderColors(vulDetail.user_interaction).textColor,
                            backgroundColor:renderColors(vulDetail?.user_interaction).backgroundColor,
                            borderColor:renderColors(vulDetail?.user_interaction).borderColor
                            }}
                                >{capitalizeFirstLetter(vulDetail?.user_interaction)}</span>
                                :
                                <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                        }
                        </div>
                </div>    
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <span style={{ padding: '16px', fontSize: 14}}>Integrity Impact</span>
                    <div style={{padding: '16px'}} >
                            {
                                vulDetail?.confidentiality_impact ?
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors((vulDetail?.confidentiality_impact)).textColor,
                                backgroundColor:renderColors((vulDetail?.confidentiality_impact)).backgroundColor,
                                borderColor:renderColors((vulDetail?.confidentiality_impact)).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.confidentiality_impact)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                    </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 10, marginTop:10}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <span style={{ padding: '16px', fontSize: 14}}>Privileges Required</span>
                        <div style={{padding: '16px'}} >
                                {
                                    vulDetail?.privileges_required ?
                                    <span className={styles.scoreSeverity} style={{
                                        color: renderColors((vulDetail?.privileges_required)).textColor,
                                    backgroundColor:renderColors((vulDetail?.privileges_required)).backgroundColor,
                                    borderColor:renderColors((vulDetail?.privileges_required)).borderColor
                                    }}
                                        >{capitalizeFirstLetter(vulDetail?.privileges_required)}</span>
                                        :
                                        <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                                }
                        </div>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <span style={{ padding: '16px', fontSize: 14}}>Availability Impact</span>
                            <div style={{padding: '16px'}}>
                            {
                                vulDetail?.availability_impact ?
                                <span className={styles.scoreSeverity} style={{
                                    color: renderColors((vulDetail?.availability_impact)).textColor,
                                backgroundColor:renderColors((vulDetail?.availability_impact)).backgroundColor,
                                borderColor:renderColors((vulDetail?.availability_impact)).borderColor
                                }}
                                    >{capitalizeFirstLetter(vulDetail?.availability_impact)}</span>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                            }
                            </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 10, marginTop:10}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <span style={{ padding: '16px', fontSize: 14}}>Exploitability</span>
                        {
                            <div style={{padding: '16px'}}>
                                {
                                vulDetail?.exploitability_score ?
                                    <div style={{display: "flex", justifyContent:"end"}}>
                                        <span className={styles.scoreSeverity} style={{width: '22px',
                                            color: renderColors(vulDetail.exploitability_score).textColor,
                                            backgroundColor:renderColors(vulDetail?.exploitability_score).backgroundColor,
                                            borderColor:renderColors(vulDetail?.exploitability_score).borderColor
                                            }}>
                                            {vulDetail?.exploitability_score}
                                        </span>
                                    </div>
                                    :
                                    <span className={styles.scoreUserInt} style={{width: 22}}>-</span>
                                }
                            </div>
    }
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <span style={{ padding: '16px', fontSize: 14}}>Confidentiality Impact</span>
                    <div style={{padding: '16px'}} >
                        {
                            vulDetail?.confidentiality_impact ?
                            <span className={styles.scoreSeverity} style={{
                                color: renderColors((vulDetail?.confidentiality_impact)).textColor,
                            backgroundColor:renderColors((vulDetail?.confidentiality_impact)).backgroundColor,
                            borderColor:renderColors((vulDetail?.confidentiality_impact)).borderColor
                            }}
                                >{capitalizeFirstLetter(vulDetail?.confidentiality_impact)}</span>
                                :
                                <span className={styles.scoreUserInt} style={{width: 22}}>-</span> 
                        }
                    </div>
                </div>
    

            </div>
        </div>
    )
}




