import React from 'react'
import styles from "../dashboard.module.scss"
 
import EmptyStateLogo from "../../../icons/States/EmptyStateWatchList.svg"
import { useSelector } from 'react-redux';
import { userSelector } from '../../../core/reducers/userReducer';

interface props {
    open: boolean,
    title: string;
    text: string;
    setOpen: any;
    addButtonText: string
}
export default function EmptyStateWatchList({open, setOpen, text, title, addButtonText}: props) {
  const { roleUser } = useSelector(userSelector)
  return (
    <div className={styles.emptyState}>
      <div style={{width: '300px'}}>
        <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>
          <img src={EmptyStateLogo}/>
        </div>
        <div style={{marginTop: 24}}> 
          <h2 style={{textAlign: "center",color:"white", fontWeight:"600",fontSize:20}}>{title}</h2>
          <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14}}>{text}</p>
        </div>
      </div>
    </div>
  )
}
