import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import styles from '../dashboard.module.scss'
import { useSelector } from 'react-redux';
import { ransomeSelector } from '../../../core/selectors/ransomeSelector';
import { RansomeOverview } from '../../../core/constants';
import { getRansomeOverviexApi } from '../../../core/api/ransomeApi';

interface props {
  overview: RansomeOverview | undefined
}

const COLORS = ['#E11900', '#E11900CC', '#E1190099', '#E1190066', '#E1190033','#1F1F1F'];

function PieChartRansomeGroups({overview}: props) {
    
    const data = overview?.groupsPost?.map((item: any) => ({
        name: item?.group_name,
        value: parseFloat(item?.percent)
    }))
    // console.log(data)
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
          <ResponsiveContainer width="50%" height={200} style={{rotate: "270deg"}}>
        <PieChart  style={{ fontWeight: "semibold", fontSize: "md", pointerEvents: "none" }}>
          <Pie
            data={data}
            startAngle={0}
            endAngle={-360}
            cx="50%"
            cy="50%"
            outerRadius={100}
            dataKey="value"
            innerRadius={70}
            stroke="none"
          >
            {data?.map((entry: any, index: any) => (
              <Cell fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className={styles.pieKeys}>
        {
          data?.map((item: any, index:number) => 
            <div style={{display: 'flex', alignItems: "center", gap: 15}}>
              <div style={{backgroundColor: COLORS[index], height: 8, width: 8, borderRadius: "50%"}}/>
              <p style={{fontSize: 12}}>{item?.value+"% "+item?.name}</p>
            </div>
          )
        }
      </div>
        </div>
  );
}

export default PieChartRansomeGroups;
