import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import styles from '../../../dashboard.module.scss'
import EmptyState from '../../../common/EmptyState'


export default function Software({ sofwares }: any) {

    useEffect(() => {
        document.title = "User Profile - Software"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])

    return (
        <>
            {
                sofwares && sofwares?.length !== 0 ?
                    <table className={styles.table} style={{ marginTop: 8 }}>
                        <tbody>
                            {
                                sofwares?.map((item: any) =>
                                    <tr key={item.softwareid} className={styles.tableActivityRowRansom1Col}>
                                        <td style={{ width: '100%', padding: '15px 16px', color: '#ffffff' }} className={styles.tableCell}>
                                            {item?.name.replace(/^\d+\)\s*/, '')}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :
                    <div className={styles.loaderVul}>
                        <EmptyState />
                    </div>
            }
        </>

    )
}
