import React, { useEffect, useState } from 'react';
import MenueContent from './common/MenueContent';
import Card from './components/Card';
import RecentThreats from './components/RecentThreats';
import styles from "./dashboard.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faColumns, faTable } from '@fortawesome/free-solid-svg-icons';
import ChartHome from './components/ChartHome';
import CriticalRiskBar from './components/CriticalRiskBar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from '../../core/reducers/authReducer';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Overview from './HomeSecrions/Overview';
import MyTasks from './HomeSecrions/MyTasks';
import Insights from './HomeSecrions/Insights';
import { statsSelector } from '../../core/selectors/statsSelector';
import { GET_ALL_VULNERABILITIES, REQUEST_GET_ALL_ENTREPRISES, REQUEST_HOME_STATS, UPDATE_LOGIN_ENTREPRISE } from '../../core/constants';
import { useDispatch } from 'react-redux';
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer';
import { userSelector } from '../../core/reducers/userReducer';
import { typesHandleChange } from '../Account/constants';


function Content() {
    const navigate = useNavigate()
    const [grid, setgrid] = useState(false)
    const [activeCompo, setactiveCompo] = useState('overview')
    const [recent, setRecent] = useState('all')
    const dispatch = useDispatch()
    const state: any = useSelector((state) => state)
    const { userInfo } = useSelector(userSelector)
    const { entreprises } = state.entreprise
    const [entId, setentId] = useState("")
    const handleChange = (event: SelectChangeEvent) => {
        dispatch({
            type: REQUEST_HOME_STATS,
            payload: event.target.value as string
        }),
            setRecent(event.target.value as string);
    };

    useEffect(() => {
        dispatch({
            type: REQUEST_GET_ALL_ENTREPRISES
        })
        const loginEntrprise = localStorage.getItem("loginEntreprise")
        if (loginEntrprise) {
            setentId(loginEntrprise)
        }
    }, [userInfo.loginEntreprise])


    const renderSections = () => {
        switch (activeCompo) {
            case 'overview':
                return <Overview />
            //   case 'mytasks':
            //       return <MyTasks grid={grid}/>
            //   case 'insights':
            //       return <Insights/>
            default:
                break;
        }
    }



    return (
        <>
            <div className={styles.content}>
                <div className={styles.menuContent}>
                    <div style={{ justifyContent: "center", borderRadius: 16 }} className={styles.firstSectionMenu}>
                        <p style={{ cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500 }} onClick={() => setactiveCompo('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Dashboard</p>
                        {/* <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('mytasks')} className={activeCompo === 'mytasks' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>My tasks</p> */}
                        {/* <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('insights')} className={activeCompo === 'insights' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Insights</p> */}
                    </div>
                    { /*activeCompo === 'mytasks' && <FontAwesomeIcon onClick={()=>setgrid(!grid)} icon={!grid ? faColumns : faTable} height={20} width={19} color='white' style={{cursor: "pointer"}}/>
                */}
                    { /*activeCompo !== 'mytasks' && activeCompo !== 'insights'  &&
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleChange} value={recent} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} size="1x" height={11} width={10} style={{ marginLeft: '-20px' }} />} style={{ backgroundColor: "transparent", fontSize: 14, fontWeight: 500 }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#1f1f1f',
                                        borderRadius: '8px',
                                        padding: '0px',
                                        boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.2)',
                                        maxHeight: '360px',
                                        overflowY: 'auto',
                                    },
                                },
                                MenuListProps: {
                                    dense: false,
                                    disablePadding: true,
                                },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            }} >
                            <MenuItem value={'all'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px' }}>Full Range</MenuItem>
                            <MenuItem value={'last7days'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px' }}>Last 7 days</MenuItem>
                            <MenuItem value={'lastMonths'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px' }}>Last month</MenuItem>
                            <MenuItem value={'last3Months'} sx={{ color: 'white', '&:hover': { backgroundColor: '#1a1a1a', }, padding: '8px 54px 8px 16px' }}>Last 3 months</MenuItem>

                        </Select> */
                    }

                </div>
                <hr className={styles.divider} />

                {
                    renderSections()
                }
            </div>

        </>
    )
}

export default Content
