import { call, put } from "redux-saga/effects";
import { ApprouveUserReq, Entreprise, EntrepriseActions, EntrepriseSupaBase, ExpiredToken, FAILED_GET_ALL_ENTREPRISES, LoginFirstTimeRes, LoginRes, MessageResp, UPDATE_USER_INFO_SUCCESS, UserEntrepriseReq, UsersEntreprise } from "../constants";
import { addUserAPI, approuveUserAPI, getAllEntreprisesAPI, getEntrepriseInfoAPI, getUsersEntrepriseAPI, updateInfosEntrepriseAPI } from "../api/entrepriseApi";
import { getUsersEntrepriseRequest, setAllEntreprisesAction, setInfosEntrepriseSuccess, setUsersEntreriseSucess } from "../actions/entrepriseActions";
import { LoginSuccessAction } from "../actions/authActions";
import { ExponentiationOperator } from "typescript";
import { setMessageAction } from "../actions/userActions";
import { AxiosError } from "axios";

export function* getInfosEntrepriseSaga(action: EntrepriseActions) {
    try {
        const response: Entreprise | ExpiredToken = yield call(getEntrepriseInfoAPI)
        if(isUsersNotAuthenticated(response)) {
            yield put(LoginSuccessAction({
                login: '',
id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue:'',
               profilePicture:'',
               dateToUpdateOtp:'',
               email: '',
               jwt: undefined,
               typeEntreprise: undefined
            }))
        } else {
            yield put(setInfosEntrepriseSuccess(response))
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* updateInfosEntrepriseSaga(action: EntrepriseActions) {
    try {
        const response: LoginFirstTimeRes | ExpiredToken = yield call(updateInfosEntrepriseAPI, action.payload as Entreprise)
        if(isUsersNotAuthenticated(response)) {
            yield put(LoginSuccessAction({
                login: '',
id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue: '',
                profilePicture:'',
                dateToUpdateOtp:'',
                 email: '',
                 jwt: undefined,
                 typeEntreprise: undefined
            }))
        } else {
            if(response.message === MessageResp.ENTREPRISE_DATA_UPDATED) {
            const response: Entreprise  = yield call(getEntrepriseInfoAPI)
                yield put(setInfosEntrepriseSuccess(response))
        }
        }
        
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* getUsersEntrepriseSaga(action: EntrepriseActions) {
    try {
        const response: UsersEntreprise[] | ExpiredToken = yield call(getUsersEntrepriseAPI);
        if(isUsersNotAuthenticated(response)) {
            yield put(LoginSuccessAction({
                login: '',
id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue: '',
                profilePicture: '',
                dateToUpdateOtp:'',
                 email: '',
                 jwt: undefined,
                 typeEntreprise: undefined
            }))
        } else {
            yield put(setUsersEntreriseSucess({users: response}))
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function isUsersNotAuthenticated(response: any): response is ExpiredToken {
    return response && response.message === "NO_BEARER_TOKEN" && response.status === 401
}

export function isUserNotSaved(response: any): response is LoginFirstTimeRes {
    return response && response.message
}

export function* addUserSaga(action: EntrepriseActions) {
    try {
        const data = action.payload as any
        const response: LoginFirstTimeRes | LoginRes = yield call(addUserAPI, data)
        if(isUserNotSaved(response)) {
            console.log("im here")
            yield put({
                type: UPDATE_USER_INFO_SUCCESS,
                payload: response.message
            })
        } else {
            window.localStorage.setItem("email", response.email)
        window.localStorage.setItem('hmac', response.hmacValue)
        window.localStorage.setItem("fullName", response.lastName)
        window.localStorage.setItem('name', response.firstName)
        window.localStorage.setItem('userid', response.id?.toString() || "")

        yield put(LoginSuccessAction(response))
        yield put({
            type: UPDATE_USER_INFO_SUCCESS,
            payload: 'User updated successfully'
        })
        }

    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}

export function* approuveUserSaga(action: EntrepriseActions) {
    try {
        const data = action.payload as ApprouveUserReq
        const response: LoginFirstTimeRes | ExpiredToken = yield call(approuveUserAPI, data);
        if(isUsersNotAuthenticated(response)) {
            yield put(LoginSuccessAction({
                login: '',
id:null,
                success: false,
                authentificated: false,
                approuved: false,
                firstName: '',
                lastName: '',
                loginEntreprise: null,
                hmacValue: '',
                profilePicture:'',
                dateToUpdateOtp: '',
                 email: '',
                 jwt: undefined,
                 typeEntreprise: undefined
            }))
        } else {
            if(response.message === MessageResp.USER_APPROUVED) {
                yield put(setMessageAction({
                    notification: 'User Approuved'
                }))
            }
            yield put(getUsersEntrepriseRequest())
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}


export function* getAllEntreprisesSaga(action: EntrepriseActions) {
    try {
        const response: EntrepriseSupaBase[] = yield call(getAllEntreprisesAPI)
        yield put(setAllEntreprisesAction(response))
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Refresh the page on 401 Unauthorized
            window.location.reload();
        } else {
            let errorMessage = "An unknown error occurred.";
            
                // Check if the error is an instance of Error
                if (error instanceof Error) {
                    errorMessage = error.message;
                } 
                // For errors that might be plain strings
                else if (typeof error === "string") {
                    errorMessage = error;
                } 
                // Handle cases where the error might be an object with a message property
                else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = (error as any).message;
                }
            
                // Log the error for debugging
                console.log("Caught error:", error);
                console.log("Error message:", errorMessage);
            
                window.location.href = `/error?errorMessage=${encodeURIComponent(errorMessage)}`;
        }
    }
}