import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../common/Header'
import Menu from '../../common/Menu'
import styles from "../../dashboard.module.scss"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
function Conversations() {
    const navigate = useNavigate()
    const { chatMessages } = useSelector(ransomeSelector)
    console.log(chatMessages)
    return (
        <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/ransomwareIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Go Back</span>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop:4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{chatMessages?.name_group}</span>
                    </div>  
                </div>
                    
                </div>

                {/* <div style={{display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24}}>
                <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Last modified</span>
                    <span style={{color: "white"}}>January 11, 2024</span>
                </div>
                    
                </div> */}
                <div style={{width: '736px', margin: '0 auto', backgroundColor: "red !important" }}>
                    {
                        chatMessages?.message_data?.map(item => 
                            <div style={{marginTop: 30}} className={item?.party === "Victim" ? styles.msgSender : styles.msgReceiver}>
                        <p className={item?.party === "Victim" ? styles.msgSenderText : styles.msgReceiverText}>
                           {item?.content}
                        </p>
                        <p style={{color: "white", fontSize: 11, marginTop: '-10px', textAlign: 'end'}}>{item?.party}, <span style={{color: "#afafaf", fontSize: 11}}>March 14, 2024 12:27</span></p>
                    </div>
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export default Conversations
